import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Slide,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { ceil } from "lodash";

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  countdown: {
    color: red[700],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onContinue }) => {
  const classes = useStyles();

  const getCountDownStr = (countDownInSeconds) => {
    if (countDownInSeconds > 60) {
      return `${ceil(countDownInSeconds / 60)} minutes`;
    } else {
      return `${countDownInSeconds} seconds`;
    }
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle>Session Timeout</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{" "}
          <span className={classes.countdown}>
            {getCountDownStr(countdown)}
          </span>
          .
        </Typography>
        <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onContinue}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
