import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  invalid: {
    border: "1px solid #F64E60",
    borderRadius: "5px"
  }
}));

export default function ConfigSelect ({url, hasError}) {
  const classes = useStyles();
  const urlRows = url;
  const queryParams = useQueryParamStore('stroke', 'SET_STROKE_QP', ['site_id', 'config_id']);
  const configs = useAsyncSelect({
    url: urlRows,
    key: 'id',
    searchTerm: 'name',
    //filters: queryParams.searchOptions
  });

  const [config, setConfig] = useState(null);

  useEffect(() => {
    const config_id = queryParams.searchOptions.config_id;
    setConfig((prev) => {
      if (`${config_id || ''}` === `${(prev || {}).id || ''}`) return prev;
      return configs.options.find((config) => {
        return `${config_id}` === `${config.id}`
      })
    });
  }, [setConfig, queryParams.searchOptions.config_id, configs.options])


  const handleChange = useCallback((config) => {
    const config_id = (config || {}).id;
    queryParams.setSearchOption('config_id', config_id);
  }, [queryParams.setSearchOption]);

  if (configs.options.length < 2) return null;
  return <AsyncSelect
    cacheOptions={false}
    placeholder={'Choose a Form configuration...'}
    className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    options={configs.options}
    loadOptions={configs.loadOptions}
    getOptionLabel={option => option.description || option.name}
    getOptionValue={option => option.id}
    loadValue={configs.loadValue}
    defaultOptions={true}
    onChange={handleChange}
    isClearable={true}
    value={config}
    isOptionDisabled={option => {
      const site_id = queryParams.searchOptions.site_id;
      if (site_id && Array.isArray(option.site_id) && option.site_id.findIndex((option) => {
        return `${site_id}` === `${option}`
      }) === -1) {
        return true;
      }
      return option.isDisabled
    }}
  />
}