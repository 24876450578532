import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useIsMounted from '../../../hooks/useIsMounted';

const propTypes = {
  children: PropTypes.node,
  onDrop: PropTypes.func.isRequired,
};

const DropZone = (props) => {
  const targetRef = useRef();
  const {children, onDrop} = props;
  const isMounted = useIsMounted();
  /* BEGIN Drop Zone Actions */
  const dragCount = useRef(0);
  const [dragging, setDragging] = useState(false);
  const [blockPointer, setBlockPointer] = useState(true);
  const blockPointerTimout = useRef(0);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMounted()) {
      setBlockPointer(false);
    }
    if (blockPointerTimout.current) clearTimeout(blockPointerTimout.current);
    blockPointerTimout.current = setTimeout(() => {
      if (isMounted()) {
        setBlockPointer(true);
      }
    }, 200);
  }
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCount.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  }
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCount.current--;
    if (dragCount.current === 0) {
      setDragging(false);
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCount.current = 0
    }
  }
  useEffect(() => {
    const div = targetRef.current;
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDrag)
    div.addEventListener('drop', handleDrop)
    window.addEventListener('dragover', handleDrag)
    return () => {
      const div = targetRef.current;
      if (div) {
        div.removeEventListener('dragenter', handleDragIn)
        div.removeEventListener('dragleave', handleDragOut)
        div.removeEventListener('dragover', handleDrag)
        div.removeEventListener('drop', handleDrop)
      }
      window.removeEventListener('dragover', handleDrag)
    }
  })
  /* END Drop Zone Actions */

  /* BEGIN Drop Zone Placement */

  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  // this event listener will rerender this component when the window is resized
  useWindowDimensions();
  // useWindowDimensions will cause this effect to rerun
  useEffect(() => {
    if (targetRef.current) {
      const newDimensions = {
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      };
      if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
        setDimensions(newDimensions);
      }
    }
  });

  const getPlacementStyle = useCallback(() => {
    const placementProps = {
      width: children ? dimensions.width : '100%',
      height: children ? dimensions.height : '100%',
    };
    if (blockPointer) {
      placementProps.pointerEvents = 'none';
    }
    if (children) {
      Object.assign(placementProps, {
        position: 'absolute'
      })
    } else {
      Object.assign(placementProps, {
        position: 'fixed', top: 0, left: 0
      })
    }
    return placementProps;
  }, [dimensions, children, blockPointer])
  /* END Drop Zone Placement */

  const [dropZoneStyle, setDropZoneStyle] = useState({
  });
  useEffect(() => {
    const placementProps = getPlacementStyle();
    const style = {
      zIndex: '9999999999',
      backgroundColor: 'rgb(0,0,0)',
      opacity: dragging ? 0.5 : 0,
      transition: 'visibility 175ms, opacity 175ms',
      ...placementProps,
    }
    setDropZoneStyle(style);
  }, [getPlacementStyle, dragging]);

  if (children) {
    // with children the drop zone will only cover the child components
    return <div ref={targetRef}>
      <div
        className={dragging ? 'visible' : 'invisible'}
        style={{
        ...dropZoneStyle
      }}/>
      {children}
    </div>
  } else {
    // without children the drop zone will cover the entire page
    return <div ref={targetRef} style={{...getPlacementStyle()}}>
      <div
        className={dragging ? 'visible' : 'invisible'}
        style={{
        ...dropZoneStyle
      }}/>
    </div>
  }
};

DropZone.propTypes = propTypes;

export default DropZone;
