import React, { useMemo } from 'react';
import DataTable from '../../common/datatable/DataTable';

const FormConfigDataTable = (props) => {
  const {
    rows,
    replaceRow,
    totalRows,
    queryParams,
    errorMessage,
    loading,
    retry,
    handleClickRow
  } = props;

  const headCols = useMemo(() => {
    return [
      {
        id: 'name',
        label: 'Name',
        sortable: false,
      },
      {
        id: 'title',
        label: 'Title',
        sortable: false,
      },
      {
        id: 'description',
        label: 'Description',
        sortable: false,
      },
      {
        id: 'type',
        label: 'Form Type',
        sortable: false,
      }
    ]
  }, [replaceRow]);

  return <DataTable
    headCols={headCols}
    idName={'name'}
    queryParams={queryParams}
    rows={rows}
    totalRows={totalRows}
    errorMessage={errorMessage}
    loading={loading}
    retry={retry}
    handleClickRow={handleClickRow}
  />
}

export default FormConfigDataTable;
