import {
  SET_PARTICIPANTS,
  SET_PARTICIPANT
} from './actions';

const ParticipantReducer = (state, action) => {
  switch (action.type) {
    case SET_PARTICIPANTS: {
      return {
        ...state,
        participants: action.participants
      }
    }
    case SET_PARTICIPANT: {
      return {
        ...state,
        participants: state.participants.push(action.participant)
      }
    }
    default:
      return state
  }
}

export default ParticipantReducer
