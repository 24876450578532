import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "react-bootstrap/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Switch from "@material-ui/core/Switch";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CheckIcon from "@material-ui/icons/Check";
import { formatNumber } from "../../utils/helpers";

import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { configContext, withConfig } from "../../provider/config/ConfigProvider";
import EnhancedTableHead from "../common/datatable/EnhancedTableHead";
import DataTablePagination from "../common/datatable/DataTablePagination";
import SiteDataRow from "./SiteDataRow";

const headCols = [
  {
    id: "common_name",
    label: "Site Name" ,
    sortable: true,
  },
  {
    id: "address_1",
    label: "Address"
  },
  {
    id: "county",
    label: "County"
  }
];

const SiteDataTable = (props) => {
  const {
    rows,
    setRows,
    setSelected,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    changeArchive,
    changeRequireContactVerification,
    google,
    totalRows,
  } = props;
  const config = useContext(configContext);
  const [open, setOpen] = React.useState(-1);
  const mapStyles = {
    width: "100%",
    height: "265px",
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    setSelected(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeArchive = (id) => {
    changeArchive(id);
  };

  const handleChangeRequireContactVerification = (id) => {
    changeRequireContactVerification(id);
  };

  useEffect(() => {
    setRows(rows);
  }, [rows]);

  return <div>
    <DataTablePagination
      rowsPerPageOptions={[25, 50, 100]}
      totalRows={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    <div style={{
      overflowX: "auto",
    }}>
      <Table hover style={{
        minWidth: 350,
        borderCollapse: 'initial !important',
        borderSpacing: '0 !important',
        borderRadius: '0.42rem',
      }}>
        <EnhancedTableHead
          headCols={headCols}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <tbody>
        {rows.map((row) => {
          return <SiteDataRow
            handleClick={() => handleClick(row.site_id)}
            key={row.site_id}
            row={row}
          />
        })}
        </tbody>
      </Table>
    </div>
    <DataTablePagination
      rowsPerPageOptions={[25, 50, 100]}
      totalRows={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </div>
/*
  return (
    <div>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              return (
                <React.Fragment key={row.site_id}>
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => handleClick(row.site_id)}
                    tabIndex={-1}
                  >
                    <TableCell
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          open === index ? setOpen(-1) : setOpen(index)
                        }
                      >
                        {open === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.site}</TableCell>
                    <TableCell align="left">{row.site_type}</TableCell>
                    <TableCell align="left">
                      {row.address}
                      <br />
                      {row.city}, {row.state} {row.zip} ({row.county})
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Switch
                        checked={!row.archive}
                        onChange={(event) => handleChangeArchive(row.site_id)}
                        color="secondary"
                        name={`archive-${row.site_id}`}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row.require_identification ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Switch
                        checked={row.require_contact_verification}
                        onChange={(event) =>
                          handleChangeRequireContactVerification(row.site_id)
                        }
                        color="secondary"
                        name={`require-contact-verification-${row.site_id}`}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className={RowClasses.root}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Card
                            variant="outlined"
                            className={
                              row.latitude && row.longitude
                                ? "map-container"
                                : ""
                            }
                          >
                            <CardContent>
                              <div className="row">
                                <div className="col-sm-4">
                                  <Typography
                                    variant="button"
                                    display="block"
                                    className="mb-3"
                                  >
                                    {row.contact_name}
                                  </Typography>

                                  <a href={`tel:${row.contact_phone}`}>
                                    <Typography
                                      variant="subtitle2"
                                      display="block"
                                    >
                                      {row.contact_phone}
                                    </Typography>
                                  </a>

                                  <a href={`mailto:${row.contact_email}`}>
                                    <Typography
                                      variant="subtitle2"
                                      display="block"
                                    >
                                      {row.contact_email}
                                    </Typography>
                                  </a>

                                  <Typography
                                    variant="h5"
                                    component="h2"
                                    className="mt-6"
                                  >
                                    {row.district}
                                  </Typography>

                                  <Typography variant="body2" component="p">
                                    {row.clia}
                                  </Typography>

                                  <Typography variant="body2" component="p">
                                    <Link
                                      to={`/sites/${row.site_id}/participant`}
                                    >
                                      Print QR Codes
                                    </Link>
                                  </Typography>

                                  <Typography variant="body2" component="p">
                                    <Link to={`/sites/${row.site_id}/test`}>
                                      Tests at site
                                    </Link>
                                  </Typography>

                                  <Typography variant="body2" component="p">
                                    <a
                                      href={`${config.url.register}?site_id=${row.site_id}`}
                                    >
                                      Registration Link
                                    </a>
                                  </Typography>
                                </div>

                                {row.latitude && row.longitude ? (
                                  <div className="col-sm-8 site-map-location">
                                    <Map
                                      google={google}
                                      zoom={16}
                                      style={mapStyles}
                                      initialCenter={{
                                        lat: row.latitude,
                                        lng: row.longitude,
                                      }}
                                    >
                                      <Marker
                                        name={row.site}
                                        position={{
                                          lat: row.latitude,
                                          lng: row.longitude,
                                        }}
                                      />
                                    </Map>
                                  </div>
                                ) : null}
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `${formatNumber(from)}-${formatNumber(to)} of ${formatNumber(count)}`
        }
      />
    </div>
  );
  */
};

export default SiteDataTable;

/*export default withConfig(GoogleApiWrapper((props) => ({
  apiKey: props.config.google_maps,
}))(SiteDataTable));*/
