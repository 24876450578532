// https://dev.to/ms_yogii/useaxios-a-simple-custom-hook-for-calling-apis-using-axios-2dkj
import { useCallback } from "react";
import axios from 'axios';

const useAxios = ({
  url,
  method = 'get',
  body = null,
  headers = null
}) => {
  return useCallback(() => {
    return axios({
      url,
      method,
      headers: headers,
      data: body,
    });
  }, [url, method, body, headers]);
};

export default useAxios;
