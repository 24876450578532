import useApiService from "../../../hooks/useApiService";
import React, { useMemo } from "react";
import WidgetCard from "../../common/partial/widget/WidgetCard";
import useAxiosResponse from "../../../hooks/useAxiosResponse";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export default function SpreadsheetRowReview ({id, row}) {
  const rowUrl = `/multiform/stroke/upload/${id}/row/${row}`;

  const fetchRow = useApiService({url: rowUrl});
  const [responseRow] = useAxiosResponse(fetchRow);

  const parsedErrors = useMemo(() => {
    try {
      console.log(responseRow.response);
      const { error, cells, rowLabel, headerRowLabel, expandedForm, coercedForm } = responseRow.response;
      const { errors, ajv } = error;
      if (ajv) {
        return errors.map((error) => {
          const { instancePath, keyword, message } = error;
          //name is the last part of the instancePath
          const name = instancePath.split('/').pop();
          // parsedValue is the value of the field nested in expandedForm using the instancePath as the path
          const parsedValue = instancePath.split('/').reduce((acc, curr) => {
            if (curr) return acc[curr];
            else return acc;
          }, expandedForm);
          const coercedValue = instancePath.split('/').reduce((acc, curr) => {
            if (curr) return acc[curr];
            else return acc;
          }, coercedForm);
          const cellIndex = cells.findIndex((cell) => !!cell && typeof cell === 'object' && cell.name === name);
          const cell = cells[cellIndex];
          const {columnLabel} = (cell || {});
          return {
            cellIndex,
            name,
            keyword,
            message,
            parsedValue,
            coercedValue,
            columnLabel,
            rowLabel,
            headerRowLabel
          }
        }).sort((first, second) => first.cellIndex - second.cellIndex);
      }
      return [];
    } catch (e) {
      console.warn (e);
      return [];
    }
  }, [responseRow.response, responseRow.status]);

  const displayTable = useMemo(() => {
    const columnLabels = [];
    const columnNames = [];
    const columnValues = [];
    const columnTooltips = [];
    let lastCellIndex = 0;
    for (let i = 0; i < parsedErrors.length; i++) {
      const parsedError = parsedErrors[i];
      if (columnLabels.length === 0) {
        columnLabels.push('');
        columnNames.push(parsedError.headerRowLabel);
        columnValues.push(parsedError.rowLabel);
        columnTooltips.push(null);
      }
      if (lastCellIndex < parsedError.cellIndex - 1) {
        columnLabels.push('...');
        columnNames.push('');
        columnValues.push('');
        columnTooltips.push(null);
      }
      lastCellIndex = parsedError.cellIndex;
      columnLabels.push(parsedError.columnLabel);
      columnNames.push(parsedError.name);
      columnValues.push(parsedError.parsedValue);
      columnTooltips.push(parsedError);
    }
    return <Table bordered>
      <tbody>
        <tr>
          {columnLabels.map((columnLabel, key) => <th key={key}>{columnLabel}</th>)}
        </tr>
        <tr>
          {columnNames.map((columnName, key) => <td key={key}>{columnName}</td>)}
        </tr>
        {columnNames[0] < columnValues[0] - 1 ? <tr><td>...</td></tr> : null}
        <tr>
          {columnValues.map((columnValue, key) => {
            const tooltip = columnTooltips[key];
            if (tooltip) {
              const {keyword, message, coercedValue, parsedValue} = tooltip;
              return <OverlayTrigger
                key={key}
                placement={'auto-end'}
                overlay={(props) =>
                  <Tooltip {...props} id={`tooltip-progress-error-${key}`} >
                    {keyword}: {message}<br/>
                    Parsed value: {parsedValue}<br/>
                    Coerced value: {coercedValue}
                  </Tooltip>
                }
              >
                <td className={columnTooltips[key] ? 'bg-light-danger' : ''} key={key}>
                  {columnValue}
                </td>
              </OverlayTrigger>
            }
            return <td key={key}>{columnValue}</td>
          })}
        </tr>
      </tbody>
    </Table>
  }, [parsedErrors, responseRow.response]);

  return <WidgetCard>
    {responseRow.loading ? <Spinner animation={'border'}/> :
      parsedErrors.length > 0 ? displayTable : null
    }
  </WidgetCard>
}
