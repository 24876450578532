import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "10px",
    backgroundColor: "#e4e4e4",
    maxHeight: "400px",
    overflowY: "scroll",
    borderRadius: "3px"
  },
  dialogFooter: {
    padding: "20px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const WelcomeDialog = withStyles(styles)((props) => {
  const { classes } = props;
  // FIXME update eula
  const [open, setOpen] = React.useState(false || localStorage.acceptedEula === "false");
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <img
              alt="Logo"
              className="max-h-50px"
              src={toAbsoluteUrl("/media/license.png")}
            />
          End-user license agreement
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <Typography gutterBottom>
            These Terms of Use ("Terms of Use") govern your access and use of the ETS Testing Application Portal (the "Portal"). These Terms of Use are a legally binding agreement between you and Emergent Technology Solutions (collectively, "ETS", "we", us" or "our").
            </Typography>
            <Typography gutterBottom>
            Please read the Terms of Use carefully before you start to use the Portal. YOU AGREE TO BE BOUND BY THESE TERMS OF USE WHEN YOU CLICK "I AGREE" AND/OR CONTINUE TO ACCESS OR USE THE PORTAL. IF YOU DO NOT UNDERSTAND OR AGREE TO BE BOUND BY THESE TERMS OF USE, DO NOT ACCESS OR USE THE PORTAL.
            </Typography>
            <Typography gutterBottom>
            1. Right to Use the Portal. By agreeing to these Terms of Use, you confirm that you are a resident of the United States and are at least 18 years of age. You agree to comply with these Terms of Use, and all applicable federal, state, and local laws and regulations at all times when using the Portal. You agree to not abuse, harm, interfere with or disrupt the Portal.
            </Typography>
            <Typography gutterBottom>
            2. Right to Change the Terms of Use. ETS may, at any time and from time to time, change these terms and conditions. Any changes to these terms and conditions will be effective immediately upon posting of the changed terms and conditions on the Portal. You agree to review these terms and conditions periodically, and use of the Portal following any such change constitutes your agreement to follow and be bound by the terms and conditions as changed.
            </Typography>
            <Typography gutterBottom>
            3. Access to the Portal; User Accounts. You or your employer will provide or choose a unique username and a password as part of our security procedures. You must treat such information as confidential, and you must not disclose it to any other person or entity. Your account is personal to you and you agree not to provide any other person with access to the Portal or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. We have the right to disable your account, at any time if, in our opinion, you have violated any provision of these Terms of Use.
            </Typography>
            <Typography gutterBottom>
            4. Provision of Data. You agree to provide accurate, current and complete information to us, and that you have all necessary consents or authorizations to provide any information that you provide to us. If you are a health care provider or a health plan, you represent and warrant that when using the Portal you will comply with all applicable laws and regulations, including the Health Insurance Portability and Accountability Act and its implementing regulations and other federal and state privacy and data security laws, and you will not provide any information, including protected health information, to us for which you do not have the required authorizations or consents.
            </Typography>
            <Typography gutterBottom>
            5. Data Privacy. You agree that you will only access the Portal and information contained in the Portal to the extent you have permission or legal authority to do so. You further agree that you will not use, disclose, reproduce, distribute, share, display, and/or transmit that information in a way that infringes the privacy or other rights of another person. You agree that you will at all times comply with all laws, statutes, regulations, ordinances, or restrictions of any governing body or governmental entity, whether state, federal or otherwise, currently existing or hereafter enacted, which governs, controls, restricts or is otherwise applicable to the receipt, gathering, storage, maintenance, transmission, processing, reporting, disclosure, release or other use of patient information through or in connection with any use of the Portal.
            </Typography>
            <Typography gutterBottom>
            6. Proprietary Rights. The Portal and its contents are protected by intellectual property laws and you are granted only a limited right to remotely access the Portal. These Terms of Use do not grant you any rights in connection with any ETS copyrighted materials, trademarks or service marks, or any other intellectual proprietary rights with respect to the Portal or its contents. All title and intellectual property and other rights in and to the Portal and its contents are owned by ETS.
            </Typography>
            <Typography gutterBottom>
            7. No Warranty. THE PORTAL AND ALL MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, TOOLS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. ETS DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES WITH REGARD TO THE PORTAL AND ALL MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, TOOLS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOUR USE OF THE PORTAL IS AT YOUR OWN RISK. ACCESS TO THE PORTAL MAY BE INTERRUPTED AND THE MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, TOOLS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL MAY NOT BE ERROR-FREE. NONE OF ETS, ITS SUPPLIERS, OR ANYONE ELSE INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE PORTAL OR THE MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, TOOLS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL WARRANTS THAT THE MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, TOOLS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL ARE ACCURATE, RELIABLE, COMPLETE, USEFUL, OR CORRECT; THAT THE PORTAL WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE PORTAL ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PLEASE NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR LIMITATIONS REGARDING THE EXCLUSION OF IMPLIED WARRANTIES.
            </Typography>
            <Typography gutterBottom>
            8. Limitation Of Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL ETS, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PORTAL OR ANY CONTENT ON THE PORTAL, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. YOU ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THESE TERMS OF USE AND THE PORTAL WOULD NOT BE PROVIDED TO YOU ABSENT SUCH LIMITATIONS.
            </Typography>
            <Typography gutterBottom>
            9. Indemnification. You agree to indemnify, defend and hold harmless ETS and its affiliates, employees, officers, directors, agents, servants and representatives from any liability, loss, claim, suit, damage, and expense (including reasonable attorneys´ fees and expenses) related to your violation of these Terms of Use.
            </Typography>
            <Typography gutterBottom>
            10. Choice of Law. These Terms of Use (and any claims or disputes arising out of or related thereto or to the transactions contemplated thereby or to the inducement of any party to enter therein, whether for breach of contract, tortious conduct, or otherwise and whether predicated on common law, statute, or otherwise) shall in all respects be governed by and construed in accordance with the laws of the State of Texas, including all matters of construction, validity, and performance, in each case without reference to any conflict of law rules that might lead to the application of the laws of any other jurisdiction.
            </Typography>
            <Typography gutterBottom>
            11. Entire Agreement. These Terms of Use are the entire agreement between the Parties hereto concerning its subject matter. These Terms of Use may not be amended, modified or waived except by a written instrument signed by duly authorized representatives of each of the Parties hereto.
            </Typography>

          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              localStorage.setItem('acceptedEula', true);
              handleClose();
            }} color="secondary">
              I Accept
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

export default WelcomeDialog;
