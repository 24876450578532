import React, { useEffect, useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import { useStateValue } from "../../store/StateProvider";
import { HIDE_NOTIFICATION } from "../../store/notification";
import { isNullOrUndefined } from "../../utils/helpers";

const useStyles = makeStyles(() => ({
  errorAlert: {
    backgroundColor: "#f44336",
  },
}));

const Alert = () => {
  const [{ notification }, dispatch] = useStateValue();
  const errorMessage = localStorage.getItem("errorMessage");
  const [openNotify, setOpenNotify] = useState(false);
  const [alert, setAlert] = useState({
    type: '',
    message: ''
  });
  const classes = useStyles();

  useEffect(() => {
    if (openNotify === false) {
      dispatch({
        type: HIDE_NOTIFICATION,
      });
    }
  }, [openNotify, dispatch]);

  useEffect(() => {
    setAlert({
      type: notification ? notification.type : '',
      message: notification ? notification.message : ''
    });
    setOpenNotify(notification !== null);
  }, [notification]);

  useEffect(() => {
    if (!isNullOrUndefined(errorMessage)) {
      setAlert({
        type: 'error',
        message: errorMessage
      });
      setOpenNotify(true);
    }
  }, [errorMessage]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    if (errorMessage) {
      localStorage.removeItem("errorMessage");
    }

    setOpenNotify(false);
  }

  return (
    openNotify && (
      <Snackbar
        open={openNotify}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <MuiAlert
          className={`custom-alert ${
            alert.type === "error" ? classes.errorAlert : ""
          }`}
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alert.type}
        >
          {alert.message}
        </MuiAlert>
      </Snackbar>
    )
  );
};

export default Alert;
