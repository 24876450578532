import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

//import _ from 'lodash';

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import UserForm from "../../components/user/UserForm";

import UserService from "../../services/user";
import { userToViewModel, viewModelToUserData } from '../../mapping/user';
import { useHasRoles, useNotification } from "../../utils/customHooks";
import { RoleEnum } from "../../config/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const EditUser = () => {
  const history = useHistory();
  const setNotification = useNotification();
  const classes = useStyles();
  const [user, setUser] = useState(undefined);
  const { id } = useParams();
  //const hasRoleAdminRole = useHasRoles([RoleEnum.RoleAdmin]);
  //const hasTableauAdminRole = useHasRoles([RoleEnum.TableauAdmin]);

  const updateUser = async (updatedUser, initUser) => {
    try {

      const { userData, userRoles, userDashboardGroups } = viewModelToUserData(updatedUser);
      //const shouldUpdateUserRoles = hasRoleAdminRole && !_.isEqual(updatedUser.userRoles, initUser.userRoles);
      //const shouldUpdateDashboardGroups = hasTableauAdminRole && !_.isEqual(updatedUser.userDashboardGroups, initUser.userDashboardGroups);
      const rolesData = userRoles;
      const dashboard_group_ids = userDashboardGroups.map(x => x.dashboard_group_id);
      UserService.editUser({
        rolesData,
        dashboard_group_ids,
        ...userData
      }).then(res => {
        setNotification({
          type: "success",
          message: "Update user successfully"
        });
        history.push("/user");
      }).catch(() => {
        setNotification({
          type: "error",
          message: "Failed to update user! Please try again!"
        });
      });

    } catch (e) {
      console.log(e)
      console.log(e.response)
    }
  };

  useEffect(() => {
    (async function () {
      UserService.getUser(id).then((res) => {
        const user = res.data;
        const newUser = userToViewModel(user, user.rolesData, user.dashboardGroups);
        setUser(newUser);
      });
    })();
  }, [setUser, id]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserForm
          initUser={user}
          formLabel={"Edit User"}
          saveUser={updateUser}
        />
      </Paper>
    </div>
  );
};

export default EditUser;
