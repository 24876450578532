import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
}));

const DataTableHeader = ({label, children}) => {
  const classes = useStyles();

  return (
    <div className={`container-fluid row ${classes.container}`}>
      {label ?
      <div className="col-lg-2 mt-6">
        <Typography variant="h6" id="subtitle1">
          {label}
        </Typography>
      </div> : null }
      {children}
    </div>
  )
}

DataTableHeader.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node
};

export default DataTableHeader;
