import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SiteForm from '../../components/site/SiteForm';

import SiteService from "../../services/site";
import { useNotification } from '../../utils/customHooks';
import { bodyToFormik } from "../../utils/helpers";

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	}
}));

const EditSite = () => {
	const { id } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const setNotification = useNotification();
	const [site, setSite] = useState();

	const updateSite = async (site) => {
		let res = await SiteService.editSite(site);

		if (res && res.status === 200) {
			setNotification({
				type: "success",
				message: "Site record has been updated"
			});
			history.push('/sites');
		} else {
			setNotification({
				type: "error",
				message: "Failed to update site! Please check and try again!"
			});
		}
	}

    useEffect(() => {
		(async function () {
			setSite(undefined);
			let result = await SiteService.getSite(id);
			setSite(bodyToFormik(result.data));
		  })();
	}, [setSite, id]);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<SiteForm formLabel={"Edit Site"} initSite={site} key={id} saveSite={updateSite} />
			</Paper>
		</div>
	);
};

export default EditSite;
