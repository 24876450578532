import React, { useEffect, useState } from "react";
import UploadForm from "../../components/upload/UploadForm";
import { uploadForm, uploadList } from "../../services/uploads";

export default function Demo () {


  return <>

  </>;
}