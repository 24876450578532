import PropTypes from "prop-types";
import React from "react";
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function TableHead ({col, orderBy, order, onRequestSort}) {
  const property = col.id;
  const sortDirection = orderBy === col.id ? order : false;

  const orderSelected = (_order) => {
    return typeof sortDirection === "string" && sortDirection.toLowerCase() === _order.toLowerCase();
  }

  return <th
    align="left"
    className={sortDirection ? 'text-primary' : ''}
    onClick={(e) => {
      if (col.sortable && !col.filterable) {
        onRequestSort(e, property);
      }
    }}
    style={{
      width: col.width,
      maxWidth: col.maxWidth
    }}
  >
    <span>{col.label}</span>
    {col.sortable ? <>
        <span
          onClick={(e) => {
            if (col.sortable && col.filterable) {
              onRequestSort(e, property);
            }
          }}
          style={{
            userSelect: "none",
            opacity: orderSelected('ASC') ? 1 : 0.4
          }}
          className={'float-right'}
        >↑</span>
      <span
        onClick={(e) => {
          if (col.sortable && col.filterable) {
            onRequestSort(e, property);
          }
        }}
        style={{
          userSelect: "none",
          opacity: orderSelected('DESC')  ? 1 : 0.4
        }}
        className={'float-right'}
      >↓</span>
    </> : null }
    {col.filterable ? <span style={{
      userSelect: 'none',
      //opacity: orderSelected('DESC') ? 1 : 0.4
    }} className={'float-right'}>
      <OverlayTrigger
        trigger={['click']}
        placement="bottom"
        overlay={<Tooltip>{col.filterable}</Tooltip>}
      >
        <Button
          size={'sm'}
          variant={col.filtered ? 'primary-light' : 'light'}
          style={{
            padding: '0.2rem',
          }}
          className="d-inline-flex align-items-center btn-transparent-primary py-0"
        >
          <span className="svg-icon menu-icon mr-0 ">
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/Text/Filter.svg')}
            />
          </span>
        </Button>
      </OverlayTrigger>
    </span> : null}
  </th>
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCols } = props;

  return (
    <thead>
    <tr>
      {headCols.map((col) => (
        <TableHead
          key={col.id}
          col={col}
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
        />
      ))}
    </tr>
    </thead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headCols: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    searchable: PropTypes.bool     // TODO implement searchable
  })).isRequired,
};

export default EnhancedTableHead;
