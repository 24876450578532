import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Button, Col, Row, Spinner, Dropdown, ButtonGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { components } from "react-select";
import { Link } from 'react-router-dom';
import useIsMounted from '../../../hooks/useIsMounted';
import { service } from '../../../services/api-service';
import { buildQueryString } from '../../../utils/queryString';

const SiteLevelOption = props => {
  return <components.Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.common_name || props.data.legal_name}
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.dashboard_level_code
      ? <small className="text-muted"> | {props.data.dashboard_level_code}</small>
      : null}
  </components.Option>
}

export default function GroupSiteField (props) {
  const {field, form, sites, dashboardLevels, label, sitesWithChildren} = props;
  const {setFieldValue} = form;
  const {name, value} = field;
  const isMounted = useIsMounted()

  const selectedLevel = form.values['dashboard_level_code'];
  const [siteHover, setSiteHover] = useState(null);
  const [siteSelect, setSiteSelect] = useState(null)
  const [siteHighlight, setSiteHighlight] = useState([])
  const [childSites, setChildSites] = useState({});
  const [includeSites, setIncludeSites] = useState([]);

  const loadingChildrenMessage = useMemo(() => {
    if (!sitesWithChildren) {
      return ''
    }
    const total = sitesWithChildren.length;
    const progress = Object.entries(childSites).length
    if (progress >= total) {
      return ''
    }
    return `Loading ${progress} of ${total} children...`
  }, [sitesWithChildren, childSites])

  useEffect(() => {
    const site_ids = (sitesWithChildren || []).map(site => site.site_id);
    for (const site_id of site_ids) {
      if (!childSites[site_id]) {
        service({
          method: 'get',
          url: `/site/${site_id}/children${buildQueryString({
            rowsPerPage: 0,
            page: 0,
            childrenOnly: true
          })}`,
        }).then((data) => {
          setChildSites((prev) => {
            return {
              ...prev,
              [site_id]: data.data.results.map(({site_id}) => site_id)
            }
          });
          sites.updateRows(data.data.results);
        }).catch(console.warn)
        break;
      }
    }
  }, [sitesWithChildren, childSites, setChildSites, isMounted, sites, sites.updateRows])

  useEffect(() => {
    if (siteHover === null && siteSelect === null) {
      setSiteHighlight([])
    } else {
      const hovered = childSites[siteHover] || []
      const selected = childSites[siteSelect] || []
      if (typeof setIncludeSites === 'function') {
        setIncludeSites(selected)
      }
      setSiteHighlight([...hovered, ...selected])
    }
  }, [siteSelect, siteHover, setSiteHighlight, childSites, setIncludeSites])

  const handleAdd = useCallback((site) => {
    if (!site) return;
    if (!Array.isArray(site)) site = [site];
    const site_ids = site.map(site => site.site_id);
    const newSites = new Set([...(value || []), ...site_ids]);
    setFieldValue(name, Array.from(newSites));
  }, [name, setFieldValue, value]);

  const handleRemove = useCallback((site) => {
    if (!site) return;
    if (!Array.isArray(site)) site = [site];
    const site_ids = site.map(site => site.site_id);
    setFieldValue(name, value.filter(site_id => !site_ids.includes(site_id)));
  }, [name, setFieldValue, value]);

  const [groupSites, maxRows] = useMemo(() => {
    const byLevel = {};
    let maxRows = 0;
    if (!value || !sites || !sites.options) return [byLevel, maxRows];
    for (const site_id of value) {
      const site = sites.options.find(site => `${site.site_id}` === `${site_id}`);
      if (!site) continue;
      const level = site.dashboard_level_code || null;
      if (!byLevel[level]) byLevel[level] = [];
      byLevel[level].push(site);
    }
    for (const level of Object.keys(byLevel)) {
      maxRows = Math.max(maxRows, byLevel[level].length);
    }
    return [byLevel, maxRows];
  }, [sites.options, value]);

  const levels = (dashboardLevels || []).concat({dashboard_level_code: null, dashboard_level: 'Uncategorized'});

  return <Row>
    <Col>
      <Row>
        <Col>
          <Row>
            <Col xs={'auto'}><h6>{label}</h6></Col>
            {loadingChildrenMessage ? <Col>
              <Spinner animation={'border'} size={'sm'}/>
              {loadingChildrenMessage}
            </Col>  : null}
          </Row>
        </Col>
        <Col>
          <AsyncSelect
            name={name}
            placeholder={'Add a site.'}
            //isMulti
            //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
            loadOptions={sites.loadOptions}
            getOptionLabel={option => option.common_name || option.legal_name}
            getOptionValue={option => option.site_id}
            loadValue={sites.loadValue}
            defaultOptions={sites.options}
            onChange={handleAdd}
            isClearable={true}
            value={null}
            menuPlacement={'auto'}
            components={{Option: SiteLevelOption}}
            filterOption={(option) => {
              if (includeSites.length > 0) {
                if (!includeSites.includes(option.value)) {
                  return false;
                }
              }
              return (value || []).findIndex(site_id => `${site_id}` === `${option.value}`) === -1;
            }}
          />
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <Table bordered>
            <thead>
              <tr>
                {levels.map((level) => {
                  return <th
                    key={level.dashboard_level_code}
                    className={`${selectedLevel === level.dashboard_level_code ? 'bg-light-primary' : ''}`}
                    style={{
                      width: `${100 / levels.length}%`
                  }}>
                    {level.dashboard_level}
                  </th>;
                })}
              </tr>
            </thead>
            <tbody>
              {Array(maxRows).fill(0).map((_, i) => {
                return <tr key={i}>
                  {levels.map((level) => {
                    let site = undefined;
                    if (groupSites[level.dashboard_level_code] && groupSites[level.dashboard_level_code][i]) {
                      site = groupSites[level.dashboard_level_code][i];
                    }
                    const site_id = (site || {}).site_id
                    const codeHighlight = selectedLevel === level.dashboard_level_code
                    const hoverHighlight = siteHover === site_id
                    const childHighlight = siteHighlight.includes(site_id)
                    const selectHighlight = siteSelect === site_id
                    const selectable = (sitesWithChildren || []).find((s) => s.site_id === site_id)

                    let bg = ''
                    let hover = '';
                    if (selectable) {
                      hover = 'bg-hover-secondary'
                      bg = 'bg-light'
                    }
                    if (codeHighlight) {
                      bg = 'bg-light-primary'
                      if (selectable) {
                        bg = 'bg-primary-o-60'
                      }
                    }
                    if (childHighlight) {
                      bg = 'bg-info-o-60'
                    }
                    if (selectHighlight) {
                      bg = 'bg-gray-400'
                      hover = 'bg-hover-state-secondary'
                    }

                    if (hoverHighlight && selectable || childHighlight) {
                      //className += ' border-info'
                    }
                    const className = `${bg} ${hover}`.trim() || ''

                    let childCount = ''
                    if (selectable) {
                      const currentChildren = childSites[site_id]
                      const addedChildren = (currentChildren || []).filter((child) => value.includes(child))
                      if (currentChildren) {
                        childCount = `(${addedChildren.length}/${currentChildren.length})`
                      }
                    }

                    return <td
                      onClick={() => setSiteSelect((prev) => {
                        const value = (site || {}).site_id
                        if (value === prev || !selectable) {
                          return null
                        }
                        return value || null
                      })}
                      onMouseEnter={() => setSiteHover(selectable && site_id || null)}
                      onMouseLeave={() => setSiteHover(null)}
                      className={className}
                      key={`${level.dashboard_level_code}_${i}_${(site || {}).site_id}`}>
                      {site ? <>{site.common_name || site.legal_name}{childCount ? <div className={'text-muted'}>{childCount}</div> : null}
                        <Row>
                          <Col>
                            <Button
                              as={Link}
                              size={'sm'}
                              className={'mr-2 btn-outline-dark'}
                              to={`/site/edit/${site.site_id}`}
                            >
                              Edit
                            </Button>
                            <Dropdown
                              className={'mr-2'}
                              as={ButtonGroup}>
                              <Button
                                size={'sm'}
                                className={'btn-outline-primary'}
                                onClick={() => handleRemove(site)}
                              >
                                Remove
                              </Button>
                              {selectable ? <>
                              <Dropdown.Toggle split className={'btn-outline-primary'} id="dropdown-split-basic" />
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                  const {site_id} = site;
                                  const sites = [...(childSites[site_id] || [])].map((id) => {
                                    return {site_id: id}
                                  })
                                  handleAdd(sites)
                                }}>Add All</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  const {site_id} = site;
                                  const sites = [site_id, ...(childSites[site_id] || [])].map((id) => {
                                    return {site_id: id}
                                  })
                                  handleRemove(sites)
                                }}>Remove All</Dropdown.Item>
                              </Dropdown.Menu> </>: null }
                            </Dropdown>
                          </Col>
                        </Row>
                      </> : ''}
                    </td>
                  })}
                </tr>
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
  </Row>

}
