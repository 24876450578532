import React, { useCallback, useState } from "react";
import WidgetCard from '../../common/partial/widget/WidgetCard';
import useIsMounted from '../../../hooks/useIsMounted';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function FormConfigSitesReassign (props) {
  const {
    cardClassName = "card-stretch gutter-b",
    fetchRows,
    unsavedSites,
    setUnsavedSites,
    title,
    name
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const isMounted = useIsMounted();
  const history = useHistory();

  const save = useCallback(() => {
    const assignedSites = unsavedSites.filter((site) => site.assigned);
    const unassignedSites = unsavedSites.filter((site) => !site.assigned);
    setLoading(true);
    fetchRows({
      method: 'patch',
      data: {
        removeSites: assignedSites.map((site) => site.site_id),
        addSites: unassignedSites.map((site) => site.site_id),
      }
    }).then(() => {
      if (isMounted()) {
        setUnsavedSites([]);
      }
    }).catch((error) => {
      const {response} = error;
      console.warn(response);
      if (isMounted()) {
        setError(response.data);
      }
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, unsavedSites, setUnsavedSites, setLoading]);

  return <WidgetCard className={cardClassName}>
    <Row>
      <Col>
        {title ? <h3>{title}</h3> : <h3 className={'text-muted'}>{name}</h3>}

      </Col>
      <Col/>
      <Col xs={'auto'}>
        <button
          className={`btn btn-secondary float-right mr-2`}
          type="button"
          onClick={() => {
            history.goBack();
          }}
          disabled={loading}>
          Cancel
        </button>
        <button
          className={`btn btn-${Object.keys(error).length ? 'danger' : 'primary'} float-right mr-2`}
          type="button"
          onClick={save}
          disabled={loading || unsavedSites.length === 0}>
          Submit
        </button>
      </Col>
    </Row>
  </WidgetCard>;
}