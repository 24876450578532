export const RoleEnum = {
  PortalUser: "portal_user",
  TableauAdmin: "tableau_admin",
  CardiacUser: "cardiac_user",
  StrokeUser: "stroke_user",
  UploadUser: "upload_user",
  FormAdmin: "form_admin",
  FormUser: "form_user",
  UserAdmin: "user_admin",
  SiteAdmin: "site_admin",
  RoleAdmin: "role_admin",
  Developer: "developer"
};
