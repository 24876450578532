import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  reauthenticateWithCredential,
  onAuthStateChanged,
  RecaptchaVerifier,
  PhoneAuthProvider,
  EmailAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  multiFactor,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
  getIdToken,
} from "firebase/auth";
import UserService from "../services/user";
import { phoneWithCountryCode } from "../utils/format-phone";

let app;
let auth;
let recaptcha;

export const authMethods = {
  // firebase helper methods go here...
  init: (config) => {
    if (!app) app = initializeApp(config);
    auth = getAuth(app);
  },
  signup: (email, password, setErrors, setToken) => {
    createUserWithEmailAndPassword(auth, email, password)
      //make res asynchonous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("token", token);
        const acceptedEula = await localStorage.getItem("acceptedEula");
        if (!acceptedEula) {
          await localStorage.setItem("acceptedEula", false);
        }
        setToken(token);
        //grab token from local storage and set to state.
        console.log(res);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err]);
      });
  },
  signin: (email, password, setErrors, setToken) => {
    //change from create users to...
    return signInWithEmailAndPassword(auth, email, password)
      //everything is almost exactly the same as the function above
      .then( (res) => {
        return setUserToken(res, setToken);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err]);
      });
  },
  forgotpassword: (email, setErrors) => {
    sendPasswordResetEmail(auth, email)
      .then(async (res) => {
        window.location = "/";
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err]);
      });
  },

  signout: (setErrors, setToken, errorMessage) => {
    if (!auth) return;
    // signOut is a no argument function
    signOut(auth)
      .then((res) => {
        //remove the token
        localStorage.removeItem("token");
        localStorage.removeItem("roles");

        if (errorMessage && typeof errorMessage === "string") {
          localStorage.setItem("errorMessage", errorMessage);
        }

        //set the token back to original state
        setToken(null);
        window.location = "/";
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err]);
        //whether firebase does the trick or not i want my user to do there thing.
        setToken(null);
        console.error(err.message);
      });
  },
  setRecaptcha: (container) => {
    recaptcha = new RecaptchaVerifier(container, {
      //'siteKey': ,
      'size': 'invisible',
      'callback': function () {},
      'expired-callback': () => {}
    }, auth);
    return recaptcha;
  },
  multiFactorResolver: (error) => {
    return getMultiFactorResolver(auth, error);
  },
  signInWithPhone2FA: (hint, resolver) => {
    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: resolver.session
    };
    return verifyPhoneNumber(phoneInfoOptions);
  },
  confirmSignIn: (verificationId, verificationCode, resolver, setToken, redirectTo) => {
    const multiFactorAssertion = getMultiFactorAssertion(verificationId, verificationCode);
    return resolver.resolveSignIn(multiFactorAssertion).then((response) => {
      return setUserToken(response, setToken, redirectTo);
    });
  },
  enrollUserPhone2FA: (phone) => {
    return userMultiFactor().getSession().then((authSession) => {
      const phoneInfoOptions = {
        phoneNumber: phoneWithCountryCode(phone),
        session: authSession
      };
      return verifyPhoneNumber(phoneInfoOptions);
    });
  },
  confirmEnrollment: (verificationId, verificationCode) => {
    const multiFactorAssertion = getMultiFactorAssertion(verificationId, verificationCode)
    return userMultiFactor().enroll(multiFactorAssertion);
  },
  unenrollUserPhone2FA: (enrolledFactor) => {
    return userMultiFactor().unenroll(enrolledFactor);
  },
  reauthenticate: (password, setErrors, setToken) => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, password);
    return reauthenticateWithCredential(user, cred)
      .then( (res) => {
        return setUserToken(res, setToken, false);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err]);
      });
  },
  getToken: (refresh = false) => {
    const user = auth.currentUser;
    if (!user) return Promise.resolve(null);
    return getIdToken(user, refresh);
  },
  onAuthStateChanged: (nextOrObserver) => onAuthStateChanged(auth, nextOrObserver),
  verifyPasswordReset: (actionCode) => {
    return verifyPasswordResetCode(auth, actionCode);
  },
  confirmPasswordReset: (actionCode, newPassword) => {
    return confirmPasswordReset(auth, actionCode, newPassword);
  },
  checkActionCode: (actionCode) => {
    return checkActionCode(auth, actionCode);
  },
  applyActionCode: (actionCode) => {
    return applyActionCode(auth, actionCode);
  }
};

export const userMultiFactor = () => {
  const currentUser = auth.currentUser;
  if (!currentUser) return undefined;
  return multiFactor(currentUser);
}

const verifyPhoneNumber = (phoneInfoOptions) => {
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha);
}

export function getMultiFactorAssertion (verificationId, verificationCode) {
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
  return PhoneMultiFactorGenerator.assertion(cred);
}

const setUserToken = async (userCredential, setToken, redirectTo) => {
  var firebaseAuth = JSON.parse(JSON.stringify(userCredential.user));
  var token = firebaseAuth.stsTokenManager.accessToken;

  //set token to localStorage
  localStorage.setItem("token", token);

  await setUserRoles();

  const acceptedEula = await localStorage.getItem("acceptedEula");
  if (!acceptedEula) {
    await localStorage.setItem("acceptedEula", false);
  }

  if (typeof redirectTo === 'undefined') {
    window.location = "/";
  } else if (typeof redirectTo === 'string') {
    window.location = redirectTo;
  }
  setToken(window.localStorage.token);
}

const setUserRoles = async () => {
  try {
    var userRolesRes = await UserService.getUserSelfRoles();
    var userRoles = extractRoles(userRolesRes.data);

    localStorage.setItem("roles", JSON.stringify(userRoles));
  } catch (e) {
    console.log(e)
    throw e
  }
};

export const extractRoles = (rolesRes) => {
  var userRoles = {};

  for (const [siteId, { roles: siteRoles }] of Object.entries(rolesRes)) {
    for (const [roleName, hasRole] of Object.entries(siteRoles)) {
      if (hasRole) {
        if (!Array.isArray(userRoles[roleName])) {
          userRoles[roleName] = [];
        }
        userRoles[roleName].push(siteId);
      }
    }
  }

  return userRoles;
};
