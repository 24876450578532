// https://dev.to/ms_yogii/useaxios-a-simple-custom-hook-for-calling-apis-using-axios-2dkj
import React, { useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const useAxiosResponse = (fetchCallback, autoFetch = true) => {
  const [status, setStatus] = useState(0);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(autoFetch);
  const isMounted = useIsMounted();
  const fetchCount = React.useRef(0);

  const fetchData = () => {
    if (isMounted()) {
      const current = ++fetchCount.current;
      setLoading(true);
      fetchCallback().then((res) => {
        if (isMounted() && current === fetchCount.current) {
          setStatus(res.status);
          setResponse(res.data);
        }
      }).catch((err) => {
        if (isMounted() && current === fetchCount.current) {
          setStatus(err.response.status);
          setError(err);
          setResponse(err.response.data);
        }
      }).finally(() => {
        if (isMounted()) {
          setLoading(false);
        }
      });
    }
  }

  useEffect(() => {
    if (autoFetch) fetchData();
  }, [fetchCallback, autoFetch]);

  return [ {response, error, loading, status}, fetchData ];
}

export default useAxiosResponse;
