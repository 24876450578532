import { Col, Form, Row } from 'react-bootstrap';
import useQueryParamStore from '../../../../hooks/useQueryParamStore';
import { useCallback } from 'react';
export default function UserArchiveFilter () {
  const queryParams = useQueryParamStore('user', 'SET_USER_QP', []);
  const {archive} = queryParams.searchOptions;

  const handleValue = useCallback((value) => {
    queryParams.setSearchOption('archive', value);
  }, [queryParams.setSearchOption]);

  return <Row>
    <Col
      xs={'auto'}
      onClick={() => {
        handleValue(undefined);
      }}>
      <Form.Check
        type="radio"
        label="Show Active"
        checked={archive === undefined || archive === true}
        onChange={() => {
          handleValue(undefined);
        }}/>
    </Col>
    <Col
      onClick={() => {
       handleValue(false);
      }}>
      <Form.Check
        type="radio"
        label="Show Disabled"
        checked={archive === false}
        onChange={() => {
          handleValue(false);
        }}/>
    </Col>
  </Row>;
}