import React, { useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";

const DataTableRow = (props) => {
  const {
    headCols,
    row,
    handleClick,
    ...rest
  } = props;

  const renderCell = useCallback((props, i) => {
    if (typeof headCols[i].renderCell === 'function') {
      return headCols[i].renderCell(props);
    } else {
      return <React.Fragment>{props.row[props.id]}</React.Fragment>
    }
  }, [headCols]);

  const onClick = useCallback(() => {
    if (typeof handleClick === 'function') handleClick(row);
  }, [handleClick, row]);

  return <tr {...rest}>
    <ErrorBoundary
      FallbackComponent={({error, resetErrorBoundry}) => {
        return <th colSpan={headCols.length}>
          {error.message}<button className={'btn btn-danger float-right'} onClick={resetErrorBoundry}>Retry</button>
        </th>
      }}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {headCols.map((column, index) => {
        return <td
          key={column.id}
          onClick={typeof column.onClick === 'function' ? column.onClick : onClick}>
          {renderCell({id: column.id, row}, index)}
        </td>
      })}
    </ErrorBoundary>
  </tr>
}

export default DataTableRow;
