import React, {useState} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import useApiService from '../../hooks/useApiService';
import { useFormikContext } from 'formik';

const UserResendEmail = () => {

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { values } = useFormikContext();
  const send = useApiService({
    url: `/user/${values['user_id']}/resend-email`,
    method: 'post'
  });

  return <Row>
    <Col>
      <Button className={`btn-${error ? 'danger' : 'primary'}`} disabled={loading || sent} onClick={() => {
        if (!loading) {
          setLoading(true);
          setError(false);
          send().then(() => {
            setSent(true);
          }).catch((e) => {
            setError(true);
            console.error(e);
          }).finally(() => {
            setLoading(false);
          });
        }
      }}>{loading
        ? 'Loading'
        : sent ? 'Email Sent' : 'Resend Email'}</Button>
    </Col>
  </Row>
}

export default UserResendEmail;