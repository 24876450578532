import React from 'react';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import UserForm from '../../components/user/UserForm';

import UserService from '../../services/user';
import { useNotification } from '../../utils/customHooks';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	}
}));

const CreateUser = () => {
	const history = useHistory();
	const classes = useStyles();
	const setNotification = useNotification();

	const createUser = async (user) => {
		try {
			let res = await UserService.addUser(user);
			if(res && res.status === 200) {
				setNotification({
					type: "success",
					message: "User record has been created"
				});
				const user_id = res.data.user_id || res.data.results[0].user_id;
				history.push("/user/edit/" + user_id);
			} else {
				setNotification({
					type: "error",
					message: "Failed to create user! Please check and try again!"
				});
			}
		} catch (e) {
			const { response } = e;
			if (response.status === 409) {
				setNotification({
					type: "error",
					message: "An account with this email already exists."
				});
			} else {
				setNotification({
					type: "error",
					message: "Failed to create user! Please check and try again!"
				});
			}
		}
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<UserForm formLabel={"Create User"} saveUser={createUser} />
			</Paper>
		</div>
	);
};

export default CreateUser;
