import React from "react";
import { useStateValue } from "../../store/StateProvider";

import { LayoutSplashScreen } from "../../_metronic/layout";

const Loading = () => {
  const [{ isLoading }] = useStateValue();

  return <LayoutSplashScreen visible={isLoading}/>
};

export default Loading;
