import React, { useEffect, useState } from "react";
import multiFormContext from "./multiFormContext";
import useApiService from "../../hooks/useApiService";
import usePrevious from "../../hooks/usePrevious";
import useAxiosResponse from "../../hooks/useAxiosResponse";

// TODO move data to client/store
const init = {
  // https://rjsf-team.github.io/react-jsonschema-form/
  jsonSchema: {},
  uiSchema: {},
  formData: {}
};

const MultiFormProvider = ({children}) => {

  const [jsonSchema, setJsonSchema] = useState(JSON.stringify(init.jsonSchema));
  const [uiSchema, setUiSchema] = useState(JSON.stringify(init.uiSchema));
  const [formData, setFormData] = useState(init.formData);

  const fetchOptions = useApiService({url: '/multiform/schema'});
  const [options] = useAxiosResponse(fetchOptions);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected) refreshSchema();
  }, [selected]);

  const fetchSchema = useApiService({url: `/multiform/schema/${(selected || {}).value}`});
  const [loaded, refreshSchema] = useAxiosResponse(fetchSchema, false);
  const prevLoading = usePrevious(loaded.loading);
  useEffect(() => {
    if (prevLoading && !loaded.loading && loaded.status === 200) {
      const {jsonSchema, uiSchema, formData} = loaded.response;
      setJsonSchema(JSON.stringify(jsonSchema));
      setUiSchema(JSON.stringify(uiSchema));
      setFormData(formData);
    }
  }, [loaded, prevLoading]);

console.log('render')

  return <>
    <multiFormContext.Provider value={{
      jsonSchema,
      uiSchema,
      formData,
      setJsonSchema,
      setUiSchema,
      setFormData,
      options,
      selected,
      setSelected
    }}>
      {children}
    </multiFormContext.Provider>
  </>;
}

export default MultiFormProvider;
