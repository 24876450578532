import WidgetCard from "../../common/partial/widget/WidgetCard";
import React, { useMemo, useState } from 'react';
import FormButtonRow from "./FormButtonRow";
import { Spinner } from "react-bootstrap";
import templates from "../templates";
import FormNavTabs from './FormNavTabs';
import { useRouteMatch } from 'react-router-dom';

export default function FormViewWidget ({ className, jsonSchema, uiSchema, extraErrors, formData, setFormData, onSubmit, onSave, onCancel, loading}) {
  const match = useRouteMatch();

  const schemaParsed = useMemo(() => {
    //if (typeof jsonSchema === 'object') return jsonSchema;
    if (typeof jsonSchema === 'string') return JSON.parse(jsonSchema);
    return jsonSchema;
  }, [jsonSchema]);
  const uiParsed = useMemo(() => {
    let obj;
    if (typeof uiSchema === 'object') {
      obj = uiSchema;
    } else if (typeof uiSchema === 'string') {
      obj = JSON.parse(uiSchema);
    } else {
      return uiSchema;
    }
    return obj
  }, [uiSchema]);
  const errorsParsed = useMemo(() => {
    //if (typeof extraErrors === 'object') return extraErrors;
    if (typeof extraErrors === 'string') return JSON.parse(extraErrors);
    return extraErrors
  }, [extraErrors]);

  const [errors, setErrors] = useState([]);

  return <WidgetCard className={className}>
    {(schemaParsed) && (uiParsed) && (formData)
    ? <><FormButtonRow errorCount={errors.length} onSubmit={onSubmit} onCancel={onCancel} onSave={onSave} loading={loading}/><FormNavTabs
        basePath={match.url}
        schema={schemaParsed}
        uiSchema={uiParsed}
        extraErrors={errorsParsed}
        setErrors={setErrors}
        formData={formData}
        setFormData={setFormData}
      >
        <FormButtonRow errorCount={errors.length} onSubmit={onSubmit} onCancel={onCancel} onSave={onSave} loading={loading}/>
      </FormNavTabs></>
    : <Spinner animation={'border'}/>}
  </WidgetCard>
}
