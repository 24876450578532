import { SET_AUTH_USER } from './actions';

const AuthReducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        auth: {
          ...state.auth,
          user: action.user
        }
      }
    }
    default:
      return state
  }
}

export default AuthReducer
