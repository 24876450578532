import {components} from "react-select";
import React from "react";

const StateOption = props => {
  return <components.Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.name}
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.abbreviation ? <small className="text-muted"> | {props.data.abbreviation}</small> : null}
  </components.Option>
}

export default StateOption;
