import React from "react";
import {ErrorBoundary} from 'react-error-boundary'
import { Button, Col, Row } from "react-bootstrap";

const WidgetCard = ({
  className = "card-stretch gutter-b",
  header,
  children,
}) => {
  // TODO error handler using error boundry
  return (
    <ErrorBoundary
      FallbackComponent={({error, resetErrorBoundary}) => {
        return <div className={`card card-custom text-center text-danger bg-light-danger ${className}`}>
          <div className={`card-body pt-0`}>
            <Row>
              <Col>
                <h3>Something went wrong</h3>
                <h6>{error.message}</h6>
                <Button variant={'danger'} onClick={resetErrorBoundary}>
                  Try again
                </Button>
              </Col>
            </Row>
          </div>
        </div>;
      }}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className={`card card-custom ${className}`}>
        {header ?
          <div className="card-header border-0">
            {header}
          </div>
        : null}
        <div className={`card-body ${header ? 'pt-0' : ''}`}>
          {children}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default WidgetCard;
