import { service } from "./api-service";

function getOptions() {
  return service({
    url: "/site/options",
    method: "get",
  });
}

function getSites(params = "") {
  return service({
    url: "/site/list" + params,
    method: "get",
  });
}

function getSiteCSVFile(params = "") {
  return service({
    url: "/site/exportAllSite" + params,
    method: "get",
    responseType: "blob"
  });
}

function getSite(id) {
  return service({
    url: "/site/" + id,
    method: "get",
  });
}

function addSite(data) {
  return service({
    url: "/site",
    method: "post",
    data,
  });
}

function editSite(data) {
  return service({
    url: "/site/" + data.site_id,
    method: "patch",
    data,
  });
}

function submitSitesCsv(data) {
  return service({
    url: "/site?mode=csv",
    method: "post",
    data,
  });
}

const SiteService = {
  getOptions,
  getSites,
  getSite,
  addSite,
  editSite,
  submitSitesCsv,
  getSiteCSVFile,
};

export default SiteService;
