import React, { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import {
  ariaDescribedByIds,
  FormContextType,
  enumOptionsIndexForValue,
  enumOptionsValueForIndex,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps, EnumOptionsType
} from "@rjsf/utils";
import ReactSelect, { components, ControlProps, GroupBase } from "react-select";

function SolidControl({ children, ...props }: ControlProps<any, boolean, GroupBase<any>>) {
  return <components.Control {...props} className={`form-control form-control-solid px-0 py-0`}>
    {children}
  </components.Control>
}

export default function OptionSelectWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
    schema,
    id,
    options,
    required,
    disabled,
    readonly,
    value,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    rawErrors = [],
  }: WidgetProps<T, S, F>) {
  const { enumOptions, enumDisabled, enumHidden, emptyValue: optEmptyValue } = options;

  const emptyValue = multiple ? [] : '';

  function getValue(event: FocusEvent | ChangeEvent | any, multiple?: boolean) {
    if (multiple) {
      return [].slice
        .call(event.target.options as any)
        .filter((o: any) => o.selected)
        .map((o: any) => o.value);
    } else {
      return event.target.value;
    }
  }

  const handleOnChange = useCallback((option : EnumOptionsType<S> | null | readonly EnumOptionsType<S>[]) => {
    let newValue = null;
    if (multiple) {
      newValue = (option as EnumOptionsType<S>[]).map((o: EnumOptionsType<S>) => o.value)
    } else if (option !== null) {
      newValue = (option as EnumOptionsType<S>).value;
    }
    onChange(enumOptionsValueForIndex<S>(newValue, enumOptions, optEmptyValue));
  }, [multiple, onChange, enumOptions, optEmptyValue, enumOptionsValueForIndex]);

  const selectableOptions = useMemo(() => {
    return (enumOptions as any).map(({ value, label }: any, i: number) => {
      return {
        value: String(i),
        label: label
      }
    })
  }, [enumOptions])

  const selectedIndexes = enumOptionsIndexForValue<S>(value, enumOptions, multiple);

  const selectedOption = useMemo(() => {
    if (typeof selectedIndexes === 'undefined') {
      return emptyValue;
    }
    const selected = selectableOptions.filter((option: any) => selectedIndexes.indexOf(option.value) !== -1);
    if (multiple) return selected[0];
    return selected;
  }, [selectedIndexes, selectableOptions, emptyValue, multiple]);

  return (
    <ReactSelect
      //custom
      //as={ReactSelect}
      components={{Control: SolidControl}}
      classNamePrefix='custom-select'
      id={id}
      name={id}
      value={selectedOption}
      required={required}
      isDisabled={disabled || readonly}
      autoFocus={autofocus}
      className={`w-100 ${rawErrors.length > 0 ? 'is-invalid' : ''}`}
      styles={{
        control: (provided, state) => ({
          ...provided,
          ...(rawErrors.length > 0 ? {
            border: "1px solid #F64E60",
            borderRadius: "5px"
          } : {}),
        }),
      }}
      onBlur={
        onBlur &&
        ((event: FocusEvent) => {
          const newValue = getValue(event, multiple);
          onBlur(id, enumOptionsValueForIndex<S>(newValue, enumOptions, optEmptyValue));
        })
      }
      onFocus={
        onFocus &&
        ((event: FocusEvent) => {
          const newValue = getValue(event, multiple);
          onFocus(id, enumOptionsValueForIndex<S>(newValue, enumOptions, optEmptyValue));
        })
      }
      onChange={handleOnChange}
      aria-describedby={ariaDescribedByIds<T>(id)}
      isOptionDisabled={(option: EnumOptionsType<S>) => {
        const disabled: any = Array.isArray(enumDisabled) && (enumDisabled as any).indexOf(option.value) != -1;
        return disabled;
      }}
      filterOption={(option: EnumOptionsType<S>) => {
        return !(Array.isArray(enumHidden) && enumHidden.indexOf(option.value) !== -1);
      }}
      options={selectableOptions}
      placeholder={placeholder}
      isMulti={multiple}
      isClearable={!multiple && (schema.default === optEmptyValue)}
    />);
}
