import React, { useState, useContext, useEffect } from "react";
import { firebaseAuth } from "../provider/auth/AuthProvider";

import { injectIntl } from "react-intl";
import InputMask from 'react-input-mask';
import useIsMounted from "../hooks/useIsMounted";
import { VerifyPhoneDialog } from "./auth/auth-dialog";
import useApiService from "../hooks/useApiService";
import useAxiosResponse from "../hooks/useAxiosResponse";

const SignUp = () => {
  const { enrollMultiFactor, confirmEnrollment, handleSignout} = useContext(firebaseAuth);
  const [verificationId, setVerificationId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const isMounted = useIsMounted();

  const fetchUser = useApiService({url: '/user/self'});
  const [fetchResult] = useAxiosResponse(fetchUser, true);

  useEffect(() => {
    const {response, error, loading, status} = fetchResult;
    if (status === 200 && !loading && response) {
      if (typeof response.phone === 'string') {
        setPhoneNumber(response.phone);
      }
    }
  }, [fetchResult, setPhoneNumber]);

  const verifyPhone = () => {
    return !!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phoneNumber);
  }

  const tryCode = (code) => {
    return confirmEnrollment(verificationId, code);
  }

  const enroll = () => {
    enrollMultiFactor(phoneNumber, enroll).then((verificationId) => {
      if (isMounted) setVerificationId(verificationId);
    });
  }

  const handleClose = () => {
    if (isMounted()) {
      setVerificationId(null);
      setPhoneNumber(null);
    }
    handleSignout('2FA settings changed. Signing out.');
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <VerifyPhoneDialog
        key={verificationId}
        phone={phoneNumber}
        verificationId={verificationId}
        tryCode={tryCode}
        success={handleClose}
        handleClose={handleClose}
      />
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">2FA Required</h3>
        <h5 className="text-muted font-weight-bold">
          {" "}
          Please enter your phone number now.{" "}
        </h5>
      </div>

      <form
        onSubmit={e => e.preventDefault()}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <InputMask
            formatChars={{
              0: '[0-9]',
              a: '[A-Za-z]',
              '*': '[A-Za-z0-9]',
            }}
            mask={'000-000-0000'}
            value={phoneNumber || ''}
            onChange={(e) => setPhoneNumber(e.target.value)}
          >
            {(inputProps) => <input
              {...inputProps}
              placeholder="000-000-0000"
              type="tel"
              className={`form-control form-control-solid h-auto py-5 px-6 ${verifyPhone() ? "is-valid" : "is-invalid"}`}
              name="phone"

            />}
          </InputMask>
        </div>
        <p className="text-muted font-weight-bold">
          {" "}
          A 6-digit code will be sent to your phone everytime you log in.{" "}
          Phone SMS messaging rates apply.{" "}
        </p>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            onClick={() => enroll()}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            disabled={!verifyPhone()}
          >
            <span>Submit</span>
          </button>
          <button
            onClick={() => handleSignout()}
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default injectIntl(SignUp);
