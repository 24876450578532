import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { authMethods, userMultiFactor } from "../../firebase/authmethods";
import MultiFactorDialog  from "../../components/auth/auth-dialog";
import { configContext } from "../config/ConfigProvider";
import { LayoutSplashScreen } from "../../_metronic/layout";
import useLogger from '../../hooks/useLogger';

export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const config = useContext(configContext);
  const [errors, setErrors] = useState([]);
  const [token, _setToken] = useState(null);
  const captchaRef = useRef(null);
  const verifierRef = useRef(null);
  const logger = useLogger();

  const setToken = useCallback((token) => {
    return _setToken((prev) => {
      const localToken = localStorage.getItem("token");
      if (localToken && localToken === token) {
        return prev;
      }
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
      return token;
    });
  }, [_setToken]);

  useEffect(() => {
    authMethods.init(config.firebase);
    verifierRef.current = authMethods.setRecaptcha(captchaRef.current);
    verifierRef.current.render();
    return () => {
      verifierRef.current.clear();
    }
  }, [config.firebase]);

  // false means the auth status is unknown
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => {
    const unlisten = authMethods.onAuthStateChanged(
      nextAuthUser => {
        if (nextAuthUser) {
          //set refreshed token to localStorage
          setToken(nextAuthUser.stsTokenManager.accessToken);
          setAuthUser(nextAuthUser)
        } else {
          setAuthUser(null);
        }
      },
    );
    return () => {
      unlisten();
    }
  });

  const [enrolledFactors, setEnrolledFactors] = useState(null);
  const refreshEnrolledFactors = useCallback(() => {
    if (authUser === false) {
      setTimeout(() => {
        refreshEnrolledFactors();
      }, 100);
    } else {
      const factors = (userMultiFactor() || {}).enrolledFactors;
      setEnrolledFactors(factors);
    }
  }, [setEnrolledFactors, authUser]);
  useEffect(() => {
    refreshEnrolledFactors();
  });

  const handleSignin = (email, password) => {
    authMethods.signin(email, password, setErrors, setToken).then((res) => {
      return logger(res, 'AuthLogin').then(() => {
        return res;
      });
    })
  };

  const handleForgotPassword = (email) => {
    authMethods.forgotpassword(email, setErrors, setToken);
  };

  const handleSignout = (errorMessage) => {
    logger(errorMessage, 'AuthLogout').finally(() => {
      authMethods.signout(setErrors, setToken, errorMessage);

    })
  };

  const enrollMultiFactor = (phoneNumber, retry) => {
    return authMethods.enrollUserPhone2FA(phoneNumber).catch((err) => {
      err.retry = retry;
      setErrors((prev) => [...prev, err]);
    });
  }

  const confirmEnrollment = (verificationId, code) => {
    return authMethods.confirmEnrollment(verificationId, code);
  }

  const unenrollMultiFactor = (enrolledFactor, retry) => {
    return authMethods.unenrollUserPhone2FA(enrolledFactor).catch((err) => {
      err.retry = retry;
      setErrors((prev) => [...prev, err]);
    });
  }

  const verifyPasswordReset = (actionCode) => {
    return authMethods.verifyPasswordReset(actionCode);
  }

  const confirmPasswordReset = (actionCode, newPassword) => {
    return authMethods.confirmPasswordReset(actionCode, newPassword);
  }

  const checkActionCode = (actionCode) => {
    return authMethods.checkActionCode(actionCode);
  }

  const applyActionCode = (actionCode) => {
    return authMethods.applyActionCode(actionCode);
  }

  const hasEnoughFactors = useCallback(() => {
    if (authUser === false) return true;
    const {require} = config.multiFactor;
    const factors = enrolledFactors || [{}];
    return !require || factors.length > 0
  }, [enrolledFactors, authUser, config.multiFactor]);

  const getToken = (refresh = false) => {
    return authMethods.getToken(refresh).then((idToken) => {
      setToken(idToken);
      return idToken;
    }).catch(() => {
      setToken(null);
      return null;
    });
  }

  return (
    <firebaseAuth.Provider
      value={{
        authUser,
        handleSignin,
        handleForgotPassword,
        token,
        errors,
        handleSignout,
        enrollMultiFactor,
        confirmEnrollment,
        unenrollMultiFactor,
        verifyPasswordReset,
        confirmPasswordReset,
        checkActionCode,
        applyActionCode,
        enrolledFactors,
        refreshEnrolledFactors,
        hasEnoughFactors,
        getToken
      }}
    >
      <div ref={captchaRef}/>
      <LayoutSplashScreen visible={authUser === false}/>
      <MultiFactorDialog
        setErrors={setErrors}
        setToken={setToken}
        multiFactorResolver={authMethods.multiFactorResolver}
        signInWithPhone2FA={authMethods.signInWithPhone2FA}
        confirmSignIn={authMethods.confirmSignIn}
        reauthenticate={(password) => authMethods.reauthenticate(password, setErrors, setToken)}
      />
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
