import React, { useEffect, useRef, useState } from "react";
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import useIsMounted from "../../../hooks/useIsMounted";
import DropZone from "./DropZone";
import CloseIcon from '@material-ui/icons/Close';
import { ProgressBar } from "react-bootstrap";
import bytes from 'bytes';

const useStyles = makeStyles(() => ({
  hiddenFileInput: {
    margin: 0,
    overflow: 'hidden',
    opacity: 0,
    position: 'absolute'
  }
}));

export default function File({
  field, // { name, value, onChange, onBlur }
  form,
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "file",
  shouldValidate = true,
  ...props
}) {
  const {
    touched,
    errors,
    setFieldValue,
    submitCount,
    isSubmitting
  } = form;  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc. https://formik.org/docs/api/formik
  const inputRef = useRef();
  const isMounted = useIsMounted();
  const classes = useStyles();
  const inputId = 'custom-file';
  const fileName = (field.value || {}).name || '';
  // when isSubmitting is true,
  // handeSubmit can show to upload progress with actions.setFieldValue(fileKey, e, false)
  // where e is progressEvent from axios
  const progress = (field.value || {}).loaded;
  const totalSize = (field.value || {}).total;

  const bytesOpt = {
    unitSeparator: ' ',
  }
  const [valueLabel, setValueLabel] = useState('');
  useEffect(() => {
    let value = '';
    if (isSubmitting) {
      if (progress === totalSize) {
        value = 'Waiting for response.';
      } else if (progress === 0) {
        value = 'Upload pending.';
      } else {
        value = `${bytes.format(progress, bytesOpt)}/${bytes.format(totalSize, bytesOpt)}`;
      }
    } else {
      value = fileName || 'No file selected';
    }
    setValueLabel(value);
  }, [isSubmitting, setValueLabel, field.value, field, fileName, progress, totalSize, bytesOpt]);

  const isInvalid = () => (submitCount || touched[field.name]) && errors[field.name];

  const getFieldCSSClasses = () => {
    const touch = touched[field.name]
    const error = errors[field.name]
    const classes = ["form-control"];
    if (isInvalid()) {
      classes.push("is-invalid");
    }

    if (touch && !error) {
      classes.push("is-valid");
    }

    return classes.join(" ");
  };

  return (
    <>
      <DropZone onDrop={(files) => {
        if (isMounted()) {
          setFieldValue(field.name, files[0])
        }
      }}>
        {label && <label>{label}</label>}
        <div className={`input-group ${isInvalid ? 'is-invalid' : ''}`}>
          <div className="input-group-prepend">
            <button
              style={{
                zIndex: 'unset'
              }}
              className={`btn btn-outline-${isInvalid() ? 'danger' : 'secondary'}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (inputRef.current && typeof inputRef.current.click === "function") {
                  inputRef.current.click();
                }
              }}
            >Choose file</button>
          </div>
          <input
            ref={inputRef}
            id={inputId}
            type={type}
            className={classes.hiddenFileInput}
            name={field.name}
            onBlur={field.onBlur}
            {...props}
            onChange={(event) => {
              event.preventDefault()
              setFieldValue(field.name, event.currentTarget.files[0])
            }}
          />
          <label
            htmlFor={inputId}
            className={getFieldCSSClasses()}
          >
            {valueLabel}
            {isSubmitting && progress && totalSize ? <ProgressBar
              striped={progress === totalSize}
              animated={progress === totalSize}
              variant="success"
              now={(progress/totalSize) * 100}
              style={{height: '0.5rem'}} /> : null}
          </label>
          {fileName ? <div className="input-group-append">
            <button
              style={{
                zIndex: 'unset'
              }}
              className={`btn btn-outline-${isInvalid() ? 'danger' : 'secondary'}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setFieldValue(field.name, '');
              }}
            ><CloseIcon/></button>
          </div> : null}
        </div>
      </DropZone>
      {withFeedbackLabel && shouldValidate && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
