import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useStateValue } from "../store/StateProvider";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { useQueryStringFromProps } from "../utils/queryString";

const defaultQueryParams = {
  orderType: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 25,
  searchOptions: {}
}

export default function useQueryParamStore(name, actionType, urlVisible) {
  const history = useHistory();
  const {search} = useLocation();
  const [{ queryParams }, dispatch] = useStateValue();
  const {orderType, orderBy, page, rowsPerPage, searchOptions} = useMemo(() => {
    return (queryParams[name + 'QueryParams'] || defaultQueryParams);
  }, [queryParams]);
  const memoOptions = useDeepCompareMemoize(searchOptions);
  const queryString = useQueryStringFromProps({orderType, orderBy, page, rowsPerPage, searchOptions});
  const setOrderType = useCallback((value) => {
    dispatch({
      type: actionType,
      queryParams: { orderType: value, orderBy, page, rowsPerPage, searchOptions: memoOptions }
    });
  }, [orderBy, page, rowsPerPage, memoOptions, actionType, dispatch]);
  const setOrderBy = useCallback((value) => {
    dispatch({
      type: actionType,
      queryParams: { orderType: defaultQueryParams.orderType, orderBy: value, page, rowsPerPage, searchOptions: memoOptions }
    });
  }, [orderType, page, rowsPerPage, memoOptions, actionType, dispatch]);
  const setPage = useCallback((value) => {
    dispatch({
      type: actionType,
      queryParams: { orderType, orderBy, page: value, rowsPerPage, searchOptions: memoOptions }
    });
  }, [orderType, orderBy, rowsPerPage, memoOptions, actionType, dispatch]);
  const setRowsPerPage = useCallback((value) => {
    dispatch({
      type: actionType,
      queryParams: { orderType, orderBy, page: 0, rowsPerPage: value, searchOptions: memoOptions }
    });
  }, [orderType, orderBy, page, memoOptions, actionType, dispatch]);
  const setSearchOptions = useCallback((value) => {
    const sameValue = _.isEqual(value, memoOptions)
    dispatch({
      type: actionType,
      queryParams: { orderType, orderBy, page: sameValue ? page : 0, rowsPerPage, searchOptions: value }
    });
  }, [orderType, orderBy, page, rowsPerPage, actionType, dispatch, memoOptions]);
  const setSearchOption = useCallback((prop, value) => {
    let options = memoOptions;
    const {[prop]: prevValue, ...rest} = memoOptions;
    if (typeof value !== 'undefined') {
      if (`${prevValue}` !== `${value}`) {
        options = {
          ...rest,
          [prop]: value
        };
      }
    } else {
      options = {...rest};
    }

    setSearchOptions(options);
  }, [setSearchOptions, memoOptions]);

  const reset = useCallback(() => {

  });

  // update state with new url search if it is different
  const consumed = useRef(false);
  const getSearch = useCallback((options) => {
    const urlSearchParams = new URLSearchParams(search);
    const fields = Array.isArray(urlVisible) ? urlVisible : [urlVisible];
    if (!consumed.current) {
      consumed.current = true;
      for (const field of fields) {
        const value = urlSearchParams.get(field);
        const prev = options[field];
        if (value && `${value}` !== `${prev}`) {
          setSearchOption(field,  value);
        }
      }
    }
  }, [urlVisible, search, setSearchOption, consumed]);

  const setSearch = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    const fields = Array.isArray(urlVisible) ? urlVisible : [urlVisible];
    //console.log('SET SEARCH', options);
    let changed = false;
    for (const field of fields) {
      const value = memoOptions[field];
      //console.log(value, urlSearchParams.toString())
      if (!value && urlSearchParams.has(field)) {
        urlSearchParams.delete(field);
        changed = true;
      } else if (value && (!urlSearchParams.has(field) || `${urlSearchParams.get(field)}` !== `${value}`)) {
        urlSearchParams.set(field, value);
        changed = true;
      }
    }
    if (changed) {
      const searchString = urlSearchParams.toString();
      let path = history.location.pathname;
      if (searchString !== '') {
        path += '?' + searchString;
      }
      //console.log(path)
      history.replace(path);
    }
  }, [ history, search, urlVisible, memoOptions]);

  useEffect(() => {
    getSearch(memoOptions);
  }, [getSearch, memoOptions]);

  useEffect(() => {
    setSearch();
  }, [setSearch]);

  return {queryString, orderType, setOrderType, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage, searchOptions, setSearchOption, setSearchOptions, reset};
}
