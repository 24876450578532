import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import AsyncSelect from "react-select/async";
import SolidControl from "../common/form/react-select/SolidControl";
export default function SiteMultiSelect (props) {
  const {name, value, formik} = props;
  const {setFieldValue} = formik;

  const [site, setSite] = useState(null);

  const racFilter = useMemo(() => {
    return ((formik || {}).values || {}).racFilter;
  }, [formik.values]);

  const sites = useAsyncSelect({
    url: '/auth/sites',
    key: 'site_id',
    searchTerm: 'name',
    filters: {
      exclude: value,
      rac_id: racFilter
    }
  });

  const filterOption = useCallback((candidate) => {
    if (!racFilter) return true;
    const rac_ids = Array.isArray(racFilter) ? racFilter : [racFilter];
    if (rac_ids.length === 0) return true;
    return rac_ids.includes(candidate.data.rac);
  }, [racFilter]);

  const handleChange = useCallback((site) => {
    setSite(site);
    if (!Array.isArray(site)) site = [site];
    const selectedSiteIds = (site || []).filter(site => !!site).map(site => site.site_id);
    setFieldValue(name, selectedSiteIds);
  }, [setSite, name, setFieldValue]);

  return <AsyncSelect
    name={name}
    placeholder={'Choose a site.'}
    //isMulti
    //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    loadOptions={sites.loadOptions}
    getOptionLabel={option => option.common_name || option.legal_name}
    getOptionValue={option => option.site_id}
    loadValue={sites.loadValue}
    defaultOptions={sites.options}
    onChange={handleChange}
    isClearable={true}
    value={site}
    menuPlacement={'auto'}
    components={{Control: SolidControl}}
    filterOption={filterOption}
  />
}
