import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getUiOptions, UI_GLOBAL_OPTIONS_KEY } from '@rjsf/utils';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FormNavPage from './FormNavPage';
import _ from 'lodash';

const justKeys = (obj, keys) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (keys.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

const uniqueErrorSet = (errors) => {
  return errors.reduce((acc, cur) => {
    const multiPath = new Set(['anyOf', 'oneOf', 'allOf']);
    const {schemaPath} = cur;
    if (!schemaPath) return acc;
    let pathKey = schemaPath;
    const path = schemaPath.split('/').filter((v) => v !== '');
    const multiSchemaIndex = path.findIndex((v) => multiPath.has(v));
    if (multiSchemaIndex !== -1) {
      pathKey = path.slice(0, multiSchemaIndex).join('/');
    }
    acc.add(pathKey);
    return acc;
  }, new Set());
}

const FormNavTab = function (props) {
  const {
    tab,
    children,
    formData,
    handleSetFormData,
    extraErrors,
    liveValidate = true,
    debounceValidate = true,
  } = props;

  return <FormNavPage
    jsonSchema={tab.schema}
    uiSchema={tab.uiSchema}
    errorSchema={justKeys(extraErrors || {}, tab.fields)}
    formData={formData}
    setFormData={(newFormData) => {
      handleSetFormData(tab.value, newFormData);
    }}
    liveValidate={liveValidate}
    debounceValidate={debounceValidate}
  >{children}</FormNavPage>
}

const FormNavTabs = function (props) {
  const {
    basePath,
    defaultPage,
    navClassName = 'nav-pills',
    children,
    schema,
    uiSchema,
    extraErrors,
    formData,
    setFormData,
    setErrors,
  } = props;

  const base = basePath.split('/').filter((v) => v !== '').join('/');
  const globalOptions = (uiSchema || {})[UI_GLOBAL_OPTIONS_KEY];

  const tabs = useMemo(() => {
    if (typeof uiSchema !== 'object') return [];
    if (typeof schema !== 'object') return [];
    const { order } = getUiOptions(uiSchema);
    return (order || []).reduce((acc, objName) => {
      const jsonSchema = ((schema.properties || {})[objName] || {});
      if (Object.keys(jsonSchema.properties || {}).length === 0) return acc;
      const ui = uiSchema[objName] || {};
      const { nav } = getUiOptions(ui);
      const navName = nav || (globalOptions || {}).nav || 'Uncategorized';
      let existing = acc.find((tab) => {
        return tab.label === navName;
      });
      if (!existing) {
        const value = navName.toLowerCase().replace(/ /g, '');
        let path = `/${base}/${value}`;
        existing = {
          label: navName,
          value,
          path,
          fields : [],
          schema: {
            type: 'object',
            properties: {},
          },
          uiSchema: {
            'ui:order': [],
          },
        };
        // include allOf that have the tab in the "then" property
        if (schema.allOf) {
          const filteredAllOf = schema.allOf.filter((obj) => {
            const then = obj['then'];
            if (!then) return false;
            return then.properties && then.properties[objName];
          })
          if (filteredAllOf.length > 0) {
            existing.schema.allOf = filteredAllOf;
          }
        }
        if (globalOptions) {
          existing.uiSchema[UI_GLOBAL_OPTIONS_KEY] = globalOptions;
        }
        acc.push(existing);
      }
      Object.assign(existing.schema.properties, {[objName]: jsonSchema});
      Object.assign(existing.uiSchema, {[objName]: ui});
      existing.uiSchema['ui:order'].push(objName);
      existing.fields.push(objName);
      return acc;
    }, []);
  }, [schema, uiSchema, base]);

  const [tabErrors, setTabErrors] = useState({});
  const handleSetFormData = useCallback((tabName, data) => {
    //console.log(tabName, data)
    if (tabName) {
      const {formData, errors} = data;
      if (formData) {
        setFormData(formData);
      }
      if (errors) {
        setTabErrors((prev) => {
          const prevData = prev[tabName] || {};
          const prevErrors = prevData.errors;
          if (!formData && Array.isArray(prevErrors) && errors.length === prevErrors.length) {
            if (_.isEqual(errors, prevErrors)) {
              return prev;
            }
          }
          const next = Object.assign({}, prev);
          next[tabName] = {
            errors
          }
          if (errors.length > 0) {
            const newUniqueSet = uniqueErrorSet(errors);
            const newSetSize = newUniqueSet.size;
            if (newSetSize) {
              next[tabName].uniqueSet = newUniqueSet;
              next[tabName].errorSize = newSetSize;
            }
          }
          //console.log('handle set form data', {prev, next, tabName, data})
          return next;
        });
      }
    }
  }, [setTabErrors]);

  const [defaultTab, setDefaultTab] = useState(null);
  useEffect(() => {
    if (Array.isArray(tabs) && tabs.length > 0 && tabErrors) {
      setDefaultTab((prev) => {
        if (prev) return prev;
        const first = tabs[0];
        if (typeof defaultPage === 'string') {
          return tabs.find((tab) => tab.value === defaultPage) || first;
        }
        if (typeof defaultPage === 'number') {
          return tabs[defaultPage] || first;
        }
        for (const tab of tabs) {
          const { errors } = tabErrors[tab.value] || {};
          if (!Array.isArray(errors)) {
            return null;
          }
          if (errors.length > 0) {
            return tab;
          }
        }
        return first;
      });
    }
  }, [tabs, defaultPage, tabErrors]);

  const match = useRouteMatch(`/${base}/:tab`);
  const activeTabName = useMemo(() => {
    const tabName = match ? match.params.tab : null;
    if (Array.isArray(tabs) && tabs.length > 0) {
      if (tabName) {
        return tabName;
      }
      if (defaultTab) {
        return defaultTab.value;
      }
    }
    return null;
  }, [tabs, match, defaultTab]);

  //console.log('render formData', formData)

  useEffect(() => {
    const initial = [];
    const upErrors = Object.values(tabErrors).reduce((acc, { errors }) => {
      if (errors) {
        if (acc.errors) {
          acc.errors = [...acc.errors, ...errors];
        } else {
          acc.errors = [...errors];
        }
      }
      return acc;
    }, initial);
    if (Array.isArray(upErrors)) {
      setErrors(upErrors);
    }
  }, [tabErrors, setErrors, tabs]);

  return <><Row>
    <Col>
      <ul className={`nav ${navClassName} mb-10 mr-auto`}>
        {tabs.map((tab) => {
          const { errors, uniqueSet, errorSize } = tabErrors[tab.value] || {};
          //console.log(tab, errors, uniqueSet)
          const isActive = tab.value === activeTabName;
          let className = "btn";
          let variant = 'success';
          if (!Array.isArray(errors)) {
            variant = 'light';
          } else if (errors.length > 0) {
            variant = 'danger';
          }
          if (isActive) {
            className = `${className} btn-${variant}`;
          } else {
            className = `${className} btn-outline-${variant}`;
          }
          if (tab.disabled) className += ' disabled';
          const to = `/${base}/${tab.value}`;
          return <li key={tab.label} className="nav-item">
            <NavLink
              isActive={() => isActive}
              activeClassName="btn-shadow font-weight-boldest active"
              to={to}
              exact={true}
              className={className}>
              {tab.label}
              {uniqueSet && errorSize > 0 && <span className="badge badge-danger ml-2">{errorSize}</span>}
            </NavLink>
          </li>
        })}
      </ul>
    </Col>
    {/*children*/}
  </Row>
  <Row>
    <Col>
      <Switch>
        {tabs.map((tab) => {
          const { errors } = tabErrors[tab.value] || {};
          if (Array.isArray(errors)) {
            return <Route key={tab.label} path={tab.path}>
              <FormNavTab
                tab={tab}
                formData={formData}
                handleSetFormData={handleSetFormData}
                extraErrors={extraErrors}
                // disable debounce on the last error, or else error message won't update correctly
                // has to do with rawErrors in FieldTemplate.tsx
                debounceValidate={errors.length > 1}
              >
                {children}
              </FormNavTab>
            </Route>
          }
          return null
        })}
        {defaultTab
          ? <Route>
            <FormNavTab
              tab={defaultTab}
              formData={formData}
              handleSetFormData={handleSetFormData}
              extraErrors={extraErrors}
              debounceValidate={!defaultTab
                || !tabErrors
                || !tabErrors[defaultTab.value]
                || tabErrors[defaultTab.value].length < 2}
            >
              {children}
            </FormNavTab>
        </Route> : null }
      </Switch>
      {tabs.map((tab) => {
        const { errors } = tabErrors[tab.value] || {};
        if (!Array.isArray(errors)) {
          return <div key={tab.value} style={{visibility: 'hidden'}}>
            <FormNavTab
              tab={tab}
              formData={formData}
              handleSetFormData={handleSetFormData}
              extraErrors={extraErrors}
            />
          </div>
        }
        return null
      })}
    </Col>
  </Row>
  </>
};

export default FormNavTabs;
