import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import useQuery from "../../hooks/useQuery";
import ResetPassword from "../../components/auth/EmailActions/ResetPassword";
import RecoverEmail from "../../components/auth/EmailActions/RecoverEmail";
import VerifyEmail from "../../components/auth/EmailActions/VerifyEmail";

// https://firebase.google.com/docs/auth/custom-email-handler
const FirebaseEmailAction = () => {
  const query = useQuery();
  const [params, setParams] = useState({});
  useEffect(() => {
    const mode = query.get('mode');
    const actionCode = query.get('oobCode');
    const continueUrl = query.get('continueUrl');
    const lang = query.get('lang') || 'en';
    setParams({mode, actionCode, continueUrl, lang});
  }, [query, setParams]);

  return <AuthLayout>
    <>{(() => {
      switch (params.mode) {
      case 'resetPassword':
        return <ResetPassword actionCode={params.actionCode} continueUrl={params.continueUrl} />;
      case 'recoverEmail':
        return <RecoverEmail actionCode={params.actionCode} />;
      case 'verifyEmail':
        return <VerifyEmail actionCode={params.actionCode} continueUrl={params.continueUrl} />;
      default:
        return <>Invalid Link</>;
      }
    })()}</>
  </AuthLayout>
};

export default FirebaseEmailAction;
