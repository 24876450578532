import React, { useEffect, useState } from "react";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SearchableSelect from "../common/form/SearchableSelect";
import Select from "../common/form/Select";
import { Field, useFormikContext } from "formik";
import { Checkbox } from "../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../../services/user";

const useStyles = makeStyles((theme) => ({
  removeButton: {
    position: "absolute",
    cursor: "pointer",
    color: "red",
    right: "40px",
    top: "30px",
    zIndex: "1",

    [theme.breakpoints.down('sm')]: {
      right: "18px"
    },
  },
  userRoleItem: {
    position: "relative",
    marginTop: "10px",
  },
}));

const userRoleSelector = (index, roleType) => `userRoles[${index}].${roleType}`;
const userRoleTitleSelector = (index) => `userRoles[${index}].title`;

const UserRoleInput = (props) => {
  const {
    index,
    name,
    userRole,
    remove,
    sites,
    titles,
    loadSites,
    loadSite,
    selectedSites
  } = props;
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    if (userRole.site_id) {
      UserService.getOptionRoles(`?site_id=${userRole.site_id}`)
        .then((res) => {
          const {roles} = res.data;
          for (const role of roles) {
            const value = userRole[role.value];
            if (!value) {
              setFieldValue(
                userRoleSelector(index, role.value),
                value ?? false
              );
            }
          }
          setRoles(roles);
        }).catch(e => {
        console.log(e);
      });
    } else {
      setRoles([]);
    }
  }, [setRoles, userRole.site_id]);

  const filterOption = (candidate) => {
    return !selectedSites.find((site_id) => `${site_id}` === `${candidate.value}`);
  }

  return <div
    key={index}
    className={`col-lg-12 mt-6 row ${classes.userRoleItem}`}
  >
    <span
      className={classes.removeButton}
      onClick={() => {
        remove(index);
      }}
    >
      <RemoveCircle />
    </span>

    <div className="col-sm-3 mt-6">
      <Field
        component={SearchableSelect}
        name={name}
        label="Site"
        defaultValue={userRole.site_id}
        defaultOptions={sites}
        loadOptions={loadSites}
        loadValue={loadSite}
        filterOption={filterOption}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
      />
      <Field
        component={Select}
        name={userRoleTitleSelector(index)}
        label="Title"
        value={userRole.title}
        options={titles}
        useOptionField={true}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.user_title_id}
      />
    </div>
    <div className={"col-sm-9 mt-6 custom-checkbox"}>
      <label>
        Permissions
      </label>
      <br />
      <div className="row">{roles.map((role) => (
        <div key={role.value} className={`col-md-4 col-sm-12`}>
          <Field
            name={userRoleSelector(index, role.value)}
            component={Checkbox}
            onChange={(input) => {
              setFieldValue(
                userRoleSelector(index, role.value),
                input.target.checked
              );
            }}
            isSelected={userRole[role.value]}
          />
          <label>{role.label}</label>
        </div>
      ))}
      </div>
    </div>
  </div>
}

export default UserRoleInput;
