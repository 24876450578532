import React from "react";
import WidgetCard from "./WidgetCard";
import { Col, Row, Nav } from "react-bootstrap";
import { NavLink, Route, Switch } from 'react-router-dom';

const NavRouteWidget = ({
  items = [],
  basePath= '',
  cardClassName = "card-stretch gutter-b",
  navClassName = "nav-success nav-pills",
  children
}) => {

  const base = basePath.split('/').filter((v) => v !== '').join('/');

  return <>
    <WidgetCard className={cardClassName}>
      <Row>
        <Col>
          <ul className={`nav ${navClassName} mb-n3 mr-auto`}>
            {items.map((item, i) => {
              let className = "nav-link";
              //if (i === active) className += ' active';
              if (item.disabled) className += ' disabled';
              return <li key={item.label} className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={`/${base}/${item.value}`}
                  exact={true}
                  className={className}>
                  {item.label}
                </NavLink>
              </li>
            })}
          </ul>
        </Col>
        {children}
      </Row>
    </WidgetCard>
    <Switch>
      {items.sort((a, b) => {
        // sort routes so that empty values go to the end
        if (!a.value) return 1;
        if (!b.value) return -1;
        return 0;
      }).map((item) => {
        return <Route key={item.label} path={`/${base}/${item.value}`}>
          {item.content}
        </Route>
      })}
    </Switch>
  </>
}

export default NavRouteWidget;
