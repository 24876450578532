import date from "date-and-time";

export const isNullOrUndefined = (object) => object === null || object === undefined;

export const formatNumber = num => (typeof num === "number") && num && num.toLocaleString();

export const getRef = (obj, path) => {
  return (
      path.split('.').reduce(function(object, name) {
          if (!object) return null;

          const match = /^(\w*)\[(\d*)\]$/.exec(name);
          if (match) return object[match[1]] ? object[match[1]][match[2]] : null;

          return object[name];
      }, obj) ?? null
  );
}

export const getRefs = (obj, modelPath) => {
  var itemList = [];
  var refItem = null;
  var splitModelPath = modelPath.split(/\[0\]\.(.+)/).filter(item => item);

  if (splitModelPath.length === 1) {
      refItem = getRef(obj, modelPath);

      if (!isNullOrUndefined(refItem)) {
          itemList.push(refItem);
      }
  } else {
      var sourcesArray = getRef(obj, splitModelPath[0]);

      if (!isNullOrUndefined(sourcesArray)) {
          for (var i = 0; i < sourcesArray.length; i++) {
              refItem = getRef(sourcesArray[i], splitModelPath[1]);              
              itemList.push(refItem);
          }
      }
  }

  return itemList;
};

export function bodyToFormik (obj) {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      newObj[key] = "";
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
}

export function convertServerTimeString(dateString, utc) {
  if (!dateString) return null;
  if (!isNaN(dateString)) {
    return new Date(+dateString);// pass through number
  }
  if (typeof dateString === 'string') {
    let d = date.parse(dateString, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', !!utc);
    if (!d || isNaN(+d)) d = date.parse(dateString, 'YYYY-MM-DD HH:mm:ss.SSS', !!utc);
    return d;
  }
  return null;
}

export const appUrl = () => window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
