import React, { useMemo } from "react";
import Switch from "@material-ui/core/Switch";
import DateCell from "../common/datatable/cell/DateCell";
import DataTable from "../common/datatable/DataTable";

function SwitchCell ({id, row, handleChangeArchive}) {
  const value = row[id];
  return <Switch
    checked={!value}
    onChange={(event) =>
      handleChangeArchive(event, row.user_id)
    }
    color="secondary"
    name={`archive-${row.user_id}`}
    inputProps={{ "aria-label": "primary checkbox" }}
  />
}

const UserDataTable = (props) => {
  const {
    rows,
    queryParams,
    totalRows,
    errorMessage,
    loading,
    retry,
    handleClickRow,
    changeArchive,
  } = props;

  const headCols = useMemo(() => {
    return  [
      {
        id: "first_name",
        sortable: true,
        searchable: true,
        label: "First Name",
      },
      {
        id: "last_name",
        sortable: true,
        searchable: true,
        label: "Last Name",
      },
      {
        id: "email",
        sortable: true,
        searchable: true,
        label: "Email"
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: false,
        label: "Phone"
      },
      {
        id: "created",
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: "Created",
        renderCell: (props) => <DateCell format={'MM/DD/YYYY'} {...props}/>
      },
      {
        id: "last_login",
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: "Last Login",
        renderCell: (props) => <DateCell format={'MM/DD/YYYY'} {...props}/>
      },
      {
        id: "archive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        onClick: () => {},
        renderCell: (props) => <SwitchCell {...props} handleChangeArchive={handleChangeArchive}/>
      },
    ];
  }, [handleChangeArchive]);

  function handleChangeArchive(event, id) {
    changeArchive(id);
  }

  const rowProps = (row) => {
    const props = {};
    if (row.hasRoles === false) {
      props.handleClick = undefined;
      props.className = 'bg-light-secondary text-muted'
    } else if (row.roleCount === 0) {
      props.className = 'table-primary'
    }
    return props;
  }

  return <DataTable
    headCols={headCols}
    idName={'user_id'}
    queryParams={queryParams}
    rows={rows}
    totalRows={totalRows}
    errorMessage={errorMessage}
    loading={loading}
    retry={retry}
    handleClickRow={handleClickRow}
    rowProps={rowProps}
  />
}

export default UserDataTable;
