import React, { createContext, useContext, useReducer } from "react";

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

export const initialState = {
  auth: {
    user: {
      roles: {},
      noRoles: false
    }
  },
  defaultSearchValues: {
    participantSearchForm: {
      consent_form: true
    }
  },
  multiForm: {
    progress: [],
    config: [],
  },
  notification: null,
  participants: [],
  possibleFilterOptions: {
    testing_proctors: [],
    testing_sites: [],
    potential_sites: []
  },
  queryParams: {
    userQueryParams: {
      orderType: 'asc',
      orderBy: 'first_name',
      page: 0,
      rowsPerPage: 25,
      searchOptions: {}
    },
    siteQueryParams: {
      orderType: 'asc',
      orderBy: 'site_id',
      page: 0,
      rowsPerPage: 25,
      searchOptions: {}
    },
    dashboardGroupQueryParams: {
      orderType: 'asc',
      orderBy: 'dashboard_group_id',
      page: 0,
      rowsPerPage: 25,
      searchOptions: {}
    },
    uploadQueryParams: {
      orderBy: 'created_at',
      orderType: 'desc',
      page: 0,
      rowsPerPage: 25,
    }
  },
  users: [],
  isLoading: false,
};
