import React, { useEffect, useMemo, useState, useCallback } from "react";
import useApiService from "./useApiService";
import { editQueryString, useQueryStringFromProps } from "../utils/queryString";
import useDebounce from "./useDebounce";
import useIsMounted from "./useIsMounted";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

/**
 *
 * @param url API route that accepts search params and responds with {results, total_rows}
 * @param key Default OrderBy prop
 * @param searchTerm Prop used in searchOptions provided to API
 * @param rowsPerPage How many options are displayed
 * @param debounceDelay
 * @param filters Additional searchOptions that is provided to API
 * @param additionalOptions Any rows that will be added by default and will not be found in the API
 * @return {{loadValue: ((function(*): (Promise<unknown>))|*), takeUndefinedValue: (function(): string), loadOptionsDebounced: (*|DebouncedFuncLeading<function(...[*]): void>), options: *[], updateRows: ((function(*): void)|*), loadOptions: ((function(*, *): void)|*)}}
 */
const useAsyncSelect = ({
  url,
  key,
  searchTerm,
  debounceDelay = 500,
  rowsPerPage = 25,
  filters = {},
  additionalOptions = [],
}) => {
  const isMounted = useIsMounted();
  /**
   * Combines to list of rows
   * Updates (replaces) rows with matching [key] prop.
   * Adds new values to the end.
   *
   * usage: oldRows.reduce( rowsReduce, newRows);
   */
  const rowsReduce = useCallback((accumulator, current) => {
    let found = false;
    if (!current[key]) return accumulator; // cannot use rows without keys, so skip it
    accumulator = accumulator.map((previous) => {
      if (`${previous[key]}` === `${current[key]}`) { // if there is an update to a previous row, replace that row with the new one
        found = true;
        return current;
      } else {
        return previous;
      }
    });
    if (found) return accumulator; // current row updated existing row, do not add duplicate
    return accumulator.concat(current);
  }, [key]);

  /**
   * List of rows that have been downloaded and cached.
   *
   */
  const [rows, setRows] = useState([]);
  const [lastRows, setLastRows] = useState([]);
  /**
   * Instead of replacing all the rows, keep existing values and update them if necessary.
   *
   */
  const updateRows = useCallback((newRows) => {
    if (!Array.isArray(newRows)) newRows = [newRows];
    setLastRows(newRows);
    setRows((prevState) => {
      return newRows.reduce(rowsReduce, prevState);
    });
  }, [setRows, setLastRows]);

  /**
   * Query string build from search params
   */
  const queryString = useQueryStringFromProps({
    orderBy: key,
    rowsPerPage,
    searchOptions: filters
  });

  /**
   * Adds additional options that won't come from API and includes them in the select dropdown
   */
  const additionalOptionsMemoized = useDeepCompareMemoize(additionalOptions);
  const concatOptions = useCallback((rows, total_rows) => {
    // Adds message indicating how many rows exist but are not cached
    const messages = [];
    if (total_rows > rows.length) {
      messages.push({[searchTerm]: `And ${total_rows - rows.length} more.`, isDisabled: true, [key]: 'disabled_more_info'})
    }
    return additionalOptionsMemoized.concat(rows.concat(messages));
  }, [searchTerm, key, additionalOptionsMemoized]);

  const [totalRows, setTotalRows] = useState(0);
  const fetchRows = useApiService({url, queryString});

  /**
   * If a value cannot be found in the api, provide the option of manually adding it
   */
  const [undefinedValue, setUndefinedValue] = useState('');
  const takeUndefinedValue = useCallback(() => {
    const output = `${undefinedValue}`;
    if (output) {
      setUndefinedValue('');
    }
    return output;
  }, [undefinedValue, setUndefinedValue]);
  /**
   * Fetch a single value that matches this existing value
   */
  const loadValue = useCallback((value) => {
    let found = additionalOptionsMemoized.concat(rows).find((row) => `${row[key]}` === `${value}`); // check to see if we already have this row
    if (found) return Promise.resolve(found);
    const queryEdit = editQueryString(queryString, {page: 0, rowsPerPage: 1, searchOptions:{[key]: value}});
    return fetchRows({url, queryString: queryEdit}).then((response) => {
      let {results} = response.data;
      if (results && Array.isArray(results) && results.length > 0) {
        if (isMounted()) updateRows(results);
        return results[0];
      } else {
        setUndefinedValue(value);
        return undefined;
      }
    }).catch((e) => {
      // TODO
    })
  }, [key, additionalOptionsMemoized, rows, fetchRows, url, queryString, updateRows, isMounted, setUndefinedValue]);

  /**
   * Loads list using inputValue as the search term
   */
  const loadOptions = useCallback((inputValue, callBack) => {
    const queryEdit = editQueryString(queryString, {searchOptions:{[searchTerm]: inputValue}});
    fetchRows({url, queryString: queryEdit}).then((response) => {
      let {results, total_rows} = response.data;
      if (isMounted()) {
        if(!inputValue && (filters.exclude || []).length === 0) setTotalRows(total_rows);
        const output = concatOptions(results, total_rows);
        if (typeof callBack === "function") callBack(output);
        updateRows(results);
      }
    }).catch(e => {
      console.log(e.response)
    });
  }, [searchTerm, url, queryString, updateRows, setTotalRows, concatOptions, isMounted, filters.exclude]);
  const loadOptionsDebounced = useDebounce(
    (nextValue, callback) => loadOptions(nextValue, callback),
    debounceDelay
  );

  /**
   * Populate the options on first mount
   */
  useEffect(() => {
    loadOptions('', undefined);
  }, [loadOptions]);

  /**
   * Always provide cached options to display in the select dropdown.
   */
  const options = useMemo(() => {
    return concatOptions(rows, totalRows);
  }, [rows, concatOptions, totalRows]);

  return {loadValue, loadOptions, loadOptionsDebounced, options, updateRows, lastRows, takeUndefinedValue};
}

export default useAsyncSelect;
