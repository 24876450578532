import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useField, useFormikContext } from "formik";
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api'
import { configContext } from "../../provider/config/ConfigProvider";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import SearchableSelect from "../common/form/SearchableSelect";
import CountyOption from "./CountyOption";
import CountySingleValue from "./CountySingleValue";
import StateOption from "./StateOption";
import StateSingleValue from "./StateSingleValue";

const libraries = ['places'];

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const extractAddressDetails = (addressObject) => {
  let addressComponents = addressObject.address_components;
  let addressDetails = {};

  console.log(addressObject)

  addressDetails.latitude = addressObject.geometry.location.lat();
  addressDetails.longitude = addressObject.geometry.location.lng();

  for (let i = 0; i < addressComponents.length; i++) {
    let componentType = addressComponents[i].types[0];

    switch (componentType) {
      case "street_number": {
        addressDetails.address_1 = addressComponents[i].short_name + " ";
        break;
      }
      case "route": {
        addressDetails.address_1 += addressComponents[i].short_name;
        break;
      }
      case "postal_code": {
        addressDetails.zip = addressComponents[i].short_name;
        break;
      }
      case "locality":
        addressDetails.city = addressComponents[i].short_name;
        break;
      case "administrative_area_level_1": {
        addressDetails.state = addressComponents[i].short_name;
        break;
      }
      case "administrative_area_level_2":
        addressDetails.county = addressComponents[i].short_name.replace(
          " County",
          ""
        );
        break;
    default: break;
    }
  }

  return addressDetails;
};

const AutoCompleteBoundary = ({isLoaded, children, onLoad, onPlaceChanged}) => {
  if (isLoaded) {
    return <Autocomplete
      fields={["address_components", "formatted_address", "geometry"]}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      restrictions={{ country: "us" } }
    >{children}</Autocomplete>
  } else {
    return <>children</>
  }
}

const Address = () => {

  const { setFieldValue, errors, touched } = useFormikContext();

  const [address1Field] = useField({ name: "address_1" });
  const [address2Field] = useField({ name: "address_2" });
  const [cityField] = useField({ name: "city" });
  const [stateField] = useField({ name: "state" });
  const [zipField] = useField({ name: "zip" });
  const [countyField] = useField({ name: "county" });
  const [latField] = useField({ name: "latitude" });
  const [lngField] = useField({ name: "longitude" });

  const countyApiFilters = useMemo(() => {
    const v = stateField.value;
    if (v) {
      return { state: v }
    } else {
      return {};
    }
  }, [stateField.value]);
  const countySelectFilter = useCallback((option) => {
    return !option.data.state || option.data.state === stateField.value;
  }, [stateField.value]);
  const counties = useAsyncSelect({
    url: '/address/county',
    key: 'county',
    searchTerm: 'county',
    filters: countyApiFilters
  });
  useEffect(() => {
    const value = counties.takeUndefinedValue();
    if (value) {
      const state = stateField.value;
      const countyOption = {county: value};
      if (state) countyOption.state = state;
      counties.updateRows(countyOption);
    }
  }, [counties.updateRows, counties.takeUndefinedValue, stateField.value]);

  const states = useAsyncSelect({
    url: '/address/state',
    key: 'abbreviation',
    searchTerm: 'name',
  });

  const updateAddressFieldsValue = useCallback((addressDetails) => {
    console.log(addressDetails)
    setFieldValue(address1Field.name, addressDetails.address_1);
    setFieldValue(address2Field.name, addressDetails.address_2);
    setFieldValue(cityField.name, addressDetails.city);
    setFieldValue(stateField.name, addressDetails.state);
    setFieldValue(zipField.name, addressDetails.zip);
    setFieldValue(countyField.name, addressDetails.county);
    setFieldValue(latField.name, addressDetails.latitude);
    setFieldValue(lngField.name, addressDetails.longitude);
  }, [setFieldValue, address1Field, address2Field, cityField, stateField, zipField, countyField, latField, lngField]);

  const config = useContext(configContext);
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey: config.google_maps,
    libraries: libraries
  });

  const autoCompleteRef = useRef(null);
  const onLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  }

  const onPlaceChanged = () => {
    if (autoCompleteRef.current !== null) {
      const addressObject = autoCompleteRef.current.getPlace();
      const addressDetails = extractAddressDetails(addressObject);
      updateAddressFieldsValue(addressDetails);
    }
  }

  return (
    <>
      <div className="col-lg-4 mt-6">
        <label>Address Line 1</label>
        <AutoCompleteBoundary
          isLoaded={isLoaded}
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            className={getFieldCSSClasses(
              touched[address1Field.name],
              errors[address1Field.name]
            )}
            name="address_1"
            type="text"
            onChange={(input) => {
              setFieldValue(address1Field.name, input.target.value.split(",")[0]);
            }}
            {...address1Field}
            placeholder="Address Line 1"
            value={address1Field.value ?? ""}
          />
        </AutoCompleteBoundary>
        {errors[address1Field.name] && touched[address1Field.name] ? (
          <div className="invalid-feedback">
            {errors[address1Field.name].toString()}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-lg-4 mt-6">
        <label>Address Line 2</label>
        <input
          className={getFieldCSSClasses(
            touched[address2Field.name],
            errors[address2Field.name]
          )}
          name="address_2"
          type="text"
          onChange={(input) => {
            setFieldValue(address2Field.name, input.target.value.split(",")[0]);
          }}
          {...address2Field}
          placeholder="Address Line 2"
          value={address2Field.value ?? ""}
        />
        {errors[address2Field.name] && touched[address2Field.name] ? (
          <div className="invalid-feedback">
            {errors[address2Field.name].toString()}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-lg-4 mt-6">
        <label>City</label>
        <input
          className={getFieldCSSClasses(
            touched[cityField.name],
            errors[cityField.name]
          )}
          style={{ width: "100%" }}
          {...cityField}
          name="city"
          type="text"
          value={cityField.value ?? ""}
          onChange={(input) => {
            setFieldValue(cityField.name, input.target.value);
          }}
          placeholder="City"
        />
        {errors[cityField.name] && touched[cityField.name] ? (
          <div className="invalid-feedback">
            {errors[cityField.name].toString()}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-lg-4 mt-6">
        <SearchableSelect
          field={stateField}
          form={{ errors, touched, setFieldValue }}
          label="State"
          defaultOptions={states.options}
          loadOptions={states.loadOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.abbreviation}
          loadValue={states.loadValue}
          //filterOption={}
          components={{Option: StateOption, SingleValue: StateSingleValue}}
        />
      </div>

      <div className="col-lg-4 mt-6">
        <label>ZIP</label>
        <input
          className={getFieldCSSClasses(
            touched[zipField.name],
            errors[zipField.name]
          )}
          style={{ width: "100%" }}
          {...zipField}
          name="zip"
          type="tel"
          value={zipField.value ?? ""}
          onChange={(input) => {
            setFieldValue(zipField.name, input.target.value);
          }}
          placeholder="ZIP"
        />
        {errors[zipField.name] && touched[zipField.name] ? (
          <div className="invalid-feedback">
            {errors[zipField.name].toString()}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-lg-4 mt-6">
        <SearchableSelect
          field={countyField}
          form={{ errors, touched, setFieldValue }}
          label="County"
          defaultOptions={counties.options}
          loadOptions={counties.loadOptions}
          getOptionLabel={option => option.county}
          getOptionValue={option => option.county}
          loadValue={counties.loadValue}
          components={{Option: CountyOption, SingleValue: CountySingleValue}}
          filterOption={countySelectFilter}
        />
      </div>

      <input
        {...lngField}
        name="longitude"
        type="hidden"
        value={lngField.value ?? ""}
        onChange={(input) => {
          setFieldValue(lngField.name, input.target.value);
        }}
      />

      <input
        {...latField}
        name="latitude"
        type="hidden"
        value={latField.value ?? ""}
        onChange={(input) => {
          setFieldValue(latField.name, input.target.value);
        }}
      />
    </>
  );
};

export default Address;
