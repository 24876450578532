import React, { useContext, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import { configContext } from "../../provider/config/ConfigProvider";

let countdownInterval;
let timeout;

const SessionTimeout = ({ logOut }) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
    const config = useContext(configContext);

    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async (isTimedOut = false) => {
        try {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            logOut();
        } catch (err) {
            console.error(err);
        }
    };

    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };

    const onActive = () => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };

    const onIdle = () => {
        const delay = 1000 * 1;
        if (!timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDownInSeconds = config.session.timeoutTolerance/1000;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDownInSeconds);

                countdownInterval = setInterval(() => {
                    if (countDownInSeconds > 0) {
                        setTimeoutCountdown(--countDownInSeconds);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };
    
    return (
    <>
        <IdleTimer
            ref={idleTimer}
            onActive={onActive}
            onIdle={onIdle}
            debounce={250}
            timeout={config.session.timeout}
            crossTab={{
                emitOnAllTabs: true
            }}
        />
        <SessionTimeoutDialog
            countdown={timeoutCountdown}
            onContinue={handleContinue}
            open={timeoutModalOpen}
        />
    </>
    );
}

export default SessionTimeout;
