import React, { useCallback, useState } from 'react';
import FormViewWidget from '../form/FormViewWidget';

export default function FormConfigPreview (props) {
  const {
    cardClassName = "card-stretch gutter-b",
    responseSchema,
  } = props;

  const [formData, setFormData] = useState({});
  const setForm = useCallback((newFormData) => {
    console.log(newFormData);
    setFormData(newFormData);
  }, [setFormData]);

  return <>
    <FormViewWidget
      jsonSchema={(responseSchema.response || {}).jsonSchema}
      uiSchema={(responseSchema.response || {}).uiSchema}
      setFormData={setForm}
      formData={formData}
      //onSubmit={onSubmit}
      //onSave={onSave}
      //onCancel={onCancel}
      loading={responseSchema.loading}
      className={cardClassName}
    />
  </>;
}
