import React, { useCallback, useContext } from "react";
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { configContext } from "../../provider/config/ConfigProvider";

export default function CustomBrowserRouter(props) {
  const config = useContext(configContext);
  let base = config.url.client;

  const getUserConfirmation = useCallback(
    (message, cb) => {
      if (typeof message !== 'string') {
        cb(true);
      } else {
        // default behavior https://reactrouter.com/web/api/BrowserRouter/getuserconfirmation-func
        const allowTransition = window.confirm(message);
        cb(allowTransition);
      }
    },
    [],
  );
  return (
    <BrowserRouter basename={base} getUserConfirmation={getUserConfirmation}>
      {props.children}
    </BrowserRouter>
  );
}
CustomBrowserRouter.propTypes = {
  children: PropTypes.node,
  basename: PropTypes.string
};
