import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import React from "react";

/** The `HiddenWidget` is a widget for rendering a hidden input field.
 *  It is typically used by setting type to "hidden".
 *
 * @param props - The `WidgetProps` for this component
 */
function HiddenConstWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(props: WidgetProps<T, S, F>) {
  const { id, value, schema, onChange } = props;
  React.useEffect(() => {
    const {['default']: defaultValue, ['const']: constValue} = schema;
    if (defaultValue === constValue && value !== defaultValue) {
      onChange(defaultValue);
    }
  })
  return <input type='hidden' id={id} name={id} value={typeof value === 'undefined' || value === null ? '' : value} />;
}

export default HiddenConstWidget;
