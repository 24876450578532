import React, { useContext } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { firebaseAuth } from "../../provider/auth/AuthProvider";
import { VerifyCodeInput } from "../common/VerifyCodeInput";
import {phoneParenthesis} from "../../utils/format-phone";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import useIsMounted from "../../hooks/useIsMounted";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "10px",
    borderRadius: "3px"
  },
  dialogFooter: {
    padding: "20px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const VerifyPhoneDialog = withStyles(styles)((props) => {
  const {classes, phone, verificationId, tryCode, success, handleClose} = props;
  const isOpen = () => {
    return !!phone && !!verificationId && typeof tryCode === 'function'
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen()}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Verify Phone Details
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            {phone ?
              <MultiFactorVerify
                phone={phone}
                confirmationResult={(code) => tryCode(code)}
                confirmed={() => success()}
              /> : null }
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              handleClose();
            }} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

const SelectFactorDialog = withStyles(styles)((props) => {
  const {classes, resolver, selectFactor, handleClose} = props;
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(!!resolver);
    if (resolver && resolver.hints.length === 1) {
      selectFactor(0);
      setOpen(false);
    }
  }, [resolver])
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          2FA Required. Please select the phone number that the verification code will be sent to.
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <List>
              {((resolver || {}).hints || []).map((hint, index) => {
                return <ListItem key={hint.uid}>
                  <ListItemText primary={phoneParenthesis(hint.phoneNumber)}/>
                  <Button variant="contained" onClick={() => {
                    selectFactor(index).finally(() => {
                      setOpen(false);
                    });
                  }}>Send</Button>
                </ListItem>
              })}
            </List>
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              handleClose();
            }} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

export const ReauthenticationDialog = withStyles(styles)((props) => {
  const {classes, setErrors, retry, reauthenticate, handleClose} = props;
  const { errors } = useContext(firebaseAuth);
  const [message, setMessage] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const uniqueError = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const close = () => {
    setMessage('');
    setPassword('');
    setLoading(false);
    handleClose();
  }

  const cancel = () => {
    setMessage('Unable to Reauthenticate.\nThis action has been cancelled.');
  }

  const success = () => {
    retry();
    setMessage('');
    setPassword('');
    setLoading(false);
  }

  const submit = () => {
    setLoading(true);
    reauthenticate(password)
      .then(() => success())
      .catch(err => {
        setPassword('');
        setErrors((prev) => [...prev, err]);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <>
      <Dialog onClose={close} aria-labelledby="customized-dialog-title" open={message !== ''}>
        <DialogTitle id="customized-dialog-title" onClose={close}>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            {message}
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              close();
            }} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog onClose={cancel} aria-labelledby="customized-dialog-title" open={typeof retry === 'function'}>
        <DialogTitle id="customized-dialog-title" onClose={cancel}>
          Reauthentication required
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <TextField
              error={errors.length > 0}
              placeholder="Password"
              type="password"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.length > 0
              ? errors.filter(uniqueError).map((error, index) => <p key={index} style={{ color: "red" }}>{error.message}</p>)
              : null}
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button disabled={loading} variant="contained" onClick={() => {
              cancel();
            }} color="secondary">
              Cancel
            </Button>
            <Button disabled={loading} autoFocus variant="contained" onClick={() => {
              submit();
            }} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
});

export const UnenrollConfirmationDialog = withStyles(styles)((props) => {
  const {classes, email, phone, open, confirm, cancel} = props;

  return (
    <div>
      <Dialog onClose={cancel} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={cancel}>
          2FA Unenroll Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <Typography gutterBottom>
              Remove <b>{phone}</b> from the account belonging to <b>{email}</b>
            </Typography>
            <Typography gutterBottom>
              The user will need to rely on their other phone numbers to log in.
            </Typography>
            <Typography gutterBottom>
              If all factors are removed, the user will be able to login with only their email and password.
            </Typography>
            <Typography gutterBottom>
              User may be prompted to enroll in 2FA before being able to gain access to any part of the app.
            </Typography>
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              cancel();
            }} color="secondary">
              Cancel
            </Button>
            <Button autoFocus variant="contained" onClick={() => {
              confirm();
            }} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

function MultiFactorVerify(props) {
  const {phone, confirmationResult, confirmed} = props;
  const [loading, setLoading] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const digits = 6;
  const isMounted = useIsMounted();

  return <>
    <Typography
      gutterBottom
      variant={"subtitle1"}
    >
      Please enter the <b>{digits}</b>-digit code sent to <b>{phone}</b>
    </Typography>
    <div><VerifyCodeInput
      type='tel'
      fields={digits}
      onComplete={(code) => {
        setLoading(true);
        confirmationResult(code).then((result) => {
          confirmed(result);
        }).catch(() => {
          if (isMounted()) {
            setInvalid(true);
            setLoading(false);
          }
        });
      }}
      onReset={() => setInvalid(false)}
      isInvalid={invalid}
      autoFocus={true}
      disabled={loading}
    /></div>
  </>;
}
MultiFactorVerify.propTypes = {
  phone: PropTypes.string.isRequired,
  confirmationResult: PropTypes.func.isRequired,
  confirmed: PropTypes.func.isRequired
}

const MultiFactorDialog = ((props) => {
  const {
    setErrors,
    setToken,
    multiFactorResolver,
    signInWithPhone2FA,
    confirmSignIn,
    reauthenticate
  } = props;
  const { errors } = useContext(firebaseAuth);
  // resolver from the error auth/multi-factor-auth-required.
  const [resolver, setResolver] = React.useState(null);
  // retry function from auth/requires-recent-login
  const [retry, setRetry] = React.useState(null);
  // the verification ID from the resolver.hints provided by firebase
  const [verificationId, setVerificationId] = React.useState(null);
  const [phone, setPhone] = React.useState(null);

  const handleClose = () => {
    setResolver(null);
    setVerificationId(null);
    setPhone(null);
    setRetry(null);
  };
  React.useEffect(() => {
    // look for errors that can be handled and then remove them from the list
    // only remove an error from the list if dialog is closed, so they resolve one at a time and in order
    if (Array.isArray(errors)  ) {
      const i = errors.findIndex((error) => {
        if (error.code === 'auth/multi-factor-auth-required' && !resolver) {
          setResolver(multiFactorResolver(error));
          return true;
        } else if (error.code === `auth/requires-recent-login` && !retry) {
          const retryFunction = error.retry || (() => console.log('reauthentication successful'));
          setRetry(() => () => retryFunction());
          return true;
        }
        return false;
      });
      if (i > -1) {
        // remove this error from the list
        setErrors(errors.filter((e, j) => j !== i));
      }
    }
  },[errors, resolver]);
  // select the hint from the resolver and then get the verificationId from it
  const selectFactor = (index) => {
    const hint = resolver.hints[index];
    setPhone(phoneParenthesis(hint.phoneNumber));
    return signInWithPhone2FA(hint, resolver).then((verificationId) => {
      setResolver(resolver);
      setVerificationId(verificationId);
    });
  }

  // promise that resolves if the code is correct, but throws an error if it is incorrect
  // error is caught by VerifyPhoneDialog to show the invalid state and reset the code
  const tryCode = (code) => {
    // don't redirect to retry after authentication
    const redirectTo = typeof retry === 'function' ? false : undefined;
    return confirmSignIn(verificationId, code, resolver, setToken, redirectTo);
  }

  return <>
    <SelectFactorDialog
      resolver={resolver}
      selectFactor={selectFactor}
      handleClose={() => {
        setResolver(null);
      }}
    />
    <VerifyPhoneDialog
      phone={phone}
      verificationId={verificationId}
      key={verificationId}
      tryCode={tryCode}
      success={() => {
        setPhone(null);
        setVerificationId(null);
        setResolver(null);
        if (typeof retry === 'function') {
          setTimeout(() => {
            retry();
            setRetry(null);
          }, 100);
        }
      }}
      handleClose={handleClose}
    />
    <ReauthenticationDialog
      retry={!resolver ? retry : undefined}
      handleClose={handleClose}
      reauthenticate={reauthenticate}
      setErrors={setErrors}
    />
  </>;
});

export default MultiFactorDialog;
