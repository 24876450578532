import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SiteDataTable from "../../components/site/SiteDataTable";

import SiteService from "../../services/site";

import {
  useQueryString,
  searchOptionsToSearchString,
} from "../../utils/queryString";
import { useStateValue } from "../../store/StateProvider";
import { SET_SITE_QP } from "../../store/queryParams";
import {
  useHasRoles,
  useDidUpdateEffect,
  useNotification,
  useLoading,
} from "../../utils/customHooks";
import { RoleEnum } from "../../config/roles";
import DataTableHeader from "../../components/common/datatable/DataTableHeader";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SearchBar from 'material-ui-search-bar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const PAGE_NAME = "site";

export default function Site() {
  const history = useHistory();
  const setNotification = useNotification();
  const [
    {
      queryParams: { siteQueryParams },
    },
    dispatch,
  ] = useStateValue();
  const hasSiteRoles = useHasRoles([RoleEnum.SiteAdmin]);
  const queryString = useQueryString(PAGE_NAME);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [searchString, setSearchString] = useState(
    searchOptionsToSearchString(siteQueryParams.searchOptions)
  );
  const [orderType, setOrder] = useState(siteQueryParams.orderType);
  const [orderBy, setOrderBy] = useState(siteQueryParams.orderBy);
  const [page, setPage] = useState(siteQueryParams.page);
  const [rowsPerPage, setRowsPerPage] = useState(siteQueryParams.rowsPerPage);
  const [totalRows, setTotalRows] = useState(0);
  const setLoading = useLoading();

  const fetchData = useCallback(() => {
    SiteService.getSites(queryString).then((result) => {
      setRows(result.data.results);
      setTotalRows(result.data.total_rows);
    }).catch((e) => {
      const {response} = e;
      console.log(response);
    });
  }, [setRows, setTotalRows, queryString]);

  const changeArchive = (id) => {
    let parsedRows = [...rows];

    let changedSite = parsedRows.find((item) => {
      return item.site_id === id;
    });

    if (changedSite) {
      changedSite.archive = !changedSite.archive;
      updateSite(changedSite);
    }

    setRows(parsedRows);
  };

  const updateSite = async (site) => {
    let res = await SiteService.editSite(site);

    if (res && res.status === 200) {
      setNotification({
        type: "success",
        message: "Site record has been updated",
      });
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    dispatch({
      type: SET_SITE_QP,
      queryParams: {
        ...siteQueryParams,
        page,
      },
    });
  };

  const gotoEditPage = (id) => {
    history.push('/site/edit/' + id);
  }

  useDidUpdateEffect(() => {
    setPage(0);
    dispatch({
      type: SET_SITE_QP,
      queryParams: {
        ...siteQueryParams,
        orderBy,
        rowsPerPage,
        orderType,
        searchOptions: {
          name: searchString,
        },
        page: 0,
      },
    });
  }, [orderType, orderBy, rowsPerPage, searchString, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DataTableHeader label={'Sites'}>
          <div className="col-lg-4 mt-6">
            <SearchBar
              value={searchString}
              onRequestSearch={setSearchString}
              onCancelSearch={setSearchString}
              //className={classes.searchBar}
              placeholder={"Search by Name"}
            />
          </div>
          <div className={"col"}/>
          <div className="col-lg-2 mt-6">
            <Link to={'/site/create'}>
              <Button
                block
                color="secondary"
                //startIcon={<AddIcon />}
              >
								<span className="svg-icon">
									<SVG
                    src={toAbsoluteUrl(`/media/svg/icons/Code/${"Plus.svg"}`)}
                  />
								</span>
                Create
              </Button>
            </Link>
          </div>
        </DataTableHeader>

        <SiteDataTable
          rows={rows}
          setRows={setRows}
          setSelected={(site_id) => {
            gotoEditPage(site_id);
          }}
          order={orderType}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={handlePageChange}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
        />
      </Paper>
    </div>
  );
}
