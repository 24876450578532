import React from "react";

export function Checkbox({ isSelected, onChange, children, innerRef, className}) {
  return (
    <>
      <label className={`checkbox checkbox-lg checkbox-single ${className}`}>
        <input type="checkbox" checked={isSelected} ref={innerRef} onChange={onChange} />
        {children}
        <span />
      </label>
    </>
  );
}
