import React from 'react';
import { useField, useFormikContext } from "formik";

import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";

import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useState } from 'react';

import moment from 'moment';

import { useDidUpdateEffect } from '../../../../utils/customHooks';
import { isNullOrUndefined } from '../../../../utils/helpers';

import './DateRangePickerField.scss';

const useStyles = makeStyles((theme) => ({
  dateRagneInputContainer: {
    position: "relative",
    minWidth: "194px"
  },
  closeIcon: {
    position: "absolute",
    top: "9px",
    right: "5px",
    cursor: "pointer"
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      padding: "unset",
      paddingLeft: "1rem"
    },
  },
}));

const shouldDisplaySimpleDateRange = () => window.screen.width < 1200;

export function DateRangePickerField({name, ...props}) {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [dateRangeField] = useField({ name: name });
  const [dateRage, setDateRage] = useState([
    {
      startDate: dateRangeField.value ? new Date(dateRangeField.value.split(",")[0]) : new Date(),
      endDate: dateRangeField.value ? new Date(dateRangeField.value.split(",")[1]) : new Date(),
      key: 'selection'
    }
  ]);
  const [dateRangeStr, setDateRangeStr] = useState(dateRangeField.value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetDateRange = () => {
    setFieldValue(dateRangeField.name, "");
  }

  useDidUpdateEffect(() => {
    var startDate = moment(dateRage[0].startDate).format("MM/DD/YYYY");
    var endDate = moment(dateRage[0].endDate).format("MM/DD/YYYY");

    if (startDate === endDate) {
      setFieldValue(dateRangeField.name, startDate);
    } else {
      setFieldValue(dateRangeField.name, `${startDate},${endDate}`);
    }
  }, [dateRage]);

  useDidUpdateEffect(() => {
    if (dateRangeField.value) {
      const [startDate, endDate] = dateRangeField.value.split(",");
      
      if (startDate === endDate || isNullOrUndefined(endDate)) {
        setDateRangeStr(startDate);
      } else {
        setDateRangeStr(`${startDate} - ${endDate}`);
      }
    } else {
      setDateRangeStr("");
    }
  }, [dateRangeField])

  return (
    <>
      <div className={`${classes.dateRagneInputContainer} date-range-input-container`}>
        <input type="text" className="form-control" readOnly
          name={name}
          value={dateRangeStr}
          onClick={handleClick}
          {...props}
        />
        { dateRangeField.value !== "" ? (
          <CloseIcon 
            className={classes.closeIcon}
            onClick={resetDateRange}
          />
        ) : null }
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Modal.Body className={classes.modal}>
          {
            shouldDisplaySimpleDateRange() ? (
              <DateRange
                onChange={item => setDateRage([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRage}
                direction="vertical"
                scroll={{ enabled: true }}
              />
            ) : (
              <DateRangePicker
                onChange={item => setDateRage([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={dateRage}
                direction="vertical"
                scroll={{ enabled: true }}
                className={"date-range-picker-desktop"}
              />
            )
          }
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => handleClose()}
            className="btn btn-primary btn-elevate"
          >
            Choose
          </button>
        </Modal.Footer>
      </Popover>
    </>
  );
}
