import {
  SET_NOTIFICATION,
  HIDE_NOTIFICATION
} from './actions';

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.notification
      }
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: null
      }
    }
    default:
      return state
  }
}

export default NotificationReducer
