import React, { useContext } from "react";

import { Link, NavLink, useLocation } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl, checkIsActive } from "../_metronic/_helpers";
import { firebaseAuth } from "../provider/auth/AuthProvider";

import { useHasRoles } from '../utils/customHooks';
import { RoleEnum } from '../config/roles';
import { configContext } from "../provider/config/ConfigProvider";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolBar: {
    [theme.breakpoints.down('md')]: {
      minHeight: "unset"
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1e1e2d"
  },
  btns: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: `4px 8px`,
      fontSize: `0.8125rem`,
    }
  }
}));

function Header(props) {
  const { window } = props;
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { handleSignout } = useContext(firebaseAuth);
  const hasSiteRole = useHasRoles([RoleEnum.SiteAdmin]);
  const hasUserRole = useHasRoles([RoleEnum.UserAdmin]);
  const hasUploadRole = useHasRoles([RoleEnum.UploadUser]);
  const hasDeveloperRole = useHasRoles([RoleEnum.Developer]);
  const hasFormRole = useHasRoles([RoleEnum.FormUser]);
  const hasStrokeRole = useHasRoles([RoleEnum.StrokeUser]);
  const hasSuperTableauRole = useHasRoles([RoleEnum.TableauAdmin], [0]);
  const hasSuperFormRole = useHasRoles([RoleEnum.FormAdmin], [0]);
  const config = useContext(configContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const MenuItems = ({ isMobile }) => (
    <div>
      <div className="aside-menu">
        <ul className="menu-nav">
          { hasSiteRole ? (
              <li
                className={`menu-item ${getMenuItemActive("/sites", false)}`}
                aria-haspopup="true"
                onClick={() => {
                  if (isMobile) {
                    handleDrawerToggle();
                  }
                }}
              >
                <NavLink className="menu-link" to="/sites">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                    />
                  </span>
                  <span className="menu-text">Sites</span>
                </NavLink>
              </li>
            ) : null
          }
          { hasSuperTableauRole ? (
            <li
              className={`menu-item ${getMenuItemActive("/dashboard/embedding", false)}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to="/dashboard/embedding">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Group.svg"
                      )}
                    />
                  </span>
                <span className="menu-text">Dashboard Embedding</span>
                <span className="label label-info label-inline mr-2">DEV</span>
              </NavLink>
            </li>
          ) : null
          }
          { hasSuperTableauRole && hasSiteRole ? (
            <li
              className={`menu-item ${getMenuItemActive("/dashboard/group", false)}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to="/dashboard/group">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Group.svg"
                      )}
                    />
                  </span>
                <span className="menu-text">Dashboard Groups</span>
              </NavLink>
            </li>
          ) : null
          }
          { hasUserRole ? (
              <li
                className={`menu-item ${getMenuItemActive("/user", false)}`}
                aria-haspopup="true"
                onClick={() => {
                  if (isMobile) {
                    handleDrawerToggle();
                  }
                }}
              >
                <NavLink className="menu-link" to="/user">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/User.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Users</span>
                </NavLink>
              </li>
            ) : null
          }
          { hasSuperFormRole ? (
            <li
              className={`menu-item ${getMenuItemActive(
                "/form",
                hasDeveloperRole && hasSiteRole
              )}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to={hasSiteRole ? "/form/schema" : hasDeveloperRole ? "/form/editor" : "/403"}>
                <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                    />
                  </span>
                <span className="menu-text">Form Configs</span>
                {  hasDeveloperRole && hasSiteRole ? <i className="menu-arrow"></i> : null }
              </NavLink>
              {  hasDeveloperRole && hasSiteRole ? <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  {hasSiteRole ? <li className={`menu-item ${getMenuItemActive('/form/schema')}`}>
                    <NavLink className="menu-link" to="/form/schema">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Schema</span>
                    </NavLink>
                  </li> : null}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {hasDeveloperRole ? <li className={`menu-item ${getMenuItemActive('/form/editor')}`}>
                    <NavLink className="menu-link" to="/form/editor">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Editor</span>
                      <span className="label label-info label-inline mr-2">DEV</span>
                    </NavLink>
                  </li> : null}
                  {/*end::2 Level*/}
                </ul>
              </div> : null }
            </li>) : null
          }
          { hasDeveloperRole ? (
            <li
              className={`menu-item ${getMenuItemActive(
                "/developer",
                false
              )}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to="/developer">
                <span className="svg-icon menu-icon">
                  <DeveloperModeIcon />
                </span>
                <span className="menu-text">Developer</span>
                <span className="label label-info label-inline mr-2">DEV</span>
              </NavLink>
            </li>) : null
          }
          { hasStrokeRole ? (
            <li
              className={`menu-item ${getMenuItemActive(
                "/stroke",
                true
              )}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to={hasFormRole ? "/stroke/form" : hasUploadRole ? "/stroke/upload" : "/403"}>
                <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Thunder.svg")}
                    />
                  </span>
                <span className="menu-text">Stroke</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  {hasFormRole ? <li className={`menu-item ${getMenuItemActive('/stroke/form')}`}>
                    <NavLink className="menu-link" to="/stroke/form">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Manual Form</span>
                    </NavLink>
                  </li> : null}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {hasUploadRole ? <li className={`menu-item ${getMenuItemActive('/stroke/upload')}`}>
                    <NavLink className="menu-link" to="/stroke/upload">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Upload Spreadsheet</span>
                    </NavLink>
                  </li> : null}
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>) : null
          }
          {/*<ApiServiceSuspense url={'/cardiac/validate'}>
            <li
              className={`menu-item ${getMenuItemActive(
                "/cardiac",
                false
              )}`}
              aria-haspopup="true"
              onClick={() => {
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
            >
              <NavLink className="menu-link" to="/cardiac">
                <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")}
                    />
                  </span>
                <span className="menu-text">Cardiac</span>
              </NavLink>
            </li>
          </ApiServiceSuspense>*/}
        </ul>
      </div>
    </div>

  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar className={classes.toolBar}>
          <div className="topbar main-header">
            <div style={{maxWidth: '50%'}}>
              <IconButton
                onClick={handleDrawerToggle}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>

              <Link to="/" className="main-logo">
                <img
                  alt="Logo"
                  className="max-h-30px"
                  src={toAbsoluteUrl(config.branding.site_logo)}
                />
              </Link>
            </div>

            <div style={{minWidth: '160px'}}>
              {/* Since 2FA Settings are the only profile setting (for now),  disable the button if 2fa is not enabled.*/}
              {config.multiFactor.enable ?
                <Button
                  component={Link}
                  to="/profile"
                  variant="contained"
                  className={classes.btns}
                >
                  Profile
                </Button> : null}
              <Button
                style={{float: 'right'}}
                onClick={() => handleSignout('Logged Out')}
                variant="contained"
                className={classes.btns}
              >
                Logout
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden xsUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MenuItems isMobile={true} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <MenuItems />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default Header;
