import {
  SET_PROGRESS,
  SET_CONFIG,
} from './actions'

const MultiFormReducer = (state, action) => {
  switch (action.type) {
    case SET_PROGRESS: {
      return {
        ...state,
        multiForm: {
          ...state.multiForm,
          progress: action.results
        }
      }
    }
    case SET_CONFIG: {
      return {
        ...state,
        multiForm: {
          ...state.multiForm,
          config: action.results
        }
      }
    }
  }
}

export default MultiFormReducer;
