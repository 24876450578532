import React from 'react';
import { Col, Row, Form } from "react-bootstrap";
import SiteNameCell from './datatable/cell/SiteNameCell';
import SiteAddressCell from './datatable/cell/SiteAddressCell';

const SiteDataRow = (props) => {
  const {
    row,
    handleClick
  } = props;
  const {
    county
  } = row;

  return <tr onClick={handleClick}>
    <td>
      <SiteNameCell row={row} />
    </td>
    <td>
      <SiteAddressCell row={row} />
    </td>
    <td>
      {county}
    </td>
  </tr>
}

export default SiteDataRow;
