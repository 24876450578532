import {
  SET_USERS,
  SET_USER
} from './actions';

const UserReducer = (state, action) => {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: action.users
      }
    }
    case SET_USER: {
      return {
        ...state,
        users: state.users.push(action.user)
      }
    }
    default:
      return state
  }
}

export default UserReducer
