import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import DateCell from '../common/datatable/cell/DateCell';
import Select from '../common/form/Select';
import React from 'react';
import useApiService from '../../hooks/useApiService';
import useAxiosResponse from '../../hooks/useAxiosResponse';

const UserTableauAccount = () => {
  const { values } = useFormikContext();

  const fetchRoles = useApiService({
    url: '/user/option/tableau/roles',
    key: 'user_title_id',
  });
  const [{response: siteRoles}] = useAxiosResponse(fetchRoles);

  if (!values['tableau']) return null;
  return <Row>
    <Col xs={'auto'}>
      <label>Tableau Last Login</label>
      <DateCell
        row={values}
        id={'tableauLastLogin'} />
    </Col>
    <Col>
      <Field
        name={'tableauSiteRole'}
        component={Select}
        label="Tableau Account Role"
        value={values.tableauSiteRole}
        options={siteRoles}
        useOptionField={false}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        isClearable={false}
      />
    </Col>
  </Row>
}

export default UserTableauAccount;
