import BaseInputMaskTemplate from "./BaseInputMaskTemplate";
import FieldErrorTemplate from "./FieldErrorTemplate";
import FieldTemplate from "./FieldTemplate";
import MinorObjectFieldTemplate from './MinorObjectFieldTemplate';


export default {
  BaseInputMaskTemplate: BaseInputMaskTemplate,
  FieldErrorTemplate: FieldErrorTemplate,
  FieldTemplate: FieldTemplate,
  MinorObjectFieldTemplate: MinorObjectFieldTemplate
}
