import React, {useEffect, useState} from 'react';
import { isEqual } from "lodash";

export default function SubmitListener (props) {
  const {formik} = props;
  const [lastValues, updateState] = useState(formik.values);
  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);
    const valuesEqualInitialValues = formik.values === formik.initialValues;
    if (!valuesEqualLastValues) {
      updateState(props.formik.values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues && !formik.isSubmitting) {
      formik.handleSubmit();
    }
  }, [lastValues, formik.values, formik.initialValues, formik.isSubmitting, formik]);

  return null;
}
