import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from "react-bootstrap";

const DataTablePagination = (props) => {
  const {
    rowsPerPageOptions,
    totalRows,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage
  } = props;

  const [defaultRowsPerPage, setDRPP] = useState([10, 25, 50, 100])
  const maxButtonCount = 9;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const offset = page * rowsPerPage;
  const btnClass = 'btn btn-icon mx-1 py-2 px-4';

  useEffect(() => {
    setDRPP((prev) => {
      if (prev.includes(rowsPerPage)) {
        return prev;
      }
      return [...prev, rowsPerPage]
    })
  }, [setDRPP, rowsPerPage])

  const pageDisplayOptions = useMemo(() => {
    const values = [...defaultRowsPerPage, ...rowsPerPageOptions].map((x) => parseInt(`${x}`))
    const set = new Set(values);
    return [...set]
      .filter((x) => !isNaN(x))
      .sort((a,b ) => {
        return a-b
      }).map((x) => `${x}`)
  }, [rowsPerPageOptions, defaultRowsPerPage])

  const handleRowsPerPage = useCallback((e) => {
    onChangeRowsPerPage(e);
  }, [onChangeRowsPerPage])

  const handlePage = useCallback((e) => {
    onChangePage(e, Math.max(0, Math.min(totalPages - 1, +e.target.value)));
  }, [onChangePage, totalPages])

  const pageButtonProps = useCallback((pageNumber) => {
    const version = pageNumber === (page + 1) ? 'btn-primary' : 'btn-text-light btn-hover-primary';
    return {
      key: `${pageNumber}`,
      children: `${pageNumber}`,
      className: `${btnClass} ${version}`,
      value: `${pageNumber - 1}`,
      onClick: handlePage
    }
  }, [btnClass, handlePage, page])

  const buttonProps = useMemo(() => {
    // 2 buttons at each end will be prev/next page
    const pageButtonCount = maxButtonCount - 2;
    if (totalPages <= pageButtonCount) {
      return Array.from({length: totalPages}, (_, i) => i + 1).map(pageButtonProps)
    }

    // first and last buttons will always be displayed
    const middleButtonCount = pageButtonCount - 2;
    const first = 1;
    const last = totalPages
    // current page is centered, or offset to one side
    const currentPage = page + 1;
    const pageIsOdd = currentPage % 2 === 1;
    const buttonCountIsOdd = middleButtonCount % 2 === 1;
    const half = Math.ceil((middleButtonCount) / 2);
    let start = currentPage - half + 1;
    let end = currentPage + half;
    // current page won't be centered with even number of buttons, offset to one side
    if (!buttonCountIsOdd) {
      if (!pageIsOdd) {
        end++;
      } else {
        start--;
      }
    }
    let startJumper = Math.max(first + 1, start - (end - currentPage) + 2);
    let endJumper = Math.min(last - 1, end + (currentPage - start) - 2);
    // buttons too close to the start will not have a jumper
    if (start <= first) {
      const diff = first - start + 1
      end += diff;
      endJumper += diff;
      startJumper = false;
      start = first + 1
    }
    if (end >= last) {
      const diff = end - last
      start -= diff;
      startJumper -= diff;
      endJumper = false;
      end = last;
    }

    const middle = Array.from({ length: end - start }, (_, i) => start + i);
    const result = [first,...middle,last]
    return result.map(pageButtonProps).map((props, index, array) => {
      if (index === 1 && startJumper && `${startJumper -1}` !== props.value) {
        return {
          ...props,
          key: 'start' + props.key,
          value: `${startJumper - 1}`,
          children: '<<'
        }
      }
      if (index === array.length - 2 && endJumper && `${endJumper -1}` !== props.value) {
        return {
          ...props,
          key: 'end' + props.key,
          value: `${endJumper - 1}`,
          children: '>>'
        }
      }
      return props;

    })
  }, [pageButtonProps, totalPages, maxButtonCount, page]);

  return <Row className={'p-3'}>
    <Col xs={'auto'} className={"mt-2"}>
      Showing {Math.min(offset + 1, totalRows)} to {Math.min(offset + rowsPerPage, totalRows)} of {totalRows} entries
    </Col>
    <Col/>
    <Col xs={'auto'}>
      <Row>
        <Col xs={'auto'} className={"mt-2 p-0"}>
          Display
        </Col>
        <Col xs={'auto'}>
          <select
            className={'form-control form-control-sm'}
            value={rowsPerPage}
            onChange={handleRowsPerPage}>
            {pageDisplayOptions.map((option) => {
              return <option key={option}>{option}</option>
            })}
          </select>
        </Col>
      </Row>
    </Col>
    <Col  xs={'auto'}>
      <button
        className={`${btnClass} btn-light btn-hover-primary`}
        value={page - 1}
        disabled={page < 1}
        onClick={handlePage}>
        {'<'}
      </button>
      {buttonProps.map((props) => <button {...props}/>)}
      <button
        className={`${btnClass} btn-light btn-hover-primary`}
        value={page + 1}
        disabled={page > totalPages - 2}
        onClick={handlePage}>
        {'>'}
      </button>
    </Col>
  </Row>
}

export default DataTablePagination;
