import React, { useMemo } from "react";
import DataTable from "../../common/datatable/DataTable";
import UploadProgressCell from "../../multiForm/datatable/cell/UploadProgressCell";
import UploadEntriesCell from "../../multiForm/datatable/cell/UploadEntriesCell";
import DateCell from "../../common/datatable/cell/DateCell";

/*
 * created_at: "2022-04-29T23:07:26.153Z"
 * id: 59
 * org_name: "Stroke.csv"
 * progress: 4902
 * total: 4902
 * updated_at: "2022-04-29T23:26:26.130Z"
 * upload_id: 389
 * user_id: 1
 */

const SpreadsheetDataTable = (props) => {
  const {
    rows,
    replaceRow,
    totalRows,
    queryParams,
    errorMessage,
    loading,
    retry,
    handleClickRow
  } = props;

  const headCols = useMemo(() => {
    return [
      {
        id: "org_name",
        label: "File Name",
        sortable: true,
        searchable: true,
        width: '200px',
      },
      {
        id: "site_name",
        label: "Site Name",
        sortable: true,
        width: '17%',
      },
      {
        id: "progress",
        label: "Progress",
        sortable: true,
        maxWidth: '50%',
        renderCell: (props) => <UploadProgressCell {...props} replaceRow={replaceRow}/>
      },
      {
        id: "total",
        label: "# of Entries",
        sortable: true,
        width: '170px',
        renderCell: (props) => <UploadEntriesCell {...props}/>
      },
      {
        id: "created",
        label: "Created",
        sortable: true,
        width: '150px',
        renderCell: (props) => <DateCell {...props}/>
      },
    ]
  }, [replaceRow]);

  return <DataTable
    headCols={headCols}
    idName={'id'}
    queryParams={queryParams}
    rows={rows}
    totalRows={totalRows}
    errorMessage={errorMessage}
    loading={loading}
    retry={retry}
    handleClickRow={handleClickRow}
  />
}

export default SpreadsheetDataTable;
