import {
  FieldProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  getUiOptions,
  ErrorSchema,
  GenericObjectType,
  PROPERTIES_KEY
} from "@rjsf/utils";
import React, { useCallback, useMemo } from "react";
import _ from "lodash";

function ReadOnlyCopyField <T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: FieldProps<T, S, F>
) {
  const {
    schema,
    idSchema,
    idSeparator = '/',
    uiSchema: rawUiSchema,
    registry,
    onChange,
  } = props;
  const { fields, globalUiOptions, schemaUtils } = registry;
  const ObjectField = fields.ObjectField;
  const [uiSchema, copyPaths] = useMemo(() => {
    const uiOptions = getUiOptions<T, S, F>(rawUiSchema, globalUiOptions);
    const { readOnlyCopy } = uiOptions;
    const copyPaths = [] as [string, string][];
    const uiSchema = _.cloneDeep({ ...rawUiSchema });
    if (_.isObject(readOnlyCopy)) {
      for (const [readOnlyPath, copyPairs] of Object.entries(readOnlyCopy)) {
        const schemaPath = readOnlyPath.split(idSeparator).join(`${idSeparator}${PROPERTIES_KEY}${idSeparator}`).split(idSeparator).slice(1);
        const {readOnly} = _.get(schema, schemaPath) || {};
        for (const [from, to] of copyPairs) {
          const toPath = to.split(idSeparator).filter(Boolean);
          _.set(uiSchema, toPath.concat('ui:disabled'), readOnly);
        }
        if (readOnly) {
          copyPaths.push(...copyPairs);
        }
      }
    }
    return [uiSchema, copyPaths];
  }, [rawUiSchema, globalUiOptions, schema]);


  const handleChange = useCallback((formData: T | undefined, errorSchema?: ErrorSchema<T>, id?: string) => {
    if (copyPaths.length === 0) {
      return onChange(formData, errorSchema, id);
    }
    //const original = _.cloneDeep(formData)
    const newFormData: GenericObjectType = {
      ...formData
    };
    if (copyPaths.length > 0) {
      for (const [from, to] of copyPaths) {
        const fromPath = from.split(idSeparator).filter(Boolean);
        const toPath = to.split(idSeparator).filter(Boolean);
        if (_.has(formData, fromPath)) {
          const value = _.get(formData, fromPath);
          _.set(newFormData, toPath, value);
        } else {
          _.unset(newFormData, toPath);
        }
      }
    }
    //console.log('readOnlyField handleChange', id, { original, newFormData });
    return onChange(newFormData as T, errorSchema, id);
  }, [onChange, schemaUtils, copyPaths, idSchema])

  //console.log('readonlyField Render', props.formData)

  return <ObjectField
    {...props}
    onChange={handleChange}
    //schema={schema}
    uiSchema={uiSchema}
  />;
}

export default ReadOnlyCopyField;
