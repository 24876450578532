import React, { useCallback, useEffect, useRef, useState } from "react";
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useApiService from "../../hooks/useApiService";
import useIsMounted from "../../hooks/useIsMounted";
import { Col, Card } from "react-bootstrap";
import DataTableHeader from "../../components/common/datatable/DataTableHeader";
import { useHistory } from "react-router-dom";
import SpreadsheetUploadProgress from "../../components/upload/spreadsheet/SpreadsheetUploadProgress";
import SpreadsheetDataTable from "../../components/upload/spreadsheet/SpreadsheetDataTable";
import useUploadService from "../../hooks/useUploadService";
import SiteSelect from "../../components/stroke/SiteSelect";

const PAGE_NAME = 'stroke';

export default function StrokeUploadPage () {
  const urlRows = '/multiform/stroke/upload/list';
  const history = useHistory();
  const isMounted = useIsMounted();
  const queryParams = useQueryParamStore(PAGE_NAME, 'SET_STROKE_QP', ['site_id', 'config_id']);
  const fetchRows = useApiService({url: urlRows, queryString: queryParams.queryString});
  const uploadForm = useUploadService({url: '/multiform/stroke/upload'});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [errorSite, setErrorSite] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    fetchRows({ queryString: queryParams.queryString }).then((response) => {
      let {results, total_rows} = response.data;
      if (isMounted()) {
        setTotalRows(total_rows);
        setRows(results);
        setErrorSite('');
      }
    }).catch((e) => {
      const {response} = e;
      console.log(response);
      if (isMounted()) {
        // FIXME allow user to get the list of uploads with config only, site specified only for submit
        if (response.status === 400) {
          setErrorSite('Choose a site from the dropdown.');
        } else {
          setErrorSite('Unable to load entries.');
        }
      }
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, setTotalRows, setRows,  queryParams.queryString, setLoading, setErrorSite]);

  const submitting = useRef(false);
  const handleSubmit = useCallback((values, actions) => {
    if (submitting.current) return;
    submitting.current = true;
    const fileKey = 'file';
    actions.setSubmitting(true);
    const config = {
      body: {
        [fileKey]: values[fileKey]
      },
      files: [fileKey],
      progressCallback: (e) => {
        actions.setFieldValue(fileKey, e, false);
      }
    };
    config.headers = {
      'Content-Type': 'multipart/form-data'
    };
    if (queryParams.searchOptions.site_id) {
      config.url = `/multiform/stroke/upload?site_id=${queryParams.searchOptions.site_id}`;
    }
    uploadForm(config).then((response) => {
      actions.setFieldValue(fileKey, '', false);
      actions.setFieldTouched(fileKey, false, false);
      const {data} = response;
      setRows((prev) => {
        return [data].concat(prev);
      });
    }).catch(e => {
      let errMessage = '';
      let {response} = e;
      response = response || {};
      if (response.status === 413) {
        errMessage = 'File too big';
      } else if (response.status === 400) {
        errMessage = response.data.message;
        console.log(response.data)
      } else {
        const {loaded, total} = values[fileKey];
        if (loaded !== total) {
          errMessage = 'Error occurred when uploading file.';
        } else {
          errMessage = 'Server error occurred when processing file.';
        }
        console.log(response)
      }
      actions.setFieldValue(fileKey, '', false);
      actions.setFieldError(fileKey, errMessage);
    }).finally(() => {
      submitting.current = false;
      actions.setSubmitting(false);
    })
  }, [uploadForm, setRows, queryParams.searchOptions.site_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const replaceRow = useCallback((row) => {
    setRows((prev) => {
      return prev.map((toReplace) => {
        if (toReplace.id === row.id) {
          return row;
        } else {
          return toReplace;
        }
      })
    })
  }, [setRows]);

  const handleClickRow = useCallback((row) => {
    history.push('/stroke/upload/' + row.id);
  }, [history]);

  return <div>
    <Card className={'w-100'}>
      <DataTableHeader
        label={"Stroke"}
      >
        <Col/>
        <Col xs={'4'}  className={'mt-6'}>
          <SiteSelect url={'/multiform/stroke/upload/sites'} hasError={!!errorSite}/>
        </Col>
        <Col xs={'4'} className={'mt-6'}>
          <SpreadsheetUploadProgress handleSubmit={handleSubmit}/>
        </Col>
      </DataTableHeader>
      <SpreadsheetDataTable
        rows={rows}
        replaceRow={replaceRow}
        queryParams={queryParams}
        totalRows={totalRows}
        loading={loading}
        errorMessage={errorSite}
        retry={() => fetchData()}
        handleClickRow={handleClickRow}
      />
    </Card>
  </div>;
}