import {
  SET_SITE_QP,
  SET_PARTICIPANT_QP,
  SET_USER_QP,
  SET_TEST_QP,
  RESET_TEST_QP,
  SET_UPLOAD_QP,
  SET_STROKE_QP,
  SET_DASHBOARD_GROUP_QP
} from './actions';
import _ from 'lodash';

const defaultQueryParams = {
  orderType: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 25,
  searchOptions: {}
}

const NamedQueryParamsReducer = (name) => {
  return (state, action) => {
    switch (action.type) {
      case `SET_QP_${name}`:
        const { queryParams } = (state[name]) || {};
        const outputParams = {
          orderType: action.orderType || queryParams.orderType || defaultQueryParams.orderType,
          orderBy: action.orderBy || queryParams.orderBy || defaultQueryParams.orderBy,
          page: action.page || queryParams.page || defaultQueryParams.page,
          rowsPerPage: action.rowsPerPage || queryParams.rowsPerPage || defaultQueryParams.rowsPerPage,
          searchOptions: queryParams.searchOptions || defaultQueryParams.searchOptions,
        }
        if (action.searchOptions) {
          _.merge(outputParams.searchOptions, action.searchOptions);
          outputParams.page = 0;
        }

        return {
          ...state,
          [name]: {
            ...state[name],
            queryParams: outputParams
          }
        }
      default:
        return state;
    }
  }
}

const QueryParamsReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_QP: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          userQueryParams: action.queryParams,
        },
      };
    }
    case SET_PARTICIPANT_QP: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          participantQueryParams: action.queryParams,
        },
      };
    }
    case SET_SITE_QP: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          siteQueryParams: action.queryParams,
        },
      };
    }
    case SET_TEST_QP: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          testQueryParams: action.queryParams,
        },
      };
    }
    case RESET_TEST_QP: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          testQueryParams: {
            orderType: 'asc',
            orderBy: 'test_id',
            page: 0,
            rowsPerPage: 5,
            searchOptions: {}
          }
        },
      };
    }
    case SET_UPLOAD_QP: {
      return {
        ...state,
        queryParams:{
          ...state.queryParams,
          uploadQueryParams: action.queryParams,
        }
      }
    }
  case SET_STROKE_QP: {
    return {
      ...state,
      queryParams:{
        ...state.queryParams,
        strokeQueryParams: action.queryParams,
      }
    }
  }
  case SET_DASHBOARD_GROUP_QP: {
    return {
      ...state,
      queryParams:{
        ...state.queryParams,
        dashboardGroupQueryParams: action.queryParams,
      }
    }
  }
    default:
      return state;
  }
};

export default QueryParamsReducer;
