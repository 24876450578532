import { Col, Row } from 'react-bootstrap';
import React from 'react';

export default function SiteAddressCell ({row}) {
  const {
    address_1,
    address_2,
    city,
    state,
    zip,
  } = row;

  return <>
    <Row>
      <Col xs={'auto'} className={'pr-0'}>
        {address_1}
      </Col>
      { address_2 ?
        <Col xs={'auto'}>
          <span className="text-primary">
            {address_2}
          </span>
        </Col> : null }
    </Row>
    <Row>
      <Col>
        {city} {state}, {zip}
      </Col>
    </Row>
  </>
}
