import { useFormikContext } from 'formik';
import DateCell from '../common/datatable/cell/DateCell';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import UserMultiFactorEdit from './UserMultiFactorEdit';

const UserFirebaseAccount = () => {
  const { values } = useFormikContext();
  if (!values['firebase']) return null;
  return <Row>
    <Col xs={'auto'}>
      <label>Firebase Last Login</label>
      <DateCell
        row={values}
        id={'firebaseLastLogin'} />
    </Col>
    <Col>
      <UserMultiFactorEdit/>
    </Col>
  </Row>
}

export default UserFirebaseAccount;