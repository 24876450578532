import React, { useCallback, useEffect, useState } from "react";
import UploadService  from "../../services/uploads";
import { SET_UPLOAD_QP } from "../../store/queryParams";
import DataTableHeader from "../../components/common/datatable/DataTableHeader";
import SpreadsheetUploadProgress from "../../components/upload/spreadsheet/SpreadsheetUploadProgress";
import { Col, Card } from "react-bootstrap";
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useApiService from "../../hooks/useApiService";
import SpreadsheetDataTable from "../../components/upload/spreadsheet/SpreadsheetDataTable";
import useIsMounted from "../../hooks/useIsMounted";

const PAGE_NAME = 'upload';

const Upload = () => {
  const urlRows = '/upload/list';
  const isMounted = useIsMounted();
  const queryParams = useQueryParamStore(PAGE_NAME, SET_UPLOAD_QP);
  const fetchRows = useApiService({url: urlRows, queryString: queryParams.queryString});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);

  const fetchData = useCallback(() => {
    fetchRows({url: urlRows, queryString: queryParams.queryString}).then((result) => {
      if (isMounted()) {
        setRows(result.data.results);
        setTotalRows(result.data.total_rows);
      }
    }).catch((e) => {
      const {response} = e;
      console.log(response);
    });
  }, [setRows, setTotalRows, fetchRows, isMounted]);

  const handleSubmit = (values, actions) => {
    const fileKey = 'file';
    actions.setSubmitting(true);
    UploadService.uploadForm(
      {
        [fileKey]: values[fileKey]
      },
      ['file'],
      '/upload/stroke',
      (e) => {
        actions.setFieldValue(fileKey, e, false);
      }
    ).then((response) => {
      actions.setFieldValue(fileKey, '', false);
      actions.setFieldTouched(fileKey, false, false);
      const {data} = response;
      setRows((prev) => {
        return [data.importProgress].concat(prev);
      });
    }).catch(e => {
      let errMessage = '';
      let {response} = e;
      response = response || {};
      if (response.status === 413) {
        errMessage = 'File too big';
      } else if (response.status === 400) {
        errMessage = response.data.message;
        console.log(response.data)
      } else {
        const {loaded, total} = values[fileKey];
        if (loaded !== total) {
          errMessage = 'Error occurred when uploading file.';
        } else {
          errMessage = 'Server error occurred when processing file.';
        }
        console.log(response)
      }
      actions.setFieldValue(fileKey, '', false);
      actions.setFieldError(fileKey, errMessage);
    }).finally(() => {
      actions.setSubmitting(false);
    })
  }

  const replaceRow = useCallback((row) => {
    setRows((prev) => {
      return prev.map((toReplace) => {
        if (toReplace.id === row.id) {
          return row;
        } else {
          return toReplace;
        }
      })
    })
  }, [setRows]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Card className={'w-100'}>
        <DataTableHeader
          label={"Upload Data"}
        >
          <Col/>
          <Col xs={'4'} className={'mt-6'}>
            <SpreadsheetUploadProgress handleSubmit={handleSubmit}/>
          </Col>
        </DataTableHeader>
        <SpreadsheetDataTable
          rows={rows}
          replaceRow={replaceRow}
          queryParams={queryParams}
          totalRows={totalRows}
        />
      </Card>
    </div>
  );
}

export default Upload;
