import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Spinner, Table } from "react-bootstrap";
import EnhancedTableHead from "./EnhancedTableHead";
import DataTablePagination from "./DataTablePagination";
import DataTableRow from "./DataTableRow";

const propTypes = {
  headCols: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    width: PropTypes.string,
    searchable: PropTypes.bool,
    renderHead: PropTypes.func,
    renderCell: PropTypes.func,
    onClick: PropTypes.func,
  })).isRequired,
  idName: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({
    queryString: PropTypes.string,
    searchOptions: PropTypes.object,
    setSearchOptions: PropTypes.func,
    orderType: PropTypes.string.isRequired,
    setOrderType: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    setRowsPerPage: PropTypes.func.isRequired,
  }).isRequired,
  rows: PropTypes.array,
  replaceRow: PropTypes.func,
  totalRows: PropTypes.number,
  handleClickRow: PropTypes.func,
  bordered: PropTypes.bool,
  rowProps: PropTypes.func
};

const DataTable = (props) => {
  const {
    headCols,
    idName,
    queryParams,
    rows,
    totalRows,
    handleClickRow,
    bordered,
    errorMessage,
    loading,
    retry,
    rowProps
  } = props;

  const {orderType, setOrderType, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage} = queryParams;

  const handleRequestSort = useCallback((event, property) => {
    if (orderBy === property) {
      if (orderType === "asc"){
        setOrderType("desc");
      } else if (orderType === "desc") {
        setOrderType("asc");
      }
    } else {
      setOrderBy(property);
    }
  }, [setOrderType, setOrderBy, orderBy, orderType]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
  }, [setRowsPerPage]);

  return <div>
    <DataTablePagination
      rowsPerPageOptions={[25, 50, 100]}
      totalRows={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    <div style={{
      padding: bordered ? 10 : 0,
      overflowX: "auto",
    }}>
      <Table
        bordered={bordered}
        hover={typeof handleClickRow === 'function'}
        className={loading ? 'table-secondary' : !!errorMessage ? 'table-danger' : ''}
        style={{
        minWidth: 750,
        borderCollapse: 'initial !important',
        borderSpacing: '0 !important',
        borderRadius: bordered ? '0.42rem' : '',
      }}>
        <EnhancedTableHead
          headCols={headCols}
          order={orderType}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <tbody>
        {loading ? <tr><th colSpan={headCols.length}><Spinner animation={'border'}/></th></tr> : errorMessage ?  <tr><th colSpan={headCols.length}>
          <div>{errorMessage}</div><button className={'btn btn-danger mr-3'} onClick={retry}>Retry</button>
        </th></tr> : rows.map((row) => {
          const additionalProps = typeof rowProps === 'function' ? rowProps(row) : {};
          return <DataTableRow
            key={row[idName]}
            headCols={headCols}
            row={row}
            handleClick={handleClickRow}
            {...additionalProps}
          />;
        }) }
        </tbody>
      </Table>
    </div>
    <DataTablePagination
      rowsPerPageOptions={[25, 50, 100]}
      totalRows={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </div>
}

DataTable.propTypes = propTypes;
export default DataTable;
