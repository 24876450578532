import React from "react";
import MaskedInput from "react-text-mask";

const PhoneNumber = ({ field, form: { touched, errors }, ...props }) => {
    return (
      <div>
        <label>Office Phone</label>
        <MaskedInput
          mask={[
            /[1-9]/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          {...field}
          {...props}
          className={
            !touched[field.name]
              ? "form-control"
              : touched[field.name] && errors[field.name]
              ? "form-control is-invalid"
              : "form-control is-valid"
          }
        />

        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
};

export default PhoneNumber;
