import React, { useContext } from "react";

import { Link } from 'react-router-dom';

//import Typography from "@material-ui/core/Typography";

import { toAbsoluteUrl } from '../../_metronic/_helpers/index';
import { RoleEnum } from '../../config/roles';
import { useHasRoles } from '../../utils/customHooks';
import { configContext } from "../../provider/config/ConfigProvider";
import SVG from "react-inlinesvg";

const Help = ({isWholePage}) => {
  const hasSiteRoles = useHasRoles([RoleEnum.SiteAdmin]);
  const hasProctorRoles = useHasRoles([RoleEnum.UserAdmin]);
  const config = useContext(configContext);

  return (
    <>
      { isWholePage ? (
        <div className="d-flex flex-column flex-root full-height">
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
            id="kt_login"
          >
            <div
              className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/auth-bg.jpg")})`,
              }}
            >
              <div className="d-flex flex-row-fluid flex-column justify-content-center">
                <Link to="/" className="flex-column-auto mt-5">
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl(config.branding.site_logo)}
                  />
                </Link>
              </div>
            </div>
    
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              <h2>Welcome to RDC Management Portal</h2>
              <h3>Sites</h3>
              <p>Sites allows you to create, edit and archive site locations.</p>
              <h3>Users</h3>
              <p>The Users section allows you to create new users. You can assign permissions to utilize the app or the management portal via Users.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <h2>Welcome to RDC Management Portal</h2>
          {
            hasSiteRoles ? (
              <>
                <h3>Sites</h3>
                <p>Sites allows you to create, edit and archive site locations.</p>
              </>
            ) : null
          }

          {
            hasProctorRoles ? (
              <>
                <h3>Users</h3>
                <p>The Users section allows you to create new users. You can assign permissions to utilize the app or the management portal via Users.</p>
              </>
            ) : null
          }

          <h4>
            <a
              href="https://data.texasrdc.org/"
              className={'btn btn-light-primary btn-shadow-hover font-weight-bold font-size-h4 px-12 py-5'}
            >
              <span className="svg-icon svg-icon-50 ml-0">
                <SVG
                  src={toAbsoluteUrl(`/media/svg/icons/Design/${"Layers.svg"}`)}
                />
              </span>
              Tableau Dashboard</a>
          </h4>
        </div>
      )}
    </>
  )
};

export default Help;
