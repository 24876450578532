import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Select from "../../components/common/form/Select";
import File from "../../components/common/form/File";
import useIsMounted from "../../hooks/useIsMounted";
import { getSpreadsheetConfig } from "../../services/uploads";
import SubmitListener from "../common/SubmitListener";
import SearchableSelect from "../common/form/SearchableSelect";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import CountySingleValue from "../address/CountySingleValue";
import CountyOption from "../address/CountyOption";

const SUPPORTED_FORMATS = [
  'text/csv',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

const FILE_SIZE = 100000000; // 100 MB

const uploadSchema = {
  spreadsheet_config: Yup.string().required('Spreadsheet Config is required'),
  file: Yup.mixed()
    .test('fileSize', "File Size is too large", value => !value || value.size <= FILE_SIZE)
    .test('fileType', "Unsupported File Format", value => !value || SUPPORTED_FORMATS.includes(value.type))
    .required("File is required.")
};

const UploadForm = (props) => {
  const { handleSubmit, formLabel } = props;
  const isMounted = useIsMounted();
  const [spreadsheetConfigOptions, setSpreadsheetConfigOptions] = useState([]);
  const [UploadSchema, setUploadSchema] = useState(Yup.object().shape(uploadSchema));
  useEffect(() => {
    const newUploadSchema = {};
    Object.assign(newUploadSchema, uploadSchema)
    newUploadSchema.spreadsheet_config = Yup.string()
      .test('option', "Invalid option", value => !value || spreadsheetConfigOptions.find(option => option.value === value))
      .required('Spreadsheet Config is required')
    setUploadSchema(Yup.object().shape(newUploadSchema));
  }, [spreadsheetConfigOptions, setUploadSchema]);


  useEffect(() => {
    refresh();
  }, []);

  const refresh = useCallback(() => {
    getSpreadsheetConfig().then((results) => {
      if (isMounted()) setSpreadsheetConfigOptions(results.data);
    }).catch((err) => {
      console.log(err)
    })
  }, [setSpreadsheetConfigOptions])

  const counties = useAsyncSelect({
    url: '/address/county',
    key: 'county',
    searchTerm: 'county',
  });

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        spreadsheet_config: "",
        file: "",
        county: "bexar"
      }}
      validationSchema={UploadSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {(formik) => (
        <>
          <Modal.Body>
            {formLabel ? <Typography variant="h6" id="subtitle1">
              {formLabel}
            </Typography> : null }
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6 mt-6">
                  <Field
                    name="spreadsheet_config"
                    component={Select}
                    placeholder="Choose one..."
                    label="Spreadsheet Config"
                    options={spreadsheetConfigOptions}
                  />
                </div>
                <div className="col-sm-3 mt-6">
                  <Field
                    component={SearchableSelect}
                    name={'county'}
                    label="County"
                    //defaultValue={userRole.site_id}
                    defaultOptions={counties.options}
                    loadOptions={counties.loadOptions}
                    getOptionLabel={option => option.county}
                    getOptionValue={option => option.county}
                    loadValue={counties.loadValue}
                    components={{Option: CountyOption, SingleValue: CountySingleValue}}
                    //filterOption={filterOption}
                  />
                </div>

                <div className="col-lg-6 mt-6">
                  <Field
                    name="file"
                    component={File}
                    placeholder="Select file..."
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <SubmitListener formik={formik}/>
        </>
      )}
    </Formik>
  );
};

UploadForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formLabel: PropTypes.node
}

export default UploadForm;
