import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import useIsMounted from '../../../hooks/useIsMounted';
import useQueryParamStore from '../../../hooks/useQueryParamStore';
import useApiService from '../../../hooks/useApiService';
import DataTableHeader from '../../../components/common/datatable/DataTableHeader';
import { Button, Card, Col } from 'react-bootstrap';
import GroupDataTable from '../../../components/tableau/group/GroupDataTable';
import SearchBar from 'material-ui-search-bar';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const PAGE_NAME = 'dashboardGroup';

export default function GroupTablePage () {
  const history = useHistory();
  const urlRows = '/dashboard/group';
  const isMounted = useIsMounted();
  const queryParams = useQueryParamStore(PAGE_NAME, 'SET_DASHBOARD_GROUP_QP');
  const fetchRows = useApiService({url: urlRows, queryString: queryParams.queryString});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!searchString) {
      queryParams.setSearchOptions({});
    } else {
      queryParams.setSearchOption('searchString', searchString);
    }
  }, [searchString, queryParams.setSearchOptions, queryParams.setSearchOption])


  const fetchData = useCallback(() => {
    setLoading(true);
    fetchRows().then((response) => {
      let {results, total_rows} = response.data.groups;
      if (isMounted()) {
        setTotalRows(total_rows);
        setRows(results);
      }
    }).catch((e) => {
      const {response} = e;
      let errors = response.data.errors;
      if (typeof errors !== 'object') {
        errors = {};
      }
      if (response.status === 400) {
        errors.table = "Additional filter required.";
      } else {
        errors.table = "Unable to load entries.";
      }
      setError(errors);
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, setTotalRows, setRows, queryParams.queryString, setLoading, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickRow = useCallback((row) => {
    history.push('/dashboard/group/' + row.dashboard_group_id);
  }, [history]);

  return <div>
    <Card className={'w-100'}>
      <DataTableHeader label={"Dashboard Groups"} >
        <div className="col-lg-4 mt-6">
          <SearchBar
            value={searchString}
            onRequestSearch={setSearchString}
            onCancelSearch={setSearchString}
            //className={classes.searchBar}
            placeholder={"Search by Group Name"}
          />
        </div>
        <Col/>
        <div className="col-lg-2 mt-6">
          <Link to={'/dashboard/group/create'}>
            <Button
              block
              color="secondary"
              //startIcon={<AddIcon />}
            >
							<span className="svg-icon">
								<SVG
                  src={toAbsoluteUrl(`/media/svg/icons/Code/${"Plus.svg"}`)}
                />
							</span>
              Create
            </Button>
          </Link>
        </div>
      </DataTableHeader>
      <GroupDataTable
        rows={rows}
        replaceRow={() => {}}
        queryParams={queryParams}
        totalRows={totalRows}
        loading={loading}
        errorMessage={error.table}
        handleClickRow={handleClickRow}
      />
    </Card>
  </div>
}
