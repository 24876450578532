import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import FormConfigSitesForm from '../../../components/multiForm/schema/FormConfigSitesForm';
import FormConfigPreview from '../../../components/multiForm/schema/FormConfigPreview';
import NavRouteWidget from '../../../components/common/partial/widget/NavRouteWidget';
import useApiService from '../../../hooks/useApiService';
import useAxiosResponse from '../../../hooks/useAxiosResponse';
import WidgetCard from '../../../components/common/partial/widget/WidgetCard';
import { Button } from 'react-bootstrap';

export default function FormConfigEditPage () {
  const {name} = useParams();
  const match = useRouteMatch();

  const fetchSchema = useApiService({
    url: `/multiform/schema/${name}`,
  });
  const [responseSchema, retrySchema] = useAxiosResponse(fetchSchema, true);
  const items = [{
    value: '',
    label: 'Assign Sites',
    content: <FormConfigSitesForm name={name} title={((responseSchema.response || {}).jsonSchema || {}).description}/>
  },{
    value: 'preview',
    label: 'Preview',
    content: <FormConfigPreview name={name} responseSchema={responseSchema}/>
  }]

  if (!responseSchema.loading && responseSchema.status !== 200) {
    return <WidgetCard className={'bg-light-danger text-danger'}>
      {(responseSchema.response || {}).message || 'An error occurred when loading the form.'}
      <Button
        className={'btn-outline-danger ml-2'}
        onClick={retrySchema}
      >
        Retry
      </Button>
    </WidgetCard>
  }

  return <div>
    <NavRouteWidget items={items} basePath={match.url}/>
  </div>;
}
