import { Col, Row } from 'react-bootstrap';
import React from 'react';

export default function SiteNameCell ({row, classNameAbbreviation}) {
  const {
    legal_name,
    common_name,
    abbreviation,
  } = row;

  return <>
    <Row>
      <Col xs={'auto'}>
        <Row>
          <Col className={'pr-0 text-truncate'}>
            {common_name || legal_name}
          </Col>
          { abbreviation ?
            <Col xs={'auto'}>
              <span className={`label label-inline ${classNameAbbreviation || ''}`}>
                {abbreviation}
              </span>
            </Col> : null }
        </Row>
      </Col>

      <Col/>
    </Row>
  </>
}
