import React, { useState, useContext } from "react";
import { firebaseAuth } from "../../provider/auth/AuthProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { nameRegExp, phoneRegExp } from "../validations/regexp";
import InputMask from 'react-input-mask';
import { signUp } from "../../services/auth";
import { useHistory } from "react-router-dom";
import RegistrationSuccessDialog from "./register-dialog";
import SiteMultiSelect from "./SiteMultiSelect";
import RACFilterSelect from "./RACFilterSelect";
import SiteTitleSelect from "./SiteTitleSelect";

const SignUp = () => {
  //const { intl } = props;
  const history = useHistory();
  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    siteList: [],
    title: ""
  };

  const {authUser} = useContext(firebaseAuth);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(1, "Minimum 1 symbol")
      .max(250, "Maximum 250 symbols")
      .required("First Name is required")
      .matches(nameRegExp, "Invalid name"),
    last_name: Yup.string()
      .min(1, "Minimum 1 symbol")
      .max(250, "Maximum 250 symbols")
      .required("Last Name is required")
      .matches(nameRegExp, "Invalid name"),
    phone: Yup.string()
      .matches(phoneRegExp, "Invalid phone number"),
      //.required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    siteList: Yup.array().of(Yup.number().integer()).required("Must select a site")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(true);
      setError([]);
      signUp(values).then((response) => {
        setOpen(true);
      }).catch((e) => {
        let {response} = e;
        response = response || {};
        console.log(response.data)
        if ([400, 409].includes(response.status)) {
          actions.setErrors(response.data.errors);
          setError([response.data.message]);
        } else {
          setError(['An unexpected error occurred. Please try again.']);
        }
      }).finally(() => {
        setLoading(false);
        actions.setSubmitting(false);
      })
    },
  });

  if (authUser) {
    /*return <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        You are already signed in.
      </div>
    </div>*/
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <RegistrationSuccessDialog
        open={open}
        handleClose={() => {
          history.push("/");
          setOpen(false);
        }}
      />
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          {" "}
          Enter your information
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "first_name"
            )}`}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "last_name"
            )}`}
            name="last_name"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <InputMask
            mask={'999-999-9999'}
            {...formik.getFieldProps("phone")}
          >
            <input
              placeholder="Phone"
              type="tel"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "phone"
              )}`}
              name="phone"
            />
          </InputMask>
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <div className="text-center mt-10 mb-10 mb-lg-20">
            <h6 className="font-size-h6">
              Choose a site.
            </h6>
            <p className="text-muted font-weight-bold">
              {" "}
              Contact your administrator if you need more than one.
            </p>
          </div>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <RACFilterSelect
            {...formik.getFieldProps("racFilter")}
            formik={formik}
          />
        </div>
        <div className="form-group fv-plugins-icon-container">
          <SiteMultiSelect
            {...formik.getFieldProps("siteList")}
            formik={formik}
          />
          {formik.touched.siteList && formik.errors.siteList ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.siteList}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <SiteTitleSelect
            {...formik.getFieldProps("title")}
            formik={formik}
          />
          {formik.touched.title && formik.errors.title ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.title}</div>
            </div>
          ) : null}
        </div>

        {/*<div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>*/}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/sign-in">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>

        {error
          ? <p style={{ color: "red" }}>{error}</p>
          : null}
      </form>
    </div>
  );
};

export default injectIntl(SignUp);
