import React, { useContext } from "react";
import { firebaseAuth } from "../../provider/auth/AuthProvider";

const Error = () => {
  const { handleSignout } = useContext(firebaseAuth);

  handleSignout('Something went wrong! Please login again.');

  return (
    <></>
  );
};

export default Error;
