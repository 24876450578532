import {components} from "react-select";
import React from "react";

const CountyOption = props => {
  return <components.Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.county}
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.state ? <small className="text-muted"> | {props.data.state}</small> : null}
  </components.Option>
}

export default CountyOption;
