import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SiteForm from '../../components/site/SiteForm';

import SiteService from "../../services/site";
import { useNotification } from '../../utils/customHooks';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	}
}));

const CreateSite = () => {
	const history = useHistory();
	const classes = useStyles();
	const setNotification = useNotification();

	const createSite = async (site) => {
		let res = await SiteService.addSite(site);
	
		if(res && res.status === 200) {
			setNotification({
				type: "success",
				message: "Site record has been created"
			});
			history.push('/sites');
		} else {
			setNotification({
				type: "error",
				message: "Failed to create site! Please check and try again!"
			});
		}
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<SiteForm formLabel={"Create Site"} saveSite={createSite} />
			</Paper>
		</div>
	);
};

export default CreateSite;
