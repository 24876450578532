import React from 'react';
import App from './App';
import reportWebVitals from './tools/reportWebVitals';

import AuthProvider from './provider/auth/AuthProvider';

import "./styles/index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
import ErrorBoundary from './components/common/ErrorBoundary';
import ConfigProvider from "./provider/config/ConfigProvider";
import { StateProvider, initialState } from "./store/StateProvider";
import reducer from "./store/reducer";
import CustomBrowserRouter from "./components/common/CustomBrowserRouter";
import { createRoot } from "react-dom/client";
import LoggerProvider from "./provider/logger/LoggerProvider";

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
	<MetronicI18nProvider>
		<MetronicLayoutProvider>
			<MetronicSubheaderProvider>
				<MetronicSplashScreenProvider>
					<StateProvider initialState={initialState} reducer={reducer}>
						<ConfigProvider>
							<CustomBrowserRouter>
								<LoggerProvider>
									<AuthProvider>
										<ErrorBoundary>
											<App />
										</ErrorBoundary>
									</AuthProvider>
								</LoggerProvider>
							</CustomBrowserRouter>
						</ConfigProvider>
					</StateProvider>
				</MetronicSplashScreenProvider>
			</MetronicSubheaderProvider>
		</MetronicLayoutProvider>
	</MetronicI18nProvider>
);

reportWebVitals();
