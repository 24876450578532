import React, { useMemo } from 'react';
import DataTable from '../../common/datatable/DataTable';

const GroupDataTable = (props) => {
  const {
    rows,
    replaceRow,
    totalRows,
    queryParams,
    errorMessage,
    loading,
    retry,
    handleClickRow
  } = props;

  const headCols = useMemo(() => {
    return [
      {
        id: 'dashboard_group',
        label: 'Dashboard Group',
        sortable: true,
        width: '400px'
      },
      {
        id: 'sites_assigned',
        label: 'Sites Assigned',
        sortable: false,
      }
    ]
  }, [replaceRow]);

  return <DataTable
    headCols={headCols}
    idName={'dashboard_group_id'}
    queryParams={queryParams}
    rows={rows}
    totalRows={totalRows}
    errorMessage={errorMessage}
    loading={loading}
    retry={retry}
    handleClickRow={handleClickRow}
  />
}

export default GroupDataTable;
