import React, { useCallback, useMemo, useRef } from "react";
import UploadService from "../../../../services/uploads";
import useInterval from "../../../../hooks/useInterval";
import useIsMounted from "../../../../hooks/useIsMounted";
import { Col, Form, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import { convertServerTimeString } from "../../../../utils/helpers";
import date from "date-and-time";

const useUTC = false;

function UploadProgressCell ({row, replaceRow}) {
  const {
    total,
    progress,
    created,
    updated,
    error_rows,
    error_total,
  } = row;

  const errorText = useCallback((limit) => {
    if (error_total && error_rows) {
      const list = error_rows.split(',').map(rowNumber => parseInt(rowNumber) + 1);
      if (!limit) limit = list.length;
      if (list.length > limit) {
        return `${list.slice(0,limit).join(',')} and ${list.length - limit} more`;
      } else if (list.length > 1) {
        return `${list.slice(0,list.length - 1).join(',')} and ${list[list.length - 1]}`;
      } else {
        return `${list[0]}`;
      }
    }
    return '';
  }, [error_rows, error_total]);

  const loading = useRef(false);
  const isMounted = useIsMounted();

  const eta = () => {
    const rowTime = averageInsertTime();
    return rowTime * (total - progress);
  }

  const averageInsertTime = () => {
    try {
      const updated_at = convertServerTimeString(updated, useUTC);
      const created_at = convertServerTimeString(created, useUTC);
      const runningTime = date.subtract(updated_at, created_at).toSeconds();
      return runningTime / progress;
    } catch {
      return Math.Infinity;
    }
  }

  const countdownText = () => {
    if (inProgress()) {
      const seconds = eta();
      if (seconds < 180) {
        return Math.round(seconds) + ' seconds\xa0remain.';
      } else {
        return `${Math.floor(seconds / 60)} minutes ${Math.floor(seconds % 60)} seconds\xa0remain.`;
      }
    }
    return '';
  }

  const isStuck = useCallback(() => {
    const now = new Date();
    if (progress === 0) {
      const created_at = convertServerTimeString(created, useUTC);
      const subtract = date.subtract(now, created_at);
      return subtract.toMinutes() > 1;
    } else if (progress === total) {
      return false;
    } else {
      const updated_at = convertServerTimeString(updated, useUTC);
      if (!updated_at) return false;
      const subtract = date.subtract(now, updated_at);
      const rowTime = averageInsertTime();
      return subtract.toSeconds() > Math.max(rowTime * 10, 60);
    }
  }, [progress, total]);

  const inProgress = () => {
    return !(progress === total || isStuck());
  }

  const refresh = useCallback(() => {
    if (loading.current || !isMounted()) return;
    if (progress === total || isStuck()) {
      return;
    }

    loading.current = true;
    UploadService.uploadProgress(row).then((result) => {
      replaceRow(result.data);
    }).finally(() => {
      loading.current = false;
    })
  }, [loading, isMounted, isStuck, progress, total, replaceRow]);

  useInterval(refresh, 700);

  return <>
    <Row>
      <Col>
        <ProgressBar

          style={{
            //borderTopLeftRadius: 0,
            //borderTopRightRadius: 0
          }}
          variant={isStuck() ? 'warning' : progress === total ? 'success' : 'primary' }
          now={isStuck() && progress === 0 ? 100 : (progress/total) * 100}
          label={isStuck() ? 'stuck' : `${Math.floor(progress/total * 100)} %`}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={'auto'}>
        <Form.Text muted>
          {`${progress}/${total}`}
        </Form.Text>
      </Col>
      <Col>
        {error_rows ? <Row>
          <Col xs={'auto'} style={{
            minWidth: '100%',
            width: 0
          }}>
            <OverlayTrigger
              placement={'auto-end'}
              overlay={(props) =>
                <Tooltip {...props} id={`tooltop-progress-error-${row.id}`} >
                  {errorText(0)}
                </Tooltip>
              }
            >
             <span
               className={'label label-inline label-light-warning font-weight-bold text-truncate justify-content-start'}
               style={{
                 maxWidth: '100%',
               }}
             >
              Rows skipped (from first data row): {errorText(10)}
            </span>
            </OverlayTrigger>
          </Col>
        </Row> : null}
      </Col>
      <Col xs={'auto'}>
        <Form.Text muted>
          {countdownText()}
        </Form.Text>
      </Col>
    </Row>
  </>

}

export default UploadProgressCell
