import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "10px",
    backgroundColor: "#e4e4e4",
    maxHeight: "400px",
    overflowY: "scroll",
    borderRadius: "3px"
  },
  dialogFooter: {
    padding: "20px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PasswordResetSuccessDialog = withStyles(styles)((props) => {
  const { classes, open, handleClose } = props;

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <img
            alt={''}
            className="max-h-50px"
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
          />
          Success
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <Typography gutterBottom>
              Password reset successful. Please log in with your new password.
            </Typography>
          </div>
        </DialogContent>
        <div className={classes.dialogFooter}>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={() => {
              handleClose();
            }} color="secondary">
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

export default PasswordResetSuccessDialog;
