import React, { useCallback } from "react";
import ReactSelect, { components } from "react-select";
import SolidControl from "../common/form/react-select/SolidControl";
import useApiService from "../../hooks/useApiService";
import useAxiosResponse from "../../hooks/useAxiosResponse";

const AbbreviationOption = props => {
  return <components.Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.title}
    {/* eslint-disable-next-line react/prop-types */}
    {
      props.data.title_abbreviation
        ? <small className="text-muted"> | {props.data.title_abbreviation}</small>
        : null
    }
  </components.Option>
}

export default function SiteTitleSelect (props) {
  const { name, value, formik } = props;
  const { setFieldValue } = formik;

  const fetchTitles = useApiService({
    url: '/auth/title',
    key: 'user_title_id',
  });
  const [{response: titles}] = useAxiosResponse(fetchTitles);

  const handleChange = useCallback((option) => {
    setFieldValue(name, (option || {}).user_title_id || null);
  }, [name, setFieldValue]);

  const selectValue = (titles || []).find((option) => option.user_title_id === value);

  return <ReactSelect
    name={name}
    placeholder={'(Optional) Select Position Title'}
    //isMulti
    //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    getOptionLabel={option => option.title}
    getOptionValue={option => option.user_title_id}
    options={titles}
    onChange={handleChange}
    isClearable={true}
    value={selectValue || ''}
    menuPlacement={'auto'}
    components={{ Control: SolidControl, Option: AbbreviationOption }}
  />
}
