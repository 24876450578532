import React, { useCallback, useEffect, useState } from "react";
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useApiService from "../../hooks/useApiService";
import useIsMounted from "../../hooks/useIsMounted";
import { Col, Card, Button } from "react-bootstrap";
import DataTableHeader from "../../components/common/datatable/DataTableHeader";
import StrokeDataTable from "../../components/stroke/datatable/StrokeDataTable";
import { Link, useHistory } from "react-router-dom";
import SiteSelect from "../../components/stroke/SiteSelect";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import useAxiosResponse from "../../hooks/useAxiosResponse";
import ConfigSelect from '../../components/stroke/ConfigSelect';

const PAGE_NAME = 'stroke';

export default function StrokeTablePage () {
  const history = useHistory();
  const urlRows = '/multiform/stroke/form/list';
  const urlValidate = '/multiform/stroke/form/';
  const isMounted = useIsMounted();
  const queryParams = useQueryParamStore(PAGE_NAME, 'SET_STROKE_QP', ['site_id', 'config_id']);
  const fetchRows = useApiService({url: urlRows, queryString: queryParams.queryString});
  const fetchValidate = useApiService({url: urlValidate, queryString: queryParams.queryString});
  const [responseValidate] = useAxiosResponse(fetchValidate);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const hasError = () => Object.keys(error).length > 0;

  useEffect(() => {
    if (responseValidate.status === 400 && !responseValidate.loading) {
      setError(responseValidate.response);
    } else {
      setError({});
    }
  }, [responseValidate.response, responseValidate.status, responseValidate.loading])

  const fetchData = useCallback(() => {
    setLoading(true);
    fetchRows().then((response) => {
      let {results, total_rows} = response.data;
      if (isMounted()) {
        setTotalRows(total_rows);
        setRows(results);
      }
    }).catch((e) => {
      const {response} = e;
      let errors = response.data.errors;
      if (typeof errors !== 'object') {
        errors = {};
      }
      if (response.status === 400) {
        errors.table = "Additional filter required.";
      } else {
        errors.table = "Unable to load entries.";
      }
      setError(errors);
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, setTotalRows, setRows, queryParams.queryString, setLoading, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickRow = useCallback((row) => {
    history.push('/stroke/form/edit/' + row.id);
  }, [history]);

  return <div>
    <Card className={'w-100'}>
      <DataTableHeader
        label={"Stroke"}
      >
        <Col/>
        <Col xs={'3'} className={'mt-6'}>
          <ConfigSelect url={'/multiform/stroke/form/configs'} hasError={!!error.config_id}/>
        </Col>
        <Col xs={'3'} className={'mt-6'}>
          <SiteSelect url={'/multiform/stroke/form/sites'} hasError={!!error.site_id}/>
        </Col>
        <Col xs={'2'} className="mt-6">
          <Link to={'/stroke/form/create/'}>
            <Button
              block
              color="secondary"
              disabled={hasError()}
              //startIcon={<AddIcon />}
            >
								<span className="svg-icon">
									<SVG
                    src={toAbsoluteUrl(`/media/svg/icons/Code/${"Plus.svg"}`)}
                  />
								</span>
              Create
            </Button>
          </Link>
        </Col>
      </DataTableHeader>
      <StrokeDataTable
        rows={rows}
        replaceRow={() => {}}
        queryParams={queryParams}
        totalRows={totalRows}
        loading={loading}
        errorMessage={error.table}
        handleClickRow={handleClickRow}
      />
    </Card>
  </div>;
}