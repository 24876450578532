import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  invalid: {
    border: "1px solid #F64E60",
    borderRadius: "5px"
  }
}));

export default function SiteSelect ({url, hasError}) {
  const classes = useStyles();
  const urlRows = url;
  const queryParams = useQueryParamStore('stroke', 'SET_STROKE_QP', ['site_id', 'config_id']);
  const sites = useAsyncSelect({
    url: urlRows,
    key: 'site_id',
    searchTerm: 'name',
    rowsPerPage: 150
    //filters: queryParams.searchOptions
  });

  const [site, setSite] = useState(null);

  useEffect(() => {
    const site_id = queryParams.searchOptions.site_id;
    setSite((prev) => {
      if (`${site_id || ''}` === `${(prev || {}).site_id || ''}`) return prev;
      return sites.options.find((site) => {
        return `${site_id}` === `${site.site_id}`
      })
    });
  }, [setSite, queryParams.searchOptions.site_id, sites.options])


  const handleChange = useCallback((site) => {
    const site_id = (site || {}).site_id;
    queryParams.setSearchOption('site_id', site_id);
  }, [queryParams.setSearchOption]);

  if (sites.options.length < 2) return null;
  return <AsyncSelect
    cacheOptions={false}
    placeholder={'Choose which site to submit from...'}
    className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    options={sites.options}
    loadOptions={sites.loadOptions}
    getOptionLabel={option => option.common_name || option.name}
    getOptionValue={option => option.site_id}
    loadValue={sites.loadValue}
    defaultOptions={true}
    onChange={handleChange}
    isClearable={true}
    value={site}
    isOptionDisabled={option => {
      const config_id = queryParams.searchOptions.config_id;
      if (config_id && Array.isArray(option.config_id) && option.config_id.findIndex((option) => {
        return `${config_id}` === `${option}`
      }) === -1) {
        return true;
      }
      return option.isDisabled
    }}
  />
}