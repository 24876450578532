import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useApiService from '../../../hooks/useApiService';
import useAxiosResponse from '../../../hooks/useAxiosResponse';
import { Field, Form, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import FormButtonRow from '../../../components/multiForm/form/FormButtonRow';
import * as Yup from 'yup';
import WidgetCard from '../../../components/common/partial/widget/WidgetCard';
import { Input } from '../../../_metronic/_partials/controls';
import useAsyncSelect from '../../../hooks/useAsyncSelect';
import GroupSiteField from '../../../components/tableau/group/GroupSiteField';
import Select from '../../../components/common/form/Select';

export default function GroupFormPage () {
  const defaultValues = {
    dashboard_group: "",
    dashboard_level_code: "",
  };

  const {id} = useParams();
  const history = useHistory();
  const formUrl = `/dashboard/group${id ? '/' + id : ''}`;
  const levelUrl = '/dashboard/group/level';
  const saveMethod = id ? 'patch' : 'post';
  const [formData, setFormData] = useState(defaultValues);
  const loading = useRef(false);
  const [loadingState, setLoadingState] = useState(false);

  const filters = useMemo(() => {
    return {
      exclude: (formData || {}).site_id || [],
    }
  }, [formData]);

  const sites = useAsyncSelect({
    url: '/dashboard/group/site',
    key: 'site_id',
    searchTerm: 'name',
    filters: filters
  });

  const sitesWithChildren = useMemo(() => {
    if ((!sites || !sites.options) || (!formData || formData.dashboard_group !== 'Stroke-RDC-All Facilities')) {
      return undefined
    }
    return sites.options.filter(({dashboard_level_code}) => {
      return dashboard_level_code === 'RAC'
    })
  }, [sites, sites.options])

  const fetchLevels = useApiService({ url: levelUrl });
  const [{response: dashboardLevels}] = useAxiosResponse(fetchLevels);

  const formService = useApiService({url: formUrl});

  const load = useCallback(() => {
    if (loading.current) return;
    loading.current = true;
    setLoadingState(true);
    formService().then((response) => {
      setFormData(response.data);
      sites.updateRows(response.data.sites);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      loading.current = false;
      setLoadingState(false);
    });
  }, [formService, setFormData, loading, setLoadingState, sites.updateRows]);

  useEffect(() => {
    if (id) load();
  }, [id, load]);

  const save = useCallback((values) => {
    if (loading.current) return;
    loading.current = true;
    setLoadingState(true);
    const {
      dashboard_group_id,
      dashboard_group,
      dashboard_level_code,
      site_id,
    } = values;
    formService({
      method: saveMethod,
      data: {
        dashboard_group_id,
        dashboard_group,
        dashboard_level_code,
        site_id,
      }
    }).then((response) => {
      const {data} = response;
      const formData = data;
      setFormData(formData);
      if (formData.dashboard_group_id && !id) {
        history.push(`/dashboard/group/${formData.dashboard_group_id}`);
      } else {
        history.push('/dashboard/group');
      }
    }).catch((e) => {
      console.error(e.response);
    }).finally(() => {
      loading.current = false;
      setLoadingState(false);
    });
  }, [formService, loading, history]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const groupFormSchema = useMemo(() => {
    const dashboard_level_code = (dashboardLevels || []).map((item) => {
      return item.dashboard_level_code;
    });
    return Yup.object().shape({
      dashboard_group: Yup.string().required("Dashboard Group Name is required"),
      dashboard_level_code: dashboard_level_code.length > 0
        ? Yup.mixed().oneOf(dashboard_level_code)
        : Yup.string().required("Dashboard Level is required"),
    });
  }, [dashboardLevels]);

  return (
    <WidgetCard>
      <Formik
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={groupFormSchema}
        onSubmit={save}
      >
        {({ handleSubmit }) => (
          <>
            <Typography variant="h6" id="subtitle1">
              {id ? 'Edit' : 'Create'} Group
            </Typography>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6 mt-6">
                  <Field
                    name="dashboard_group"
                    component={Input}
                    placeholder="Group Name"
                    label="Group Name"
                    autoComplete="off"
                    readOnly={!!id}
                    disabled={!!id}
                  />
                </div>
                <div className="col-lg-6 mt-6">
                  <Field
                    name="dashboard_level_code"
                    component={Select}
                    placeholder="Group Level"
                    label="Group Level"
                    autoComplete="off"
                    options={dashboardLevels}
                    getOptionLabel={(option) => option.dashboard_level}
                    getOptionValue={(option) => option.dashboard_level_code}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12 mt-6">
                  <Field
                    label="Group Sites"
                    name={'site_id'}
                    component={GroupSiteField}
                    sites={sites}
                    dashboardLevels={dashboardLevels}
                    sitesWithChildren={sitesWithChildren}
                  />
                </div>
              </div>
            </Form>
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} loading={loadingState}/>
          </>
        )}
      </Formik>
    </WidgetCard>
  );
}