import { useState, useCallback, useMemo, useEffect } from "react";
import { asNumber, FieldProps, FormContextType, RJSFSchema, StrictRJSFSchema } from '@rjsf/utils';
import diff from 'fast-diff';
import usePrevious from "../../../hooks/usePrevious";

// Matches a string that ends in a . character, optionally followed by a sequence of
// digits followed by any number of 0 characters up until the end of the line.
// Ensuring that there is at least one prefixed character is important so that
// you don't incorrectly match against "0".
const trailingCharMatcherWithPrefix = /\.([0-9]*0)*$/;

// This is used for trimming the trailing 0 and . characters without affecting
// the rest of the string. Its possible to use one RegEx with groups for this
// functionality, but it is fairly complex compared to simply defining two
// different matchers.
const trailingCharMatcher = /[0.]0*$/;

/**
 * The NumberField class has some special handling for dealing with trailing
 * decimal points and/or zeroes. This logic is designed to allow trailing values
 * to be visible in the input element, but not be represented in the
 * corresponding form data.
 *
 * The algorithm is as follows:
 *
 * 1. When the input value changes the value is cached in the component state
 *
 * 2. The value is then normalized, removing trailing decimal points and zeros,
 *    then passed to the "onChange" callback
 *
 * 3. When the component is rendered, the formData value is checked against the
 *    value cached in the state. If it matches the cached value, the cached
 *    value is passed to the input instead of the formData value
 */
function AltNumberField<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: FieldProps<T, S, F>
) {
  const { registry, onChange, formData, value: initialValue, schema } = props;
  const {type, multipleOf} = schema;
  let allowDecimal = true;
  if (type === 'integer' || Array.isArray(type) && type.includes('integer')) {
    allowDecimal = false;
  }
  if (!multipleOf || multipleOf % 1 === 0) {
    allowDecimal = false;
  }
  const [lastValue, setLastValue] = useState<any>(initialValue);
  const { StringField } = registry.fields;

  /** Handle the change from the `StringField` to properly convert to a number
   *
   * @param value - The current value for the change occurring
   */
  const handleChange = useCallback(
    (value: FieldProps<T, S, F>['value']) => {
      // Cache the original value in component state
      const changeLastValue = new Promise<string>((res) => {
        setLastValue((prev: any) => {
          const valueStr = value ? `${value}` : '';
          const prevStr = prev ? `${prev}` : ''
          let maskedValue = '';
          // find the character that has been newly added to the prev string in any location
          const differences = diff(prevStr, valueStr
          ).filter(([method, str]) => {
            if (method === -1) {
              return false;
            } else if (method === 1) {
              // check if the string contains only digits or a period
              if (allowDecimal) {
                return str.match(/^[0-9.]+$/);
              }
              return str.match(/^[0-9]+$/);
            }
            return true;
          });
          let alreadyDecimal = !allowDecimal;
          for (const [method, str] of differences) {
            if (str.includes('.')) {
              if (alreadyDecimal) {
                continue;
              }
              alreadyDecimal = true;
            }
            if (maskedValue.length === 0 && str.startsWith('.') && method === 1) {
              maskedValue += '0';
            }
            maskedValue += str;
          }
          res(maskedValue);
          //console.log(prev, value, maskedValue);
          return maskedValue;
        });
      });

      changeLastValue.then((value) => {
        // Normalize decimals that don't start with a zero character in advance so
        // that the rest of the normalization logic is simpler
        if (`${value}`.charAt(0) === '.') {
          value = `0${value}`;
        }

        // Check that the value is a string (this can happen if the widget used is a
        // <select>, due to an enum declaration etc) then, if the value ends in a
        // trailing decimal point or multiple zeroes, strip the trailing values
        const processed =
          typeof value === 'string' && value.match(trailingCharMatcherWithPrefix)
            ? asNumber(value.replace(trailingCharMatcher, ''))
            : asNumber(value);

        onChange(processed as unknown as T);
      });

    },
    [onChange, setLastValue]
  );

  const prevFormData = usePrevious(formData);
  useEffect(() => {
    if (prevFormData !== formData && typeof formData === 'string') {
      // If the formData value changes externally, update the cached value
      handleChange(formData);
    } else if (typeof lastValue === 'undefined' && typeof formData === 'number') {
      // If the field is remounted, load the existing value
      setLastValue(formData);
    }
  }, [prevFormData, formData, handleChange, setLastValue, lastValue]);
  if (props.schema.type === 'null') return null;
  return <StringField {...props} formData={lastValue} onChange={handleChange} />;
}

export default AltNumberField;
