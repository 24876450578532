import React, { useCallback, useEffect, useState } from 'react';
import useApiService from '../../hooks/useApiService';
import { serializeError } from 'serialize-error';
import { v4 as uuidv4 } from 'uuid';

export const loggerContext = React.createContext();

export default function LoggerProvider ({children}) {
  const ClientEventEnum = {
    AuthLogin: 'auth.user.login',
    AuthLogout: 'auth.user.logout',
    StrokeFormLoadFailed: 'stroke.form.load.failed',
    StrokeFormLoadSuccess: 'stroke.form.load.success',
    ErrorBoundary: 'boundary.error.unhandled',
    ClientLoaded: 'system.client.loaded',
  }

  const [clientUuid] = useState(uuidv4());

  const submit = useApiService({url: '/log', method: 'post'});

  const log = useCallback((data, eventName) => {
    //const token = localStorage.getItem("token");
    const event = ClientEventEnum[eventName] || eventName;
    if (typeof data !== 'object') {
      data = { eventValue: data };
    }
    const payload = serializeError(data);
    payload.eventName = event;
    payload.clientUuid = clientUuid;
    return submit({
      data: payload
    }).catch(console.error);
  }, [submit, ClientEventEnum, clientUuid]);

  useEffect(() => {
    log({}, ClientEventEnum.ClientLoaded);
  }, [log]);

  return <loggerContext.Provider value={log}>
    {children}
  </loggerContext.Provider>
}
