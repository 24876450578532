export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

// export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;
///*
export const toAbsoluteUrl = (pathname) => {
    let baseUrl = localStorage.getItem('baseUrl') || process.env.REACT_APP_CLIENT_BASE_URL || '/';
    if (baseUrl === "%REACT_APP_CLIENT_BASE_URL%") baseUrl = '/';
    if (typeof pathname !== "string" || pathname.length === 0) {
        throw TypeError("pathname must be a non empty string");
    }
    if (pathname[0] === '/') {
        pathname = pathname.slice(1);
    }
    //console.log(baseUrl, pathname);
    return baseUrl + pathname;
}
// */
