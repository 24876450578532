import { SET_IS_LOADING } from './actions';

const LoadingReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    default:
      return state
  }
}

export default LoadingReducer
