/**
 * Gets the last ten numbers from a phone number
 * +12105551234 >> 2105551234
 * 123-456-7890 >> 1234567890
 *
 * @param {string} phone
 * @returns {string}
 */
export function getLastTenDigits (phone) {
  if (typeof phone !== 'string') throw new TypeError('Phone number must be string.');
  let tenDigits = '';
  for (let i = 0; i < phone.length && tenDigits.length < 10; i++) {
    const digit = phone[phone.length - 1 - i];
    if (/[0-9*]/.test(digit)) tenDigits = digit + tenDigits;
  }
  return tenDigits;
}

/**
 * Converts the last ten digits into a phone number with dashes
 * +12105551234 >> 210-555-1234
 * (123) 456-7890 >> 123-456-7890
 *
 * @param {string} phone
 * @returns {string}
 */
export function phoneTenDigit (phone) {
  const t = getLastTenDigits(phone);
  const areaCode = t.substr(0,3);
  const phoneNumber = `${t.substr(3,3)}-${t.substr(6,4)}`;
  return `${areaCode}-${phoneNumber}`;
}

export function phoneParenthesis (phone) {
  let t = getLastTenDigits(phone);
  t = t.replaceAll('*', '•');
  const areaCode = t.substr(0,3);
  const phoneNumber = `${t.substr(3,3)}-${t.substr(6,4)}`;
  return `(${areaCode}) ${phoneNumber}`;
}

/**
 * Firebase requires number with a country code.
 * 2105551234 >> +12105551234
 * (123) 456-7890 >> +11234567890
 *
 * @param {string} phone
 * @param {string} [code="+1"] By default the country code is the U.S. +1
 * @returns {string}
 */
export function phoneWithCountryCode (phone, code) {
  if (typeof code !== 'string') code = '+1';
  const t = getLastTenDigits(phone);
  return `${code}${t}`;
}