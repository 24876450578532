import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from "@material-ui/core/Typography";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../_metronic/_partials/controls';
import Select from '../../components/common/form/Select';
import Address from '../../components/address/address';
import { configContext } from "../../provider/config/ConfigProvider";
import SiteService from "../../services/site";
import SearchableSelect from "../common/form/SearchableSelect";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import { RoleEnum } from '../../config/roles';
import { RoleBasedRenderer } from '../roles-based-renderer/RoleBasedRenderer';
import SiteDashboardGroupField from './SiteDashboardGroupField';
import SiteFormConfigField from './SiteFormConfigField';
import SiteChildrenTable from './SiteChildrenTable';

const siteFormSchemaConfig = {
  legal_name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Legal Name is required"),
  common_name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Common Name is required"),
  abbreviation: Yup.string()
    .max(50, "Maximum 50 symbols"),
  address_1: Yup.string()
    .required('Address Line 1 is required'),
  address_2: Yup.string().nullable(),
  city: Yup.string()
    .required('City is required'),
  state: Yup.string()
    .required('State is required'),
  zip: Yup.number()
    .positive()
    .integer()
    .test(
      "len",
      "Must be exactly 5 characters",
      (val) => val && val.toString().length === 5
    )
    .required("ZIP is required"),
  county: Yup.string()
    .required('County is required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  parent_id: Yup.number().integer(),
  category: Yup.number().integer()
    .required('Site category is required'),
  type: Yup.number().integer()
    .required('Site type is required'),
  // from site_property
  trauma_designation: Yup.number().integer().nullable(),
  aha_stroke_os_id: Yup.string().nullable(),
  stroke_designation: Yup.number().integer().nullable()
};

const SiteForm = (props) => {
  const history = useHistory();
  const { saveSite, formLabel, initSite } = props;
  const [options, setGeneralOptions] = useState({
    site_type: [],
    site_category: [],
    stroke_designation: [],
    trauma_designation: []
  });
  const config = useContext(configContext);
  const [SiteFormSchema, setSiteFormSchema] = useState(Yup.object().shape(siteFormSchemaConfig));
  useEffect(() => {
    const newSiteFormSchema = {};
    Object.assign(newSiteFormSchema, siteFormSchemaConfig)
    if (config.functions.billingCode) {
      newSiteFormSchema.external_site_id = Yup.string()
        .required('Billing Code is required')
    }
    setSiteFormSchema(Yup.object().shape(newSiteFormSchema));
  }, [config, setSiteFormSchema]);

  const parentSites = useAsyncSelect({
    url: '/site/list',
    key: 'site_id',
    searchTerm: 'name',
    filters: {
      type: 12
    }
  });

  const defaultValues = {
    legal_name: "",
    common_name: "",
    abbreviation: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    latitude: "",
    longitude: "",
    parent_id: "",
    category: "",
    type: "",
    trauma_designation: "",
    aha_stroke_os_id: "",
    stroke_designation: "",
    dashboard_level_code: "",
    dashboard_group_id: [],
    form_config_id: [],
  };

  const initialValues = {...defaultValues, ...(initSite || {})};

  const submitForm = (values) => {
    saveSite(values);
  };

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    (async function () {
      let res = await SiteService.getOptions();

      setGeneralOptions(res.data);
    })();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={SiteFormSchema}
      onSubmit={(values) => {
        submitForm(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <>
          <Modal.Body>
            <Row className={'align-items-center'}>
              <Col xs={'auto'}>
                <Typography variant="h6" id="subtitle1">
                  {formLabel}
                </Typography>
              </Col>
              {initSite && initSite.site_id ?
                <SiteChildrenTable site_id={initSite.site_id} />
                : null}
            </Row>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4 mt-6">
                  <Field
                    name="legal_name"
                    component={Input}
                    placeholder="Legal Name"
                    label="Legal Name"
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="common_name"
                    component={Input}
                    placeholder="Common Name"
                    label="Common Name"
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="abbreviation"
                    component={Input}
                    placeholder="Abbreviation"
                    label="Abbreviation"
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="category"
                    label="Site Category"
                    component={Select}
                    placeholder="Select Category"
                    options={options.site_category}
                    getOptionLabel={(option) => `${option.category}`}
                    getOptionValue={(option) => option.category_id}
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="type"
                    label="Site Type"
                    component={Select}
                    placeholder="Select Type"
                    options={options.site_type}
                    getOptionLabel={(option) => `${option.type}`}
                    getOptionValue={(option) => option.type_id}
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="stroke_designation"
                    label="Stroke Designation"
                    component={Select}
                    placeholder="Select Stroke Designation"
                    options={options.stroke_designation}
                    getOptionLabel={(option) => `${option.description} - ${option.description2}`}
                    getOptionValue={(option) => option.stroke_designation_id}
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="trauma_designation"
                    label="Trauma Designation"
                    component={Select}
                    placeholder="Select Trauma Designation"
                    options={options.trauma_designation}
                    getOptionLabel={(option) => `${option.description} - ${option.description2}`}
                    getOptionValue={(option) => option.trauma_designation_id}
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="aha_stroke_os_id"
                    component={Input}
                    placeholder="AHA Stroke ID"
                    label="AHA Stoke ID"
                  />
                </div>

                <div className="col-lg-4 mt-6">
                  <Field
                    name="parent_id"
                    component={SearchableSelect}
                    placeholder="Parent Site"
                    label="Parent Site"
                    defaultValue={values.parent_id}
                    defaultOptions={parentSites.options}
                    loadOptions={parentSites.loadOptions}
                    loadValue={parentSites.loadValue}
                    getOptionLabel={option => option.common_name || option.legal_name}
                    getOptionValue={option => option.site_id}
                  />
                </div>

              </div>
              <div className="form-group row">
                <Address />
              </div>

              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.TableauAdmin
                ]}
                inSites={[0]}
                showWarning={false}
              >
                <SiteDashboardGroupField />
              </RoleBasedRenderer>
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.FormAdmin
                ]}
                inSites={[0]}
                showWarning={false}
              >
                <SiteFormConfigField />
              </RoleBasedRenderer>
            </Form>
          </Modal.Body>

          <Modal.Footer>
              <button
                type="button"
                onClick={onCancel}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};

export default SiteForm;
