import React, { useCallback, useEffect, useState } from "react";
import FormConfigSitesList from './FormConfigSitesList';
import { Col, Row } from 'react-bootstrap';
import FormConfigSitesReassign from './FormConfigSitesReassign';
import useApiService from '../../../hooks/useApiService';

export default function FormConfigSitesForm (props) {
  const {
    name,
    title
  } = props;

  const [unsavedSites, setUnsavedSites] = useState([]);
  const fetchRows = useApiService({
    url: `/multiform/schema/${name}/sites`
  });

  return <Row>
    <Col>
      <Row>
        <Col>
          <FormConfigSitesReassign
            title={title}
            name={name}
            fetchRows={fetchRows}
            unsavedSites={unsavedSites}
            setUnsavedSites={setUnsavedSites}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormConfigSitesList
            fetchRows={fetchRows}
            assigned={true}
            unsavedSites={unsavedSites}
            setUnsavedSites={setUnsavedSites}
          />
        </Col>
        <Col xs={6}>
          <FormConfigSitesList
            fetchRows={fetchRows}
            assigned={false}
            unsavedSites={unsavedSites}
            setUnsavedSites={setUnsavedSites}
          />
        </Col>
      </Row>
    </Col>
  </Row>;
}