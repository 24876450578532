import { useCallback } from "react";
import useApiService from "./useApiService";

function useUploadService ({
  url,
  method = 'post',
  queryString,
  body = null,
  headers = null,
}) {

  const fetch = useApiService({url, method, queryString, body, headers});
  return useCallback(({files = [], body = {}, progressCallback = undefined, ...config}) => {
    const formData = new FormData();
    Object.entries(body).forEach(([k,v]) => {
      formData.append(k,v);
    });
    const _config = {
      data: formData,
      ...config,
    };
    if (Array.isArray(files) && files.length > 0) {
      _config.files = files.map((key) => body[key]);
    }
    return fetch(_config);
  }, [url, queryString, method, body, headers]);
}

export default useUploadService;
