import { useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import UserCreateTableau from './UserCreateTableau';

const UserErrors = () => {
  const { values } = useFormikContext();
  const {errors} = values;

  if (!errors) return null;

  return <Row>
    <Col>
      {errors.map((error, i) => {
        const {name, message} = error;
        if (name === 'TableauLoadError' && message.startsWith('no user found')) {
          return <UserCreateTableau key={i} error={error}/>
        }
        return <Row>
          <Col>
            {name}: {message}
          </Col>
        </Row>
      })}
    </Col>
  </Row>
}

export default UserErrors;
