import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import useApiService from '../../hooks/useApiService';
import useAxiosResponse from '../../hooks/useAxiosResponse';
import { Checkbox } from "../../_metronic/_partials/controls";
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

export default function SiteFormConfigField () {
  const history = useHistory();
  const { values, setFieldValue } = useFormikContext();

  const FIELD_NAME = 'form_config_id';
  const value = values[FIELD_NAME] || [];

  const fetchFormConfigs = useApiService({
    url: '/multiform/schema',
    key: 'id',
  });
  const [{response: formConfigs}] = useAxiosResponse(fetchFormConfigs);
  return <Row className={'pt-6'}>
    <Col>
      <Row>
        <Col><h6>Form Configs</h6></Col>
      </Row>
      {formConfigs && Array.isArray(formConfigs.results) ? <Row>
        {formConfigs.results.map((formConfig, index) => {
          const {id, type, title, name, description} = formConfig;
          return <Col key={id} className={`col-md-4 col-sm-12 custom-checkbox`}>
            <Field
              name={`${FIELD_NAME}[${index}]`}
              component={Checkbox}
              isSelected={value.includes(id)}
              onChange={(e) => {
                const {checked} = e.target;
                if (checked) {
                  setFieldValue(FIELD_NAME, [...value, id])
                } else {
                  setFieldValue(FIELD_NAME, value.filter(v => v !== id))
                }
              }}
            />
            <label>{description} <span className={`label label-inline`}>
                {type}
              </span>
              <button
                className={'ml-2 btn btn-icon btn-xs btn-outline-primary'}
                onClick={() => {
                  history.push(`/form/schema/${name}`);
                }}
              >
              <span className={`svg-icon`}>
                <SVG src={toAbsoluteUrl(`/media/svg/icons/General/Edit.svg`)}/>
              </span>
              </button>
            </label>

          </Col>
        })}
      </Row> : null}
    </Col>
  </Row>
}
