import React, { useState } from 'react';
import useApiService from '../../hooks/useApiService';
import { useQueryStringFromState } from '../../utils/queryString';
import useAxiosResponse from '../../hooks/useAxiosResponse';
import {Modal, Button, Spinner } from 'react-bootstrap';
import SiteDataTable from './SiteDataTable';
import { useHistory } from 'react-router-dom';

const SiteChildrenTable = (props) => {
  const {site_id} = props;
  const history = useHistory();

  const queryParams = useQueryStringFromState({
    orderType: 'desc',
    orderBy: 'site_id',
    page: 0,
    rowsPerPage: 10,
    searchOptions: {
      childrenOnly: true
    }
  });

  const fetchRows = useApiService({
    url: `/site/${site_id}/children`,
    queryString: queryParams.queryString
  });

  const [ {response, error, loading} ] = useAxiosResponse(fetchRows, true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const gotoEditPage = (id) => {
    history.push('/site/edit/' + id);
  }

  return <>
    <div className="col-lg-4">
      {error || (!loading ?
        <Button
          disabled={loading || response.total_rows === 0}
          onClick={handleShow}
          className={`btn btn-${loading || response.total_rows === 0 ? 'secondary' : 'light-primary'}`}
        >
          {response.total_rows ? <><i className="flaticon-visible"></i> {response.total_rows}</> : 'No'} children
        </Button> : <Spinner animation={'border'} size={'sm'}/>)}
    </div>



    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-lg"
      scrollable={true}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Children</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        height: '70vh'
      }} >
          {response ? <SiteDataTable
            rows={response.results}
            setRows={() => {}}
            setSelected={(site_id) => {
              gotoEditPage(site_id);
              handleClose()
            }}
            order={queryParams.orderType}
            setOrder={queryParams.setOrder}
            orderBy={queryParams.orderBy}
            setOrderBy={queryParams.setOrderBy}
            page={queryParams.page}
            setPage={queryParams.setPage}
            rowsPerPage={queryParams.rowsPerPage}
            setRowsPerPage={queryParams.setRowsPerPage}
            totalRows={response.total_rows}
          /> : <Spinner animation="border" className={'mt-2 mb-0'}/> }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

export default SiteChildrenTable