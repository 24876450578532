import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import useApiService from '../../hooks/useApiService';

const UserCreateTableau = ({error}) => {

  const [senderror, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const send = useApiService({
    url: `/user/${error.user_id}/tableau`,
    method: 'post'
  });

  return <Row>
    <Col>
      {error.name}: {error.message}
    </Col>
    <Col>
      <Button className={`btn-${senderror ? 'danger' : 'primary'}`} disabled={loading || sent} onClick={() => {
        if (!loading) {
          setLoading(true);
          setError(false);
          send().then(({data}) => {
            // TODO route should return error code
            if (data.errors && data.errors.length) {
              setError(true);
              console.error(data.errors);
            } else {
              setSent(true);
            }
          }).catch((e) => {
            setError(true);
            console.error(e);
          }).finally(() => {
            setLoading(false);
          });
        }
      }}>{loading
        ? 'Loading'
        : sent ? 'Tableau Account Created' : 'Create Tableau Account'}</Button>
    </Col>
  </Row>
}

export default UserCreateTableau;