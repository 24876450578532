import {
  RJSFSchema,
  StrictRJSFSchema,
  FormContextType,
  ErrorSchema,
  FieldProps,
  GenericObjectType,
  getUiOptions
} from "@rjsf/utils";
import React, { useCallback, useMemo } from "react";
import dateAndTime from 'date-and-time';

const dataFields = ['date', 'years', 'months', 'days'] as const;
type DataField = typeof dataFields[number];

const getAge = (date: Date) => {
  if (isNaN(date.getTime())) {
    return null;
  }
  const today = new Date();
  const toYear = today.getFullYear();
  const toMonth = today.getMonth();
  const toDay = today.getDate();
  const fromYear = date.getFullYear();
  const fromMonth = date.getMonth();
  const fromDay = date.getDate();
  const year = toYear - fromYear;
  const month = toMonth - fromMonth;
  const day = toDay - fromDay;
  /* //
  console.log({
    today,
    toYear,
    toMonth,
    toDay,
    date,
    fromYear,
    fromMonth,
    fromDay,
  })//*/
  return {
    years: Math.floor(year + (month < 0 || (month === 0 && day < 0) ? -1 : 0)),
    months: Math.floor(year * 12 + month + (day < 0 ? -1 : 0)),
    days: Math.floor(Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)))
  }
}

export default function DobAgeField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
  > (props: FieldProps<T, S, F>) {
  const {
    schema: rawSchema,
    uiSchema: rawUiSchema,
    formData,
    registry,
    onChange,
  } = props;
  const { fields, schemaUtils, globalUiOptions } = registry;
  const {ObjectField} = fields;

  const [dataFieldNames, schema, uiSchema] = useMemo(() => {
    const schema = schemaUtils.retrieveSchema(rawSchema, formData);
    const uiOptions = getUiOptions<T, S, F>(rawUiSchema, globalUiOptions);
    const { properties: schemaProperties = {} } = schema as RJSFSchema;
    const order = uiOptions.order || Object.keys(schemaProperties);
    const dataOrder = Array.isArray(uiOptions.dataOrder) ? uiOptions.dataOrder as DataField[] : dataFields ;

    const dataFieldNames: Record<DataField, string> = {
      date: '',
      years: '',
      months: '',
      days: ''
    };
    const uiSchema = {
      ...rawUiSchema,
    };
    for (let i = 0; i < order.length && i < dataOrder.length; i++) {
      const fieldName = order[i];
      const fieldData = dataOrder[i];
      const properties = schemaProperties[fieldName];

      if (fieldData === 'date') {
        const {format} = properties as RJSFSchema;
        if (!format || !format.includes('date')) {
          console.warn(new Error(`DobAgeField ${fieldName} is not a date field`));
        }
      } else {
        const value = (formData as GenericObjectType)[fieldName];

        if (!value && `${value}` !== '0') {
          uiSchema[fieldName] = {
            'ui:label': false,
            'ui:widget': 'HiddenConstWidget',
          }
        } else {
          uiSchema[fieldName] = {
            'ui:disabled': true,
          }
        }
      }
      dataFieldNames[fieldData] = fieldName;
    }

    return [dataFieldNames, schema, uiSchema];
  }, [schemaUtils.retrieveSchema, rawSchema, formData, rawUiSchema, globalUiOptions]);

  const handleChange = useCallback((formData: T | undefined, errorSchema?: ErrorSchema<T>, id?: string) => {
    const { properties: schemaProperties = {} } = schema as RJSFSchema;
    const dateValue = (formData as GenericObjectType)[dataFieldNames.date];

    const newFormData: GenericObjectType = {
      [dataFieldNames.date]: dateValue,
    };

    try {
      const birthDate = dateAndTime.parse(dateValue, 'YYYY-MM-DD', false);
      const age = getAge(birthDate)
      for (const dataName of ['years', 'months', 'days'] as const) {
        const value = age![dataName];
        const fieldName = dataFieldNames[dataName];
        if (!fieldName) {
          continue;
        }
        const properties = schemaProperties[fieldName];
        const {maximum, minimum} = properties as RJSFSchema;
        if ((maximum === undefined || value <= maximum) && (minimum === undefined || value >= minimum)) {
          newFormData[fieldName] = `${value}`;
        }
      }
    } catch {
      // to be expected when the date can't be parsed , do nothing
    }

    const newErrorSchema = {...errorSchema};
    return onChange(newFormData as T, newErrorSchema, id);
  }, [onChange, dataFieldNames, schema]);

  return <ObjectField {...props} onChange={handleChange} schema={schema} uiSchema={uiSchema}/>;
}
