import React, { useCallback, useState } from "react";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import AsyncSelect from "react-select/async";
import SolidControl from "../common/form/react-select/SolidControl";
import {components} from "react-select";

const CountyOption = props => {
  return <components.Option {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.common_name || props.data.name}
    {/* eslint-disable-next-line react/prop-types */}
    {
      props.data.counties && props.data.counties.length > 0
      ? <small className="text-muted"> | {props.data.counties.join(', ')}</small>
      : null
    }
  </components.Option>
}

export default function RACFilterSelect (props) {
  const {name, value, formik} = props;
  const {setFieldValue} = formik;

  const [site, setSite] = useState(null);

  const sites = useAsyncSelect({
    url: '/auth/racs',
    key: 'site_id',
    searchTerm: 'name',
    filters: {
      exclude: value,
    }
  });

  const handleChange = useCallback((site) => {
    setSite(site);
    if (!Array.isArray(site)) site = [site];
    const selectedSiteIds = (site || []).filter(site => !!site).map(site => site.site_id);
    setFieldValue(name, selectedSiteIds);
  }, [setSite, name, setFieldValue]);

  return <AsyncSelect
    name={name}
    placeholder={'Filter by RAC or County'}
    //isMulti
    //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    loadOptions={sites.loadOptions}
    getOptionLabel={option => option.common_name || option.name}
    getOptionValue={option => option.site_id}
    loadValue={sites.loadValue}
    defaultOptions={sites.options}
    onChange={handleChange}
    isClearable={true}
    value={site}
    menuPlacement={'auto'}
    components={{Control: SolidControl, Option: CountyOption}}
  />
}
