import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { firebaseAuth } from "../../provider/auth/AuthProvider";
import { configContext } from "../../provider/config/ConfigProvider";
import useLogger from '../../hooks/useLogger';
import useDebounce from '../../hooks/useDebounce';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({data: {error, errorInfo}})
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error data={this.state.data}/>;
    }

    return this.props.children;
  }
}

const useStyles = makeStyles(() => ({
	errorBackground: {
		backgroundImage: `url(${toAbsoluteUrl('/media/error.jpg')})`
	},
  title: {
    fontSize: "14rem!important"
  },
  introduction: {
    fontSize: "2.5rem!important"
  }
}));

const Error = ({data}) => {
  const classes = useStyles();
  const { handleSignout } = useContext(firebaseAuth);
  const config = useContext(configContext);
  const logger = useLogger();
  const loggerDebounce = useDebounce(logger, 100)
  useEffect(() => {
    loggerDebounce(data, 'ErrorBoundary')
  }, [loggerDebounce, data]);

  return (
    <div className="d-flex flex-column flex-root full-height">
      <div className="d-flex flex-column flex-root">
        <div className={`${classes.errorBackground} error d-flex flex-row-fluid bgi-size-cover bgi-position-center`}>
            <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                <h1 className={`${classes.title} error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12`}>Oops!</h1>
                <p className={`${classes.introduction} font-weight-boldest`}>Something went wrong here.</p>
                <p className="font-size-h3">Please reload the page or try to <a href="javascript:void(0)" onClick={handleSignout}>login</a> again!</p>
                <p className="font-size-h3">Still doesn't work? Send an email to{' '}
                  <a href={`mailto:${config.supportContact.email}`}>{ config.supportContact.email }</a>
                  {' '}or give us a call at{' '}
                  <a href={`tel:${config.supportContact.phone}`}>{ config.supportContact.phone }</a>.
                </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
