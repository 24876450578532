import React, { useCallback, useEffect, useState } from 'react';

export default function DataTableSearch (props) {
  const {value, onRequestSearch, onCancelSearch, placeholder} = props;

  const [searchString, setSearchString] = useState(value || '');
  useEffect(() => {
    setSearchString(value || '');
  }, [value, setSearchString]);

  const handleCancelSearch = useCallback(() => {
    setSearchString('');
    if (typeof onCancelSearch === 'function') {
      onCancelSearch();
    }
  }, [onCancelSearch])

  const handleRequestSearch = useCallback(() => {
    if (typeof onRequestSearch === 'function') {
      onRequestSearch(searchString);
    }
  }, [searchString, onRequestSearch]);

  return <div className="input-icon input-icon-right">
    <input
      autoComplete={'off'}
      type="text"
      className="form-control"
      value={searchString}
      placeholder={placeholder}
      onChange={(e) => setSearchString(e.target.value)}
      onKeyDown={(e) => {
       if (e.key === 'Enter') {
         handleRequestSearch();
       }
       if (e.key === 'Escape') {
         handleCancelSearch();
       }
      }}/>
    <span
      className='btn btn-hover-light'
      onClick={() => {
        if (value) {
          handleCancelSearch();
        } else {
          handleRequestSearch();
        }
      }}
    >
      <i className={`${value ? 'flaticon2-cross' : 'flaticon2-search-1'} icon-md`}></i>
    </span>

  </div>
}