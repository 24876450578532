import { service } from "../api-service";

function getOptionRoles(params = "") {
  return service({
    url: "/user/option/roles" + params,
    method: "get",
  });
}

function getOptionSite(id) {
  return service({
    url: "/user/option/site/" + id,
    method: "get",
  });
}

function getOptionSites(params = "") {
  return service({
    url: "/user/option/sites" + params,
    method: "get",
  });
}

function getOptionsTableau(params= "") {
  return service({
    url: "/user/option/tableau" + params,
    method: "get",
  });
}


export function getUserCSVFile(params = "") {
  return service({
    url: "/user/export" + params,
    method: "get",
    responseType: "blob"
  });
}

export function getUser(id) {
  return service({
    url: "/user/" + id,
    method: "get",
  });
}

export function addUser(data) {
  return service({
    url: "/user/create",
    method: "post",
    data,
  });
}

export function editUser(data) {
  return service({
    url: "/user/" + data.user_id,
    method: "patch",
    data,
  });
}


export function getUserSelfRoles() {
  return service({
    url: "/user/self/roles",
    method: "get",
  });
}

export function updateUserRoles(data, user_id) {
  return service({
    url: `/user/${user_id}/roles`,
    method: "patch",
    data
  });
}

export function getEnrolledFactors(user_id) {
  return service({
    url: "/user/" + user_id + "/multiFactor",
    method: "get"
  });
}

export function deleteEnrolledFactor(data, user_id) {
  return service({
    url: "/user/" + user_id + "/multiFactor",
    method: "delete",
    data
  });
}

const UserService = {
  getOptionRoles,
  getOptionSite,
  getOptionSites,
  getOptionsTableau,
  getUser,
  addUser,
  editUser,
  getUserSelfRoles,
  getEnrolledFactors,
  deleteEnrolledFactor
};

export default UserService;
