import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import useAsyncSelect from '../../hooks/useAsyncSelect';
import useApiService from '../../hooks/useApiService';
import useAxiosResponse from '../../hooks/useAxiosResponse';
import { Button, Col, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Select from '../common/form/Select';

export default function SiteDashboardGroupField () {
  const { setFieldValue } = useFormikContext();

  const [groupField] = useField({name: 'dashboard_group_id'});
  const [levelField] = useField({name: 'dashboard_level_code'});

  const groups = useAsyncSelect({
    url: '/dashboard/group/search',
    key: 'dashboard_group_id',
    searchTerm: 'searchString',
    filters: {
      exclude: groupField.value || [],
    }
  });

  useEffect(() => {
    groupField.value.forEach((id) => {
      groups.loadValue(id).catch(console.warn);
    });
  }, [groupField.value]);

  const fetchLevels = useApiService({ url: '/dashboard/group/level' });
  const [{response: dashboardLevels}] = useAxiosResponse(fetchLevels);

  const handleAdd = useCallback((group) => {
    if (!group) return;
    if (!Array.isArray(group)) group = [group];
    const group_ids = group.map(group => group.dashboard_group_id);
    const newGroups = new Set([...(groupField.value), ...group_ids]);
    setFieldValue(groupField.name, Array.from(newGroups));
  }, [groupField, setFieldValue]);

  const handleRemove = useCallback((group) => {
    if (!group) return;
    if (!Array.isArray(group)) group = [group];
    const group_ids = group.map(group => group.dashboard_group_id);
    setFieldValue(groupField.name, groupField.value.filter(group_id => !group_ids.includes(group_id)));
  }, [groupField, setFieldValue]);

  const [siteGroups, maxRows] = useMemo(() => {
    const byLevel = {};
    let maxRows = 0;
    if (!groupField.value || !groups.options) return [byLevel, maxRows];
    for (const group_id of groupField.value) {
      const group = groups.options.find(group => `${group.dashboard_group_id}` === `${group_id}`);
      if (!group) continue;
      const level = group.dashboard_level_code || null;
      if (!byLevel[level]) byLevel[level] = [];
      byLevel[level].push(group);
    }
    for (const level of Object.keys(byLevel)) {
      maxRows = Math.max(maxRows, byLevel[level].length);
    }
    return [byLevel, maxRows];
  }, [groups.options, groupField]);

  const levels = (dashboardLevels || []).concat({dashboard_level_code: null, dashboard_level: 'Uncategorized'});
  return <Row className={'pt-6'}>
    <Col>
      <Row className={'mb-6'}>
        <Col xs={4}>
          <Field
            {...levelField}
            label={'Dashboard Level'}
            component={Select}
            getOptionLabel={option => option.dashboard_level}
            getOptionValue={option => option.dashboard_level_code}
            options={dashboardLevels}
          />
        </Col>
      </Row>
      <Row>
        <Col><h6>Dashboard Groups</h6></Col>
        <Col>
          <AsyncSelect
            placeholder={'Add a Dashboard Group'}
            //isMulti
            //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
            loadOptions={groups.loadOptions}
            getOptionLabel={option => option.dashboard_group}
            getOptionValue={option => option.dashboard_group_id}
            loadValue={groups.loadValue}
            defaultOptions={groups.options}
            onChange={handleAdd}
            isClearable={true}
            value={null}
            menuPlacement={'auto'}
            //components={{Option: SiteLevelOption}}
            filterOption={(option) => {
              return groupField.value.findIndex(id => `${id}` === `${option.value}`) === -1;
            }}
          />
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <Table bordered>
            <thead>
            <tr>
              {levels.map((level) => {
                return <th
                  key={level.dashboard_level_code}
                  className={`${levelField.value === level.dashboard_level_code ? 'bg-light-primary' : ''}`}
                  style={{
                    width: `${100 / levels.length}%`
                  }}>
                  {level.dashboard_level}
                </th>;
              })}
            </tr>
            </thead>
            <tbody>
            {Array(maxRows).fill(0).map((_, i) => {
              return <tr key={i}>
                {levels.map((level) => {
                  let group = undefined;
                  if (siteGroups[level.dashboard_level_code] && siteGroups[level.dashboard_level_code][i]) {
                    group = siteGroups[level.dashboard_level_code][i];
                  }
                  return <td className={`${levelField.value === level.dashboard_level_code ? 'bg-light-primary' : ''}`} key={`${level.dashboard_level_code}_${i}_${(group || {}).dashboard_group_id}`}>
                    {group ? <>{group.dashboard_group}
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            size={'sm'}
                            className={'mr-2 btn-outline-dark'}
                            to={`/dashboard/group/${group.dashboard_group_id}`}
                          >
                            Edit
                          </Button>
                          <Button
                            size={'sm'}
                            className={'mr-2 btn-outline-primary'}
                            onClick={() => handleRemove(group)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </> : ''}
                  </td>
                })}
              </tr>
            })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
  </Row>

}
