import { useCallback, useMemo, useState } from "react";
import { useStateValue } from "../store/StateProvider";
import { isNullOrUndefined } from "./helpers";
import useDeepCompareEffect from "use-deep-compare-effect";

export const extractSearchString = (searchString) => {
  let searchOptions = {};

  if (searchString) {
    if (searchString.indexOf("@") !== -1) {
      searchOptions.email = searchString;
    } else if (isPhoneNumber(searchString)) {
      searchOptions.phone = formatPhoneNumber(searchString);
    }
    else {
      let params = searchString.split(" ");

      if (params[0]) {
        searchOptions.first_name = params[0];
      }

      if (params[1]) {
        searchOptions.last_name = params[1];
      }
    }
  }

  return searchOptions;
};

export const searchOptionsToSearchString = (searchOptions) => {
  const neededSearchOptions = [];

  for (const [searchOption, value] of Object.entries(searchOptions)) {
    if (searchOption === "email" || searchOption === "first_name" || searchOption === "last_name" || searchOption === "phone") {
      neededSearchOptions.push(value);
    }
  }

  return neededSearchOptions.join(" ");
};

const defaultQueryParams = {
  orderType: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 25,
  searchOptions: {}
}

export const buildQueryString = ({
  orderType,
  orderBy,
  page,
  rowsPerPage,
  searchOptions,
}) => {
  orderType = orderType || defaultQueryParams.orderType;
  orderBy = orderBy || defaultQueryParams.orderBy;
  page = page || defaultQueryParams.page;
  rowsPerPage = rowsPerPage || defaultQueryParams.rowsPerPage;
  searchOptions = searchOptions || defaultQueryParams.searchOptions;

  let queryString = `?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;
  queryString +=
    orderBy !== "" ? `&order_by=${orderBy}&order_type=${orderType}` : "";

  if (typeof searchOptions === 'object') {
    for (const [key, value] of Object.entries(searchOptions)) {
      // https://stackoverflow.com/questions/6243051/how-to-pass-an-array-within-a-query-string
      if (Array.isArray(value)) {
        for (const i in value) {
          queryString += `&${key}=${value[i]}`
        }
      } else if (!isNullOrUndefined(value)) {
        queryString += `&${key}=${value}`;
      }
    }
  }

  return encodeURI(queryString);
};

export const editQueryString = (queryString, {
  orderType,
  orderBy,
  page,
  rowsPerPage,
  searchOptions,
}) => {
  const searchParams = new URLSearchParams(decodeURI(queryString));
  if (!isNullOrUndefined(orderType) && orderType) {
    searchParams.set('order_type', `${orderType}`);
  }
  if (!isNullOrUndefined(orderBy) && orderBy) {
    searchParams.set('order_by', `${orderBy}`);
  }
  if (!isNullOrUndefined(rowsPerPage) && rowsPerPage) {
    searchParams.set('limit', `${rowsPerPage}`);
  } else {
    rowsPerPage = searchParams.get('limit');
  }
  if (!isNullOrUndefined(page) && page) {
    searchParams.set('offset', `${page * rowsPerPage}`);
  }

  // replace existing search options, or remove them if they are intentionally set as undefined or null
  if (typeof searchOptions === 'object') {
    for (const [key, value] of Object.entries(searchOptions)) {
      if (Array.isArray(value)) {
        const aKey = key + '[]';
        if (searchParams.has(aKey)) searchParams.delete(aKey);
        for (const i in value) {
          searchParams.append(aKey, value[i]);
        }
      } else if (!isNullOrUndefined(value)) {
        // replace the key
        searchParams.set(key, `${value}`);
      } else if (searchParams.has(key)) {
        // remove the key if it exists
        searchParams.delete(key);
      }
    }
  }

  return encodeURI('?' + searchParams.toString());
};

export const useQueryStringFromProps = ({
  orderType, orderBy, page, rowsPerPage, searchOptions
}) => {
  const [queryString, setQueryString] = useState(buildQueryString({orderType, orderBy, page, rowsPerPage, searchOptions}));
  useDeepCompareEffect(() => {
    const queryParams = {orderType, orderBy, page, rowsPerPage, searchOptions};
    ['orderType', 'orderBy', 'page', 'rowsPerPage', 'searchOptions'].forEach((param) => {
      if (typeof queryParams[param] === 'undefined') {
        queryParams[param] = defaultQueryParams[param];
      }
    });
    setQueryString(buildQueryString(queryParams));
  }, [orderType, orderBy, page, rowsPerPage, searchOptions]);

  return queryString;
}

export const useQueryStringFromState = (queryParams) => {
  if (typeof queryParams === "undefined") queryParams = defaultQueryParams;
  const [orderType, setOrderType] = useState(queryParams.orderType);
  const [orderBy, setOrderBy] = useState(queryParams.orderBy);
  const [page, setPage] = useState(queryParams.page);
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.rowsPerPage);
  const [searchOptions, setSearchOptions] = useState(queryParams.searchOptions || {});

  const setSearchOption = useCallback((prop, value) => {
    setSearchOptions((prev) => {
      const {[prop]: prevValue, ...rest} = prev;
      if (typeof value !== 'undefined') {
        if (`${prevValue}` === `${value}`) return prev;
        return {
          ...rest,
          [prop]: value
        };
      }
      return {...rest};
    });
    setPage(0);
  }, [setSearchOptions, setPage]);
  useDeepCompareEffect(() => {
    setSearchOptions((prev) => {
      return {
        ...prev,
        ...queryParams.searchOptions
      };
    });
  }, [queryParams.searchOptions]);

  const reset = useCallback(() => {
    setOrderType(queryParams.orderType);
    setOrderBy(queryParams.orderType);
    setPage(queryParams.page);
    setRowsPerPage(queryParams.rowsPerPage);
    setSearchOptions(queryParams.searchOptions);
  }, [setOrderType, setOrderBy, setPage, setRowsPerPage, setSearchOptions])

  const queryString = useQueryStringFromProps({orderType, orderBy, page, rowsPerPage, searchOptions});

  return {queryString, orderType, setOrderType, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage, searchOptions, setSearchOption, setSearchOptions, reset}
}

// useQueryStringFromStore
export const useQueryString = (pageName) => {
  const [{ queryParams }] = useStateValue();
  return useMemo(() => {
    const pageQueryParams = queryParams[pageName + "QueryParams"];
    return buildQueryString(pageQueryParams);
  }, [pageName, queryParams]);
};

const isPhoneNumber = (string) => {
  const trimedStr = string.replace(/([ ()-])/g, "");

  return trimedStr.length === 10 && !isNaN(trimedStr);
}

const formatPhoneNumber = (phoneNumberStr) => {
  const trimedPhoneNumberStr = phoneNumberStr.replace(/([ ()-])/g, "");

  return trimedPhoneNumberStr.substr(0, 3) + "-" + trimedPhoneNumberStr.substr(3, 3) + "-" + trimedPhoneNumberStr.substr(6, 4);
}

/*
const q = new URLSearchParams({
  orderType: 'asc',
  orderBy: 'dashboard_group_id',
  page: 0,
  rowsPerPage: 25,
  searchOptions: {
    name: 'sample text',
    help: [1,2]
  }
});

const p = new URLSearchParams('?offset=0&limit=25&order_by=site_id&order_type=asc&excludeSites[]=0&excludeSites[]=1');
p.delete('excludeSites[]')
p.append('excludeSites[]', 4)
p.append('excludeSites[]', 5)
const pr = x => {
  for (const y of x) console.log(y);
}
pr(p)
pr(q);

console.log(encodeURI('?offset=0&limit=25&order_by=site_id&order_type=asc&excludeSites[]=0&excludeSites[]=1'))
//*/
