import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { convertServerTimeString } from "../../../../utils/helpers";
import date from "date-and-time";

const useUTC = false;

function UploadEntriesCell ({row}) {
  const {
    total,
    progress,
    created,
    updated,
    error_total
  } = row;

  const eta = () => {
    const rowTime = averageInsertTime();
    return rowTime * (total - progress);
  }

  const averageInsertTime = () => {
    try {
      const updated_at = convertServerTimeString(updated, useUTC);
      const created_at = convertServerTimeString(created, useUTC);
      const runningTime = date.subtract(updated_at, created_at).toSeconds();
      return runningTime / progress;
    } catch {
      return Math.Infinity;
    }
  }

  const isStuck = useCallback(() => {
    const now = new Date();
    if (progress === 0) {
      const created_at = convertServerTimeString(created, useUTC);
      const subtract = date.subtract(now, created_at);
      return subtract.toMinutes() > 1;
    } else if (progress === total) {
      return false;
    } else {
      const updated_at = convertServerTimeString(updated, useUTC);
      if (!updated_at) return false;
      const subtract = date.subtract(now, updated_at);
      const rowTime = averageInsertTime();
      return subtract.toSeconds() > Math.max(rowTime * 10, 60);
    }
  }, [progress, total]);

  const dateToText = (dateString) => {
    const d = convertServerTimeString(dateString, useUTC);
    if (!d || Number.isNaN(d)) return '';
    return date.format(d, 'MM/DD/YYYY[\xa0]HH:mm');
  }

  const etaText = () => {
    if (progress === total) {
      return 'Completed ' + dateToText(updated);
    } else if (isStuck()) {
      return 'Last updated ' + dateToText(updated)
    } else {
      const seconds = eta();
      let format = 'MM/DD/YYYY[\xa0]HH:mm';
      if (seconds < 3600 * 12) {
        format = 'HH:mm:ss'
      }
      return 'ETA: ' + date.format(date.addSeconds(new Date(), seconds), format);
    }
  }

  return <>
    <Row>
      <Col xs={'auto'}>
        {total}
      </Col>
      {error_total ? <Col>
        <span className={'label label-inline label-light-warning font-weight-bold'}>{error_total} rows skipped</span>
      </Col> : null}
    </Row>
    <Row>
      <Col>
        <Form.Text muted>
          {etaText()}
        </Form.Text>
      </Col>
    </Row>
  </>

}

export default UploadEntriesCell;
