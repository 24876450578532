import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";

const RecoverEmail = ({actionCode}) => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { checkActionCode, applyActionCode } = useContext(firebaseAuth);

  useEffect(() => {
    checkActionCode(actionCode).then((info) => {
      const email = info['data']['email'];
      return applyActionCode(actionCode).then(() => {
        setEmail(email);
      })
    }).catch(() => {
      setError('This link has expired.');
    });
  }, [actionCode])

  if (error) {
    return <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <p className="text-muted font-weight-bold">
          {" "}
          {error}
        </p>
      </div>
    </div>
  }

  if (!email) {
    return <CircularProgress className="splash-screen-spinner" />
  }

  return <div className="login-form login-signin" id="kt_login_signin_form">
    <div className="text-center mb-10 mb-lg-20">
      <p className="text-muted font-weight-bold">
        {" "}
        Account email reverted to {email}. Click "Forgot Password" to reset your password.
      </p>
    </div>
    <Link to="/auth/forgot-password">
      <button
        type="button"
        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 btn-block"
      >
        Forgot Password?
      </button>
    </Link>
  </div>

};

export default RecoverEmail;
