import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import fields from '../fields';
import widgets from '../widgets';
import templates from '../templates';
import FormView from "@rjsf/bootstrap-4";
import { customizeValidator } from '@rjsf/validator-ajv8';
import useDebounce from '../../../hooks/useDebounce';
import _ from 'lodash';
import { isObject } from '@rjsf/utils';

const transformErrors = (errors, uiSchema) => {
  const propertyMap = new Map();
  ///*
  for (const error of errors) {
    const {name, property} = error;
    if (!propertyMap.has(property)) {
      propertyMap.set(property, new Set());
    }
    propertyMap.get(property).add(name);
  }//*/

  return errors.filter((error) => {
    const {name, property} = error;
    ///*
    if (name === 'const') {
      return !propertyMap.get(property).has('oneOf')
        && !propertyMap.get(property).has('anyOf')
        && !propertyMap.get(property).has('enum')
        && !propertyMap.get(property).has('pattern');
    }//*/
    return true;
  }).map((error) => {
    //console.log(error, errorUiSchema)
    const {name, property, params} = error;
    //const errorUiSchema = _.get(uiSchema, property.slice(1));
    //console.log(error, propertyMap.get(property));
    if (name === 'if') {
      error.message = '\xa0';
    }
    if (name === 'minLength' && params.limit === 1) {
      error.message = 'cannot be blank';
    }
    if (name === 'multipleOf') {
      if (error.params.multipleOf === 0.001) {
        error.message = 'only up to 3 decimal places';
      }
      if (error.params.multipleOf === 0.01) {
        error.message = 'only up to 2 decimal places';
      }
      if (error.params.multipleOf === 0.1) {
        error.message = 'only up to 1 decimal place';
      }
      if (error.params.multipleOf === 1) {
        error.message = 'cannot have decimal';
      }
    }
    if (name === 'anyOf') {
      error.message = 'at least one is required';
    }
    if (name === 'pattern' && params.pattern === '[0-9]{5}') {
      error.message = 'must be 5 digits';
    }
    if (['oneOf', 'anyOf', 'enum'].includes(name)) {
      //if (propertyMap.get(property).has('const')) {
      error.message = 'must choose one of the options';
      //}
    }
    if (['formatMaximum'].includes(name)) {
      const split = error.message.split(' <= ');
      const [yyyy, mm, dd] = split[1].split('-');
      error.message = `${split[0]} before ${mm}/${dd}/${yyyy}`;
    }
    if (['formatExclusiveMinimum'].includes(name)) {
      const split = error.message.split(' > ');
      const [yyyy, mm, dd] = split[1].split('-');
      error.message = `${split[0]} after the year ${yyyy}`;
    }

    return error;
  });
};

function mapValuesDeep (obj, callback) {
  return _.mapValues(obj, (...args) => {
    const [value] = args;
    if (isObject(value)) {
      return mapValuesDeep(value, callback);
    } else {
      return callback(...args);
    }
  });
}
export default function FormNavPage (props) {
  const {
    children,
    jsonSchema,
    uiSchema,
    errorSchema,
    formData,
    setFormData,
    liveValidate = true,
    debounceValidate = true,
  } = props;

  const actualFormData = formData;

  const refValidation = debounceValidate && liveValidate;

  const validator = customizeValidator({
    ajvOptionsOverrides: {
      allErrors: true,
      coerceTypes: true,
    }
  });

  const formRef = useRef();
  const firstValidate = useRef(false);

  const validate = useCallback(() => {
    if (typeof (formRef.current || {}).validateForm === 'function') {
      const isValid = formRef.current.validateForm();
      if (isValid) {
        setFormData({
          errors: [],
        });
      }
    }
  }, [formRef, setFormData]);
  const validateDebounced = useDebounce(validate, 50);


  useEffect(() => {
    if (actualFormData) {
      if (!firstValidate.current) {
        firstValidate.current = true;
        validate();
      } else if (refValidation) {
        validateDebounced();
      }
    }
  }, [refValidation, validateDebounced, actualFormData, firstValidate.current]);

  const setForm = useCallback((newFormData) => {
    // errors from newFormData don't have the schemaPath property
    // so we don't want to use it. just use the onError callback from the form
    // which is activated by formRef
    if (firstValidate.current && !refValidation) {
      setFormData(newFormData);
    } else {
      const {errors, ...rest} = newFormData;
      setFormData(rest);
    }
    //console.log(newFormData)
    //console.log(newFormData.errors);
    //console.log(newFormData.formData);
  }, [setFormData, firstValidate.current]);

  const ui = useMemo(() => {
    return mapValuesDeep(uiSchema, (value) => {
      const newValue = templates[value];
      if (typeof newValue === 'function') return newValue;
      return value;
    });
  }, [uiSchema]);

  return <FormView
    ref={formRef}
    idSeparator={'/'}
    fields={fields}
    widgets={widgets}
    templates={templates}
    validator={validator}
    schema={jsonSchema}
    uiSchema={ui}
    extraErrors={errorSchema}
    onChange={setForm}
    formData={actualFormData}
    liveValidate={liveValidate && !refValidation}
    noHtml5Validate={true}
    showErrorList={false}
    //onFocus={console.log}
    onError={(errors) => {
      setFormData({
        errors,
      });
    }}
    transformErrors={transformErrors}
    //focusOnFirstError={console.log}
    //submitButtonMessage={"Submit"}
    //omitExtraData={true}
    //liveOmit={true}
  >{children}</FormView>
}