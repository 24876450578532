import React, { useContext } from "react";
import multiFormContext from "../../provider/multiForm/multiFormContext";
import NavWidget from "../common/partial/widget/NavWidget";
import MonacoWidget from "./MonacoWidget";
import { FormBuilder, PredefinedGallery } from "@ginkgo-bioworks/react-json-schema-form-builder";
import { Col, Row } from "react-bootstrap";
import ReactSelect from 'react-select';
import FormViewWidget from "./form/FormViewWidget";

const MultiFormEditor = () => {
  const { options, jsonSchema, uiSchema, formData, setFormData, setUiSchema, setJsonSchema, selected, setSelected } = useContext(multiFormContext);

  const formatJSON = (value) => {
    try {
      const res = typeof value === 'string' ? JSON.parse(value) : value;
      return JSON.stringify(res, null, 2);
    } catch {
      return value;
    }
  }

  const handleChange = (newSchema, newUiSchema) => {
    setSelected(null);
    setJsonSchema(formatJSON(newSchema));
    setUiSchema(formatJSON(newUiSchema));
  };

  const items = [{
    value: "builder",
    label: "Form Builder",
    content: <FormBuilder
      schema={jsonSchema}
      uischema={uiSchema}
      onChange={handleChange}
    />
  },{
    value: "definitions",
    label: "Definitions",
    content: <PredefinedGallery
      schema={jsonSchema}
      uischema={uiSchema}
      onChange={handleChange}
    />
  }, {
    value: "preview",
    label: "Preview",
    content: <Row>
      <Col>
        <FormViewWidget
          jsonSchema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          setFormData={setFormData}
        />
      </Col>
      <Col>
        <MonacoWidget
          header={"Form Data"}
          className={'vh-100'}
          value={formatJSON(formData)}
          onChange={(value) => {
            setFormData(JSON.parse(value));
          }}
        />
      </Col>
    </Row>
  },{
    value: "edit",
    label: "Edit Schema",
    content:<Row>
      <Col>
        <MonacoWidget
          header={"Schema"}
          className={'vh-100'}
          value={jsonSchema}
          onChange={setJsonSchema}
        />
      </Col>
      <Col>
        <MonacoWidget
          header={"UI Schema"}
          className={'vh-100'}
          value={uiSchema}
          onChange={setUiSchema}
        />
      </Col>
    </Row>
  },]

  return <>
    <NavWidget items={items}>
      <Col xs={'4'}>
        <ReactSelect
          options={options.response}
          isLoading={options.loading}
          onChange={setSelected}
          value={selected}
        />
      </Col>
    </NavWidget>
  </>;
}

export default MultiFormEditor;
