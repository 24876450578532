import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import {
  canExpand,
  descriptionId,
  FormContextType,
  getUiOptions,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  titleId,
} from '@rjsf/utils';
import React from "react";
import Form from "react-bootstrap/Form";

export default function ObjectFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ObjectFieldTemplateProps<T, S, F>) {
  const {
    description,
    title,
    hideError,
    errorSchema,
    properties,
    required,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    disabled,
    readonly,
    registry,
  } = props;
  const { __errors , ...fieldErrorSchema} = errorSchema || {};
  const hasErrors= !hideError && (__errors && __errors.length > 0 || Object.keys(fieldErrorSchema || {}).length > 0);

  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  return (
    <>
      {title && (
        <Form.Label
        htmlFor={titleId<T>(idSchema)}
        className={hasErrors ? 'text-danger' : undefined}
        >
          {uiOptions.title || title}
          {required ? '*' : null}
        </Form.Label>
      )}
      <Container fluid className='p-0'>
        {properties.map((element: any, index: number) => (
          <Row key={index} style={{ marginBottom: '10px' }} className={element.hidden ? 'd-none' : undefined}>
            <Col xs={12}> {element.content}</Col>
          </Row>
        ))}
        {canExpand(schema, uiSchema, formData) ? (
          <Row>
            <Col xs={{ offset: 9, span: 3 }} className='py-4'>
              <AddButton
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
                className='object-property-expand'
                uiSchema={uiSchema}
                registry={registry}
              />
            </Col>
          </Row>
        ) : null}
      </Container>
      {description && (
        <Form.Text
        id={descriptionId<T>(idSchema)}
        className={hasErrors ? 'text-danger' : 'text-muted'}
        >
          {description}
        </Form.Text>
      )}
    </>
  );
}
