import { service } from "./api-service";

export function signUp (data) {
  return service({
    url: "/auth/sign-up",
    method: "post",
    data,
  });
}

/**
 *
 * @param data
 * @param {string} data.email
 * @return {Promise}
 */
export function forgotPassword (data) {
  return service({
    url: "/auth/forgot-password",
    method: "post",
    data,
  });
}

export function confirmPasswordReset (actionCode, newPassword) {
  return service({
    url: "/auth/reset-password",
    method: "post",
    data: {
      oobCode: actionCode,
      password: newPassword
    }
  });
}
