import _ from 'lodash';
import { useCallback, useRef, useEffect } from 'react';
import useIsMounted from "./useIsMounted";

export default function useDebounce(cb, delay) {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();

  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return useCallback(
    _.debounce(
      (...args) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (isMounted() && inputsRef.current.delay === delay) inputsRef.current.cb(...args);
      },
      delay,
      options,
    ),
    [delay, _.debounce, inputsRef, isMounted],
  );
}
