import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";
import { Link } from "react-router-dom";

const VerifyEmail = ({actionCode, continueUrl}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { applyActionCode } = useContext(firebaseAuth);

  useEffect(() => {
    applyActionCode(actionCode).then((response) => {
      console.log(response);
      setLoading(false);
    }).catch(() => {
      setError('This link has expired.');
    });
  }, [actionCode])

  if (error) {
    return <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <p className="text-muted font-weight-bold">
          {" "}
          {error}
        </p>
      </div>
    </div>
  }

  if (loading) {
    return <CircularProgress className="splash-screen-spinner" />
  }

  return <div className="login-form login-signin" id="kt_login_signin_form">
    <div className="text-center mb-10 mb-lg-20">
      <p className="text-muted font-weight-bold">
        {" "}
        Email address has been verified.
      </p>
    </div>
    <Link to={`${continueUrl || '/'}`}>
      <button
        type="button"
        className="btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block"
      >
        Continue
      </button>
    </Link>
  </div>

};

export default VerifyEmail;
