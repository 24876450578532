import React, { useCallback, useState } from 'react';
import { phoneParenthesis } from "../../utils/format-phone";
import { UnenrollConfirmationDialog } from "../auth/auth-dialog";
import { useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { Checkbox } from '../../_metronic/_partials/controls';

const UserMultiFactorEdit = () => {
  const { values, setFieldValue } = useFormikContext();

  const enrolledFactors = values.userMultiFactors;
  const [error, setError] = useState(null);

  const [selectedFactor, setFactor] = useState({} );
  const handleOpen = (factor) => {
    setFactor(factor);
  }
  const handleClose = () => {
    setFactor({});
  }

  const unenroll = () => {
    const existingDeleteList = values.deleteEnrolledFactor || [];
    setFieldValue('deleteEnrolledFactor', [...existingDeleteList, selectedFactor.uid]);
    setFactor({});
  }

  const deselect = useCallback((uid) => {
    const existingDeleteList = values.deleteEnrolledFactor || [];
    const newList = existingDeleteList.filter((item) => item !== uid);
    setFieldValue('deleteEnrolledFactor', newList.length ? newList : undefined);
  }, [setFieldValue, values]);

  if (error) {
    return <Row>
      <Col xs={"auto"}>
        <Button
          variant="contained"
          onClick={() => {
            setError(null);
          }}
        >Retry</Button>
      </Col>
      <Col>
        {error}
      </Col>
    </Row>
  }

  if (!enrolledFactors || enrolledFactors.length === 0) return null;

  console.log(values)

  return <>
    <UnenrollConfirmationDialog
      email={values.email}
      phone={phoneParenthesis(selectedFactor.phoneNumber || '')}
      open={!!selectedFactor.phoneNumber}
      confirm={unenroll}
      cancel={handleClose}
    />
    <div className={"col-sm-9 custom-checkbox"}>
      <label>
          Two Factor Authentication (2FA)
      </label>
      {values.deleteEnrolledFactor ?
        <div className={'text-warning'}>
          Unsaved changes.
        </div>
        : null}
      <br />
      <div className="row">
        {enrolledFactors.map((factor) => {
          const toDelete = !!values.deleteEnrolledFactor && values.deleteEnrolledFactor.includes(factor.uid);
          const variant = toDelete ? 'warning' : 'light';
          return <div key={factor.uid} className={`col-sm-12`}>
            <Checkbox
              className={'checkbox-warning'}
              isSelected={toDelete}
              onChange={(e) => {
                if (e.target.checked) {
                  handleOpen(factor);
                } else {
                  deselect(factor.uid);
                }
              }}
            />
            <label>{phoneParenthesis(factor.phoneNumber)} {toDelete ? ' will be removed' : ''}</label>
          </div>
        })}
      </div>
    </div>
  </>
};

export default UserMultiFactorEdit;
