import React, { useCallback, useMemo } from "react";
import DataTable from "../../common/datatable/DataTable";
import DateCell from "../../common/datatable/cell/DateCell";
import RowStatusCell from "./cell/RowStatusCell";

const StrokeDataTable = (props) => {
  const {
    rows,
    replaceRow,
    totalRows,
    queryParams,
    errorMessage,
    loading,
    retry,
    handleClickRow
  } = props;

  const handleAgeFilter = useCallback((e) => {
    if (!e.target.checked) {
      queryParams.setSearchOption('age', undefined)
    } else {
      queryParams.setSearchOption('age', [0,17])
    }
  }, [queryParams.searchOptions, queryParams.setSearchOption]);

  const headCols = useMemo(() => {
    return [
      {
        id: "patient_display_id",
        label: "Patient ID",
        sortable: true,
        width: '100px'
      },
      {
        id: "site_name",
        label: "Site Name",
        sortable: true,
        width: '100px',
      },
      {
        id: "dob",
        label: "DOB",
        sortable: true,
        width: '100px',
        renderCell: (props) => <DateCell format={'MM/DD/YYYY'} {...props}/>,
        filterable: <div>
          <label className="checkbox">
            <input
              type="checkbox"
              onChange={handleAgeFilter}
              checked={!!queryParams.searchOptions.age}/>
            <span></span>
            &nbsp;
            Pediatric (0-17)
          </label>
        </div>,
        filtered: queryParams.searchOptions.age
      },
      {
        id: 'status_id',
        label: 'Status',
        sortable: true,
        width: '100px',
        renderCell: (props) => <RowStatusCell {...props}/>
      },
      {
        id: "created",
        label: "Created",
        sortable: true,
        width: '150px',
        renderCell: (props) => <DateCell {...props}/>
      },
    ]
  }, [replaceRow]);

  const rowProps = (row) => {
    const props = {};
    if (`${row.status_id}` === '2') {
      props.handleClick = undefined;
      props.className = 'bg-light-secondary text-muted'
    }
    return props;
  }

  return <DataTable
    headCols={headCols}
    idName={'id'}
    queryParams={queryParams}
    rows={rows}
    totalRows={totalRows}
    errorMessage={errorMessage}
    loading={loading}
    retry={retry}
    handleClickRow={handleClickRow}
    rowProps={rowProps}
  />
}

export default StrokeDataTable;
