import WidgetCard from "../common/partial/widget/WidgetCard";
import React, { useRef } from "react";
import Editor from "@monaco-editor/react";

const MonacoWidget = ({className, value, onChange, header}) => {
  const editorRef = useRef(null);
  function handleEditorChange(value, event) {
    try {
      JSON.parse(value);
      onChange(value);
    } catch (e) {
      //console.log(e);
    }
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    setTimeout(function() {
      editor.getAction('editor.action.formatDocument').run();
    }, 300);
  }

  return <WidgetCard header={header} className={className}>
    <Editor
      defaultLanguage="json"
      value={value}
      onMount={handleEditorDidMount}
      onChange={handleEditorChange}
      options={{
        formatOnPaste: true,
        formatOnType: true
      }}
    />
  </WidgetCard>
}

export default MonacoWidget;