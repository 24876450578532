import {components} from "react-select";
import React from "react";

const StateSingleValue = props => {
  return <components.SingleValue {...props}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.name}
    {/* eslint-disable-next-line react/prop-types */}
    {props.data.abbreviation ? <small className="text-muted"> | {props.data.abbreviation}</small> : null}
  </components.SingleValue>
}

export default StateSingleValue;
