import { SET_POSIBLE_FILTER_OPTIONS } from './actions';

const PossibleFilterOptionsReducer = (state, action) => {
  switch (action.type) {
    case SET_POSIBLE_FILTER_OPTIONS: {
      return {
        ...state,
        possibleFilterOptions: {
          ...state.possibleFilterOptions,
          ...action.data
        }
      }
    }
    default:
      return state
  }
}

export default PossibleFilterOptionsReducer
