import React, { useCallback, useEffect, useState } from 'react';
import WidgetCard from '../../common/partial/widget/WidgetCard';
import { useQueryStringFromState } from '../../../utils/queryString';
import useIsMounted from '../../../hooks/useIsMounted';
import SearchBar from 'material-ui-search-bar';
import DataTable from '../../common/datatable/DataTable';
import SiteNameCell from '../../site/datatable/cell/SiteNameCell';
import SiteAddressCell from '../../site/datatable/cell/SiteAddressCell';
import { Button, Col, Row } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

export default function FormConfigSitesList (props) {
  const history = useHistory();
  const {
    cardClassName = "card-stretch gutter-b",
    fetchRows,
    assigned,
    unsavedSites,
    setUnsavedSites
  } = props;

  const queryParams = useQueryStringFromState({
    orderType: 'desc',
    orderBy: 'site_id',
    page: 0,
    rowsPerPage: 25,
    searchOptions: {
      assigned,
      exclude: unsavedSites.map((site) => site.site_id)
    }
  });

  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!searchString) {
      queryParams.setSearchOptions({
        assigned,
        exclude: unsavedSites.map((site) => site.site_id)
      });
    } else {
      queryParams.setSearchOption('name', searchString);
    }
  }, [searchString, queryParams.setSearchOptions, queryParams.setSearchOption]);

  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const fetchData = useCallback(() => {
    setLoading(true);
    fetchRows({queryString: queryParams.queryString}).then((response) => {
      let { results, total_rows } = response.data;
      if (isMounted()) {
        setTotalRows(total_rows);
        setRows(results);
      }
    }).catch((e) => {
      const {response} = e;
      let errors = response.data.errors;
      if (typeof errors !== 'object') {
        errors = {};
      }
      errors.table = "Unable to load entries.";
      setError(errors);
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, setTotalRows, setRows, queryParams.queryString, setLoading, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const headCols = [
    {
      id: "site_id",
      label: "Edit",
      sortable: false,
      renderCell: ({id, row}) => <Button className={'btn-light-primary'} onClick={() => {
        history.push('/site/edit/' + row[id]);
      }}><span className="svg-icon"><SVG
        src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
      /></span></Button>
    },
    {
      id: "common_name",
      label: "Site Name" ,
      sortable: true,
      renderCell: (props) => <SiteNameCell {...props}/>
    },
    {
      id: "address_1",
      label: "Address",
      renderCell: (props) => <SiteAddressCell {...props}/>
    },
    {
      id: "county",
      label: "County"
    }
  ];

  const handleAddRow = useCallback((row) => {
    setUnsavedSites((prev) => {
      if (prev.find((site) => site.site_id === row.site_id)) {
        return prev;
      }
      return [...prev, { ...row, assigned}];
    });
  }, [setUnsavedSites]);

  const handleRemoveRow = useCallback((row) => {
    setUnsavedSites((prev) => {
      return prev.filter((site) => site.site_id !== row.site_id);
    });
  }, [setUnsavedSites]);

  return <WidgetCard className={cardClassName}>
    <Row>
      <Col>
          {unsavedSites.map((row) => {
            let variant = 'secondary';
            if (assigned) {
              variant = `${assigned === row.assigned ? 'danger' : 'success'}`;
            } else {
              variant = `${assigned === row.assigned ? 'primary' : 'warning'}`;
            }
            let direction = row.assigned ? 'right' : 'left';

            const arrow = <span className={`svg-icon svg-icon-${variant}`}>
              <SVG src={toAbsoluteUrl(`/media/svg/icons/Navigation/Angle-double-${direction}.svg`)}/>
            </span>;

            return <Row
              key={row.site_id}
              onClick={() => handleRemoveRow(row)}
              className={`bg-light-${variant} cursor-pointer p-3`}
            >
              {!assigned
                ? <Col  xs={'auto'}>{arrow}</Col>
                : null}
              <Col>
                <SiteNameCell row={row} classNameAbbreviation={`label-outline-${variant}`}/>
              </Col>
              {assigned
                ? <Col xs={'auto'}>{arrow}</Col>
                : null}
            </Row>
          })}
      </Col>
    </Row>
    <Row>
      <Col xs={'auto'} className={'mt-6'}>
        <h4>{assigned ? "Assigned Sites" : "Unassigned Sites"}</h4>
      </Col>
      <Col className="mt-2">
        <SearchBar
          value={searchString}
          onRequestSearch={setSearchString}
          onCancelSearch={setSearchString}
          placeholder={"Search by Name"}
        />
      </Col>
    </Row>
    <DataTable
      headCols={headCols}
      idName={'site_id'}
      queryParams={queryParams}
      rows={rows}
      totalRows={totalRows}
      errorMessage={error.table}
      loading={loading}
      retry={fetchData}
      handleClickRow={handleAddRow}
      //rowProps={rowProps}
    />
  </WidgetCard>;
}