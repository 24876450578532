import React, { useContext, useState } from "react";
import SignIn from "../../components/auth/SignIn";
import ForgotPassword from "../../components/auth/ForgotPassword";
import FirstMultiFactor from "../../components/FirstMultiFactor";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import "../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { firebaseAuth } from "../../provider/auth/AuthProvider";
import { configContext } from "../../provider/config/ConfigProvider";
import { emailContext } from "../../components/auth/context";
import AuthLayout from "../../components/auth/AuthLayout";

const Auth = () => {
  const {authUser, hasEnoughFactors} = useContext(firebaseAuth);
  const config = useContext(configContext);

  const [email, setEmail] = useState('');

  return (
    <AuthLayout>
      <emailContext.Provider value={[email, setEmail]}>
        <Switch>
          {!!authUser && config.multiFactor.require ?
            <ContentRoute path="/auth/enroll" component={FirstMultiFactor}/>
            : null}
          {authUser && !hasEnoughFactors() ?
            <Redirect to="/auth/enroll" />
            : null}
          <ContentRoute
            path="/auth/sign-in"
            component={SignIn}/>
          <ContentRoute
            path="/auth/forgot-password"
            component={ForgotPassword}
            email={email}
            setEmail={setEmail} />
          {!authUser ? <Redirect to="/auth/sign-in" /> : null}
        </Switch>
      </emailContext.Provider>
    </AuthLayout>
  );
};

export default Auth;
