import React, { useCallback } from "react";
import {
  ariaDescribedByIds,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from "@rjsf/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/** The `CheckBoxWidget` is a widget for rendering boolean properties.
 *  It is typically used to represent a boolean.
 *
 * @param props - The `WidgetProps` for this component
 */
function OptionCheckboxWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
  >(props: WidgetProps<T, S, F>) {

  const {
    options,
    id,
    value,
    disabled,
    readonly,
    label,
    autofocus = false,
    onBlur,
    onFocus,
    onChange,
    required,
    rawErrors = [],
  } = props;

  const { enumOptions, emptyValue } = options;

  const checkedValue = (enumOptions || [{}, {}])[0].value;
  const uncheckedValue = (enumOptions || [{}, {}])[1].value;
  const checked = value === checkedValue ? checkedValue : value === uncheckedValue ? uncheckedValue : emptyValue;

  const checkedToValue = useCallback((checked: any) => {
    return checked ? checkedValue : uncheckedValue;
  }, [enumOptions]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(checkedToValue(event.target.checked)),
    [onChange, checkedToValue]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) =>
      onBlur(id, checkedToValue(event.target.checked)),
    [onBlur, id, checkedToValue]
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) =>
      onFocus(id, checkedToValue(event.target.checked)),
    [onFocus, id, checkedToValue]
  );

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Saved value: {`${value}`}
    </Tooltip>
  );

  const checkboxClassNames = [
    'checkbox',
    disabled || readonly ? "checkbox-disabled" : "",
    checkedValue === uncheckedValue ? 'checkbox-outline checkbox-outline-2x' : '',
    rawErrors.length > 0 ? 'text-danger' : '',
  ];
  const renderCheckbox = () => (
    <label
      className={checkboxClassNames.filter(str => str.length > 0).join(' ')}
      data-toggle="tooltip"
    >
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={checked === checkedValue}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
      />
      <span></span>
      &nbsp;{(enumOptions || [{}])[0].label}
    </label>
  );

  const renderToolTip = false;
  if (!renderToolTip) return renderCheckbox();

  return (
    <OverlayTrigger
      placement="auto-start"
      delay={{ show: 250, hide: 10 }}
      overlay={renderTooltip}
      trigger={['hover', 'focus']}
    >
      {renderCheckbox()}
    </OverlayTrigger>
  );
}

export default OptionCheckboxWidget;
