import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { convertServerTimeString } from "../../../../utils/helpers";
import date from "date-and-time";

const useUTC = false;

function DateCell ({id, row, format}) {
  const value = row[id];

  const formatString = useMemo(() => {
    if (typeof format !== 'string' || format === '') {
      return 'MM/DD/YYYY[\xa0]HH:mm';
    }
    return format.split(' ').join('[\xa0]'); // replace breakable space with non-breakable space
  }, [format])

  const dateToText = (dateString) => {
    const d = convertServerTimeString(dateString, useUTC);
    if (!d || Number.isNaN(d)) return '';
    return date.format(d, formatString);
  }

  const dateToRelativeText = (dateString) => {
    const d = convertServerTimeString(dateString, useUTC);
    if (!d || Number.isNaN(d)) return '';
    const now = new Date();
    const subtract = date.subtract(now, d);
    const seconds = Math.round(subtract.toSeconds());
    const minutes = Math.round(subtract.toMinutes());
    const hours = Math.round(subtract.toHours());
    const days = Math.round(subtract.toDays());
    if (seconds < 60) {
      return `${seconds}\xa0second${seconds !== 1 ? 's' : ''}\xa0ago.`;
    } else if (minutes < 60) {
      return `${minutes}\xa0minute${minutes !== 1 ? 's' : ''}\xa0ago.`;
    } else if (hours < 48) {
      return `${hours}\xa0hour${hours !== 1 ? 's' : ''}\xa0ago.`;
    } else if (days < 99) {
      return `${days}\xa0day${days !== 1 ? 's' : ''}\xa0ago.`;
    } else {
      let years = now.getFullYear() - d.getFullYear();
      const month = now.getMonth() - d.getMonth();
      if (years < 4) {
        const months = Math.floor(month + years * 12);
        return `${months}\xa0month${months !== 1 ? 's' : ''}\xa0ago.`;
      } else {
        if (month < 0 || (month === 0 && now.getDate() < d.getDate())) {
          years--;
        }
        years = Math.floor(years);
        return `${years}\xa0years\xa0ago.`
      }
    }
  }

  return <>
    <Row>
      <Col>
        {dateToText(value)}
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Text muted>
          {dateToRelativeText(value)}
        </Form.Text>
      </Col>
    </Row>
  </>

}

export default DateCell;
