import React, { useState, useContext } from "react";
import { firebaseAuth } from "../../provider/auth/AuthProvider";
import { emailContext } from "./context";

import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl } from "react-intl";

import { Link } from "react-router-dom";
import { forgotPassword } from "../../services/auth";

const uniqueError = (value, index, self) => {
  return self.indexOf(value) === index;
}

const ForgotPassword = (props) => {
  const { intl } = props;
  const [email, setEmail] = useContext(emailContext);
  const initialValues = {
    email: email || "",
    password: "",
  };

  const { errors } = useContext(firebaseAuth);

  const [loading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await forgotPassword(values);
      props.history.push("/");
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Forgot Password?</h3>
        <p className="text-muted font-weight-bold">
          {" "}
          Enter your email to reset your password{" "}
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
            onChange={(e) => {
              const {value} = e.target;
              formik.setFieldValue("email", value);
              if (typeof setEmail === 'function') setEmail(value);
            }}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/sign-in">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>

        {errors.length > 0
          ? errors.filter(uniqueError).map((error, index) => <p key={index} style={{ color: "red" }}>{error.message}</p>)
          : null}
      </form>
    </div>
  );
};

export default injectIntl(ForgotPassword);
