import { useCallback, useEffect } from "react";
import { service } from "../services/api-service";

const useApiService = ({
   url,
   method = 'get',
   queryString,
   body = null,
   headers = null,
}) => {

  return useCallback((config = undefined) => {
    // config from parameters will overwrite _config from props
    const _config = { url, queryString, method };
    if (headers) _config.headers = headers;
    if (body) _config.data = body;
    if (typeof config === 'object') Object.assign(_config, config);
    if (_config.queryString) {
      _config.url = _config.url + _config.queryString;
      delete _config.queryString;
    }
    return service(_config);
  }, [url, queryString, method, body, headers]);
};

export default useApiService;
