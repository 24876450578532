import { SET_DEFAULT_SEARCH_VALUE } from './actions';

const DefaultSearchValueReducer = (state, action) => {
  switch (action.type) {
    case SET_DEFAULT_SEARCH_VALUE: {
      return {
        ...state,
        defaultSearchValues: {
          ...state.defaultSearchValues,
          ...action.data
        }
      }
    }
    default:
      return state
  }
}

export default DefaultSearchValueReducer
