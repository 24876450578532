import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { makeStyles } from "@material-ui/core/styles";
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls";
import useDebounce from "../../../hooks/useDebounce";
import useIsMounted from "../../../hooks/useIsMounted";

const useStyles = makeStyles(() => ({
  invalid: {
    border: "1px solid #F64E60",
    borderRadius: "5px"
  }
}));

export default function SearchableSelect({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "select",
  shouldValidate = true,
  defaultOptions,
  loadOptions,
  components,
  loadValue,
  getOptionLabel,
  getOptionValue,
  ...props
}) {
  const isMounted = useIsMounted();
  const classes = useStyles();
  const isInvalid = () => {
    return errors[field.name] && touched[field.name];
  };

  const [inputValue, setInputValue] = useState('');
  const loadOptionsDebounced = useDebounce(
    (nextValue, callback) => loadOptions(nextValue, callback),
    500,
  );

  const [cachedOptions, setCachedOptions] = useState([]);
  useEffect(() => {
    setCachedOptions((prevState) => {
      const addOptions = defaultOptions.filter((option) => {
        return !prevState.find((prev) => getOptionValue(prev) === getOptionValue(option))
      });
      return prevState.concat(addOptions);
    });
  }, [defaultOptions]);

  const [value, setValue] = useState('');
  useEffect(() => {
    if (!field.value) {
      setValue('');
    } else {
      const found = cachedOptions.find((option) => getOptionValue(option) === field.value);
      if (found) {
        setValue(found);
      } else {
        loadValue(field.value).then((res) => {
          if (isMounted()) setValue(res);
        }).catch(console.log);
      }
    }
  }, [field.value, cachedOptions, setValue]);

  const handleOnChange = useCallback((option) => {
    setFieldValue(field.name, option ? getOptionValue(option) : '');
  }, [setFieldValue, field.name]);

  return (
    <>
      {label && <label>{label}</label>}
      <AsyncSelect
        custom
        required
        isClearable
        as={AsyncSelect}
        className={'w-100' + (isInvalid() ? ` is-invalid ${classes.invalid}` : '')}
        {...field}
        {...props}
        inputValue={inputValue}
        onInputChange={setInputValue}
        defaultOptions={defaultOptions}
        loadOptions={loadOptionsDebounced}
        components={components || undefined}
        value={value}
        onChange={handleOnChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
      {withFeedbackLabel && shouldValidate && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}