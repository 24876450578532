import { FocusEvent, useCallback, useMemo } from "react";
import {
  ariaDescribedByIds,
  enumOptionsIsSelected,
  enumOptionsValueForIndex,
  optionId,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps, enumOptionsIndexForValue
} from "@rjsf/utils";

/** The `RadioWidget` is a widget for rendering a radio group.
 *  It is typically used with a string property constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */
function OptionRadioWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  options,
  value,
  required,
  disabled,
  readonly,
  autofocus = false,
  onBlur,
  onFocus,
  onChange,
  id,
  className,
  schema,
}: WidgetProps<T, S, F>) {
  const {['default']: defaultValue} = schema;
  const { enumOptions, enumDisabled, enumHidden, inline, emptyValue } = options;
  const handleBlur = useCallback(
    ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
      onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue)),
    [onBlur, id]
  );

  const handleFocus = useCallback(
    ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
      onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue)),
    [onFocus, id]
  );

  const defaultSelectable = useMemo(() => {
    const indexForDefault = enumOptionsIndexForValue<S>(defaultValue, enumOptions, false);
    if (typeof indexForDefault === 'undefined') {
      return false;
    }
    const indexForHidden = enumOptionsIndexForValue<S>(enumHidden, enumOptions, true);
    return !(Array.isArray(indexForHidden) && !Array.isArray(indexForDefault) && indexForHidden.includes(indexForDefault));

  }, [enumOptions, defaultValue, enumHidden])

  return (<>
    {!defaultSelectable && !required && !disabled && !readonly && !enumOptionsIsSelected<S>(defaultValue, value)
      ? <span className="label label-outline-dark label-inline mx-2 mb-2" onClick={() => {
          onChange(defaultValue);
        }}>Clear Selection</span>
      : null}
    <div className={inline ? 'radio-inline' : 'radio-list'} id={id}>
      {Array.isArray(enumOptions) &&
        enumOptions.filter((option) => {
          return !(Array.isArray(enumHidden) && enumHidden.indexOf(option.value) !== -1);
        }).map((option, i) => {
          const checked = enumOptionsIsSelected<S>(option.value, value);
          const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(option.value) !== -1;
          const disabledCls = disabled || itemDisabled || readonly ? 'disabled' : '';

          const handleChange = () => onChange(option.value);


           /*
          return inline ? (
            <label key={i} className={`radio-inline ${disabledCls}`}>
              {radio}
            </label>
          ) : (//*/return (
            <label key={i} className={`radio ${className || ''} ${disabledCls}`}>
              <input
                type="radio"
                id={optionId(id, i)}
                checked={checked}
                name={id}
                required={required}
                value={String(i)}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && i === 0}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                aria-describedby={ariaDescribedByIds<T>(id)}
              />
              <span></span>
              {readonly ? <s>{option.label}</s> : option.label}
            </label>
          );
        })}
    </div></>
  );
}

export default OptionRadioWidget;