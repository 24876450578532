import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const propTypes = {
  fields: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

export function VerifyCodeInput(props) {
  const { fields, onComplete, onReset, type, autoFocus, isInvalid, disabled } = props;
  const [value, setValue] = useState(new Array(fields).fill(''));
  const digitRefs = useRef({});

  const length = (v) => {
    if (!Array.isArray(v)) v = value;
    for (let i = 0; i < v.length; i++) {
      if (v[i] === '' || !v[i]) {
        return i;
      }
    }
    return v.length;
  };

  const addValue = (replace) => {
    const l = length();
    const r = replace.length;
    if (l === value.length) {
      const offset = 1; // in this case, the first character or replace will be included so we will ignore it
      setValue(
        value.map((c, i) => {
          if (i - r + offset < 0) {
            return replace[i + offset];
          } else {
            return '';
          }
        }),
      );
      if (onReset) onReset();
    } else {
      setValue(
        value.map((c, i) => {
          if (i > l - 1 && i - r < l) {
            return replace[i - l];
          } else {
            return c;
          }
        }),
      );
    }
  };

  const removeValue = () => {
    const l = length();
    if (l === value.length) {
      setValue(value.map(() => ''));
      if (onReset) onReset();
    } else if (l > 0) {
      setValue(
        value.map((c, i) => {
          if (i === l - 1) {
            return '';
          } else {
            return c;
          }
        }),
      );
    }
  };

  const keyPress = (event) => {
    const k = event.keyCode;
    if (k === 8) {
      removeValue();
    }
  };

  const onValue = (value) => {
    if (typeof value === 'string' && value.length > 0) {
      let replace = '';
      for (let i = 0; i < value.length; i++) {
        const c = value[i];
        if (/[0-9]/.test(c)) {
          replace += c;
        } else if (type === 'text' && /[A-Za-z]/.test(c)) {
          replace += c.toUpperCase();
        }
      }
      addValue(replace);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      digitRefs.current[0].focus();
    }
  }, [disabled]);

  useEffect(() => {
    const l = length();
    if (l >= 0 && l < value.length) digitRefs.current[l].focus();
    if (l === value.length) onComplete(value.join(''));
  }, [value]);

  return (
    <Grid
      container
      spacing={1}
    >
      {value.map((character, position) => {
        const getRef = (e) => (digitRefs.current[position] = e);
        return (
          <Grid item xs={2} key={position}>
            <TextField
              onFocus={() => {
                const ref = digitRefs.current[length()];
                if (ref) ref.focus();
              }}
              inputRef={getRef}
              type={type}
              value={character}
              disabled={disabled}
              onChange={(e) => onValue(e.target.value)}
              onKeyDown={keyPress}
              variant='outlined'
              error={isInvalid}
              inputProps={{min: 0, style: { textAlign: 'center',  fontSize: '2em'}}}
            />
          </Grid>
        );
      })}
      {isInvalid ? (
        <Grid item xs={12}>
          Incorrect code. Please try again.
        </Grid>
      ) : null}
    </Grid>
  );
}
VerifyCodeInput.propTypes = propTypes;
