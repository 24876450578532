import { AuthReducer } from "./auth";
import { ParticipantReducer } from "./participant";
import { QueryParamsReducer } from './queryParams';
import { NotificationReducer } from './notification';
import { LoadingReducer } from "./loading";
import { UserReducer } from './user';
import { PossibleFilterOptionsReducer } from './posible-filter-options';
import { DefaultSearchValueReducer } from "./default-search-values";
import { MultiFormReducer } from "./multiForm";

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop](
          {
            [prop]: acc[prop],
          },
          action
        ),
      };
    }, state);
  };
};

const reducer = combineReducers({
  auth: AuthReducer,
  participants: ParticipantReducer,
  user: UserReducer,
  queryParams: QueryParamsReducer,
  notification: NotificationReducer,
  isLoading: LoadingReducer,
  possibleFilterOptions: PossibleFilterOptionsReducer,
  defaultSearchValues: DefaultSearchValueReducer,
  multiForm: MultiFormReducer,
});

export default reducer;
