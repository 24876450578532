import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApiService from "../../hooks/useApiService";
import useAxiosResponse from "../../hooks/useAxiosResponse";
import useQueryParamStore from "../../hooks/useQueryParamStore";
import FormViewWidget from "../../components/multiForm/form/FormViewWidget";
import WidgetCard from '../../components/common/partial/widget/WidgetCard';
import { Button, Col, Row } from 'react-bootstrap';
import { useNotification } from '../../utils/customHooks';
import useLogger from '../../hooks/useLogger';
import ConfigSelect from '../../components/stroke/ConfigSelect';
import SiteSelect from '../../components/stroke/SiteSelect';

export default function StrokeFormPage () {
  const {id} = useParams();
  const history = useHistory();
  const queryParams = useQueryParamStore('stroke', 'SET_STROKE_QP', ['site_id', 'config_id']);
  const formUrl = `/multiform/stroke/form${id ? '/' + id : ''}`;
  const schemaUrl = '/multiform/stroke/form/';
  const saveMethod = id ? 'patch' : 'post';
  const [formData, setFormData] = useState(id ? null : {});
  const loading = useRef(false);
  const [loadingState, setLoadingState] = useState(false);
  const setNotification = useNotification();
  const logger = useLogger();

  const setForm = useCallback((newFormData) => {
    setFormData(newFormData);
  }, [setFormData]);

  const fetchSchemaProps = {url: schemaUrl, queryString: queryParams.queryString}
  const fetchSchema = useApiService(fetchSchemaProps);
  const [responseSchema, retrySchema] = useAxiosResponse(fetchSchema, true);
  const hasError = !responseSchema.loading && responseSchema.status !== 200 && !loadingState && responseSchema.error;

  const formService = useApiService({url: formUrl});

  const load = useCallback(() => {
    if (loading.current) return;
    loading.current = true;
    setLoadingState(true);
    formService({queryString: queryParams.queryString}).then((response) => {
      const {formData, formProgressRow} = response.data;
      queryParams.setSearchOptions({
        site_id: formProgressRow.site_id,
        config_id: formProgressRow.config_id
      });
      setFormData(formData);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      loading.current = false;
      setLoadingState(false);
    })
  }, [formService, setFormData, loading, setLoadingState, queryParams.setSearchOptions]);

  useEffect(() => {
    if (id) load();
  }, [id, load]);

  const save = useCallback((data, submit = false) => {
    if (loading.current) return;
    loading.current = true;
    setLoadingState(true);
    const urlSearchParams = new URLSearchParams();
    const { site_id, config_id } = queryParams.searchOptions;
    if (site_id) {
      urlSearchParams.set('site_id', site_id);
    }
    if (config_id) {
      urlSearchParams.set('config_id', config_id);
    }
    if (submit) {
      urlSearchParams.set('submit', 'true');
    }

    formService({
      method: saveMethod,
      queryString: '?' + urlSearchParams.toString(),
      data
    }).then((response) => {
      const {formData, formProgressRow} = response.data;
      setFormData(formData);

      if (formProgressRow.status_id === 2) {
        history.push('/stroke/form');
        setNotification({
          type: "success",
          message: "Submit successful."
        });
      } else if (formProgressRow.id && !id) {
        history.replace(`/stroke/form/edit/${formProgressRow.id}${history.location.search}`);
        setNotification({
          type: "success",
          message: "Create successful."
        });
      } else {
        setNotification({
          type: "success",
          message: "Save successful."
        });
      }
    }).catch((e) => {
      console.error(e.response);
    }).finally(() => {
      loading.current = false;
      setLoadingState(false);
    })
  }, [formService, queryParams.searchOptions, loading, history, setNotification, id, saveMethod]);

  const onSubmit = useCallback(() => {
    save(formData, true);
  }, [save, formData]);

  const onSave = useCallback(() => {
    save(formData, false);
  }, [save, formData]);

  const onCancel = useCallback(() => {
    history.push('/stroke/form');
  }, [history]);

  useEffect(() => {
    if (hasError) {
      logger({...responseSchema.error}, 'StrokeFormLoadFailed');
    } else {
      logger(fetchSchemaProps, 'StrokeFormLoadSuccess');
    }
  }, [hasError, logger, responseSchema.error, fetchSchemaProps]);

  if (hasError) {
    const { data: responseData } = (responseSchema.error || {}).response || {};

    return <WidgetCard className={'bg-light-danger text-danger'}>
      <Row className={'text-dark'}>
        <Col>
          <ConfigSelect url={'/multiform/stroke/form/configs'} hasError={!!responseData.config_id}/>
        </Col>
        <Col >
          <SiteSelect url={'/multiform/stroke/form/sites'} hasError={!!responseData.site_id}/>
        </Col>
      </Row>
      <Row className={'mt-6'}>
        <Col xs={'auto'}>
          {responseData.site_id || responseData.config_id
            ? <>
                {responseData.site_id ? <>Must a select a site to submit to<br/></> : null}
                {responseData.config_id ? <>Must a select a form to use<br/></> : null}
              </>
            : (responseSchema.response || {}).message || 'An error occurred when loading the form.'}
        </Col>
        <Col xs={'auto'}>
          <Button
            className={'btn-outline-danger ml-2'}
            onClick={retrySchema}
          >
            Retry
          </Button>
        </Col>
      </Row>
    </WidgetCard>
  }

  return <>
    <FormViewWidget
        jsonSchema={(responseSchema.response || {}).jsonSchema}
        uiSchema={(responseSchema.response || {}).uiSchema}
        setFormData={setForm}
        formData={formData}
        onSubmit={onSubmit}
        onSave={onSave}
        onCancel={onCancel}
        loading={loadingState || hasError}
      />
  </>;
}
