import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import useIsMounted from '../../../hooks/useIsMounted';
import useApiService from '../../../hooks/useApiService';
import DataTableHeader from '../../../components/common/datatable/DataTableHeader';
import { Card } from 'react-bootstrap';
import FormConfigDataTable from '../../../components/multiForm/datatable/FormConfigDataTable';
import { useQueryStringFromState } from '../../../utils/queryString';

export default function FormConfigTablePage () {
  const history = useHistory();
  const urlRows = '/multiform/schema';
  const isMounted = useIsMounted();
  const queryParams = useQueryStringFromState();
  const fetchRows = useApiService({url: urlRows});
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    fetchRows().then((response) => {
      let {results, total_rows} = response.data;
      if (isMounted()) {
        setTotalRows(total_rows);
        setRows(results);
      }
    }).catch((e) => {
      const {response} = e;
      let errors = response.data.errors;
      if (typeof errors !== 'object') {
        errors = {};
      }
      errors.table = "Unable to load entries.";
      setError(errors);
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [fetchRows, isMounted, setTotalRows, setRows, setLoading, setError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickRow = useCallback((row) => {
    history.push(`/form/schema/${row.name}`);
  }, [history]);


  return <div>
    <Card className={'w-100'}>
      <DataTableHeader label={"Form Configs"} ></DataTableHeader>
      <FormConfigDataTable
        rows={rows}
        replaceRow={() => {}}
        queryParams={queryParams}
        totalRows={totalRows}
        loading={loading}
        errorMessage={error.table}
        handleClickRow={handleClickRow}
      />
    </Card>
  </div>;
}