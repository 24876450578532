import { service } from "./api-service";

export async function getSpreadsheetConfig() {
  return service({
    method: 'get',
    url: `/upload/options`,
  });
}

export function getSingleUpload(id) {
  return service({
    url: "/upload/" + id,
    method: "get",
    responseType: "blob"
  });
}

export const deleteUpload = (id) => {
  return service({
    url: "/upload/" + id,
    method: "delete",
  });
}


export function uploadForm (values, files, url, progressCallback) {
  const formData = new FormData();
  Object.entries(values).forEach(([k,v]) => {
    formData.append(k,v);
  });
  return service({
    method: 'post',
    data: formData,
    files: files.map((key) => values[key]),
    url,
    onUploadProgress: function (e) {
      if (typeof progressCallback === 'function') {
        progressCallback(e)
      }
    }
  })
}

export async function uploadProgress (importProgress) {
  return service({
    method: 'get',
    url: `/multiform/stroke/upload/${importProgress.id}`,
  });
}

export async function getStatus () {
  return service({
    method: 'get',
    url: `/upload/status`,
  });
}

export async function setState (str) {
  return service({
    method: 'post',
    data: {state: str},
    url: `/upload/status`,
  });
}

const UploadService = {
  uploadProgress,
  uploadForm
}

export default UploadService;
