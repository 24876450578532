import React, { useContext } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { configContext } from "../../provider/config/ConfigProvider";

const AuthLayout = ({children}) => {
  const config = useContext(configContext);
  return <div className="d-flex flex-column flex-root full-height">
    <div
      className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/auth-bg.jpg")})`,
        }}
      >
        <div className="d-flex flex-row-fluid flex-column justify-content-center">
          <Link to="/" className="flex-column-auto mt-5">
            <img
              alt="Logo"
              className="max-h-70px"
              src={toAbsoluteUrl(config.branding.site_logo)}
            />
          </Link>
        </div>
      </div>

      <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        </div>
        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          {children}
        </div>
      </div>
    </div>
  </div>;
}
export default AuthLayout;
