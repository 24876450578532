import React, { useCallback, useEffect, useRef, useState } from "react";
import { getStatus, setState } from "../../services/uploads";
import useIsMounted from "../../hooks/useIsMounted";
import useInterval from "../../hooks/useInterval";
import {Row, Col} from "react-bootstrap";
import StatusWidget from "../../components/upload/StatusWidget";

const Status = () => {

  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const [status, setStatus] = useState({});

  const refresh = useCallback(() => {
    if (loading || !isMounted()) return;
    return getStatus().then((response) => {
      if (isMounted()) setStatus(response.data);
    }).catch((e) => {
      console.log(e);
    })
  }, [loading, isMounted, setStatus]);

  const set = useCallback((state) => {
    if (loading.current || !isMounted()) return;
    setLoading(true);
    return setState(state).then((response) => {
      if (isMounted()) setStatus(response.data);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [loading, setLoading, isMounted, setStatus, refresh]);

  useInterval(refresh, 1200);

  return <>
    <Row>
      <Col xs={'6'}>
        <StatusWidget
          data={status}
          handleClick={(e) => {
            e.currentTarget.blur();
            e.preventDefault();
            set(status.paused ? 'start' : 'stop');
          }}
          loading={loading}
        />
      </Col>
    </Row>
  </>;
}

export default Status;
