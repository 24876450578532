import React, { useEffect, useState } from "react";
import {LayoutSplashScreen} from "../../_metronic/layout";
import { service } from "../../services/api-service";
import useAxios from "../../hooks/useAxios";
import useAxiosResponse from "../../hooks/useAxiosResponse";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTHDOMAIN,
  REACT_APP_FIREBASE_DATABASEURL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGEBUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENTID,
  REACT_APP_GOOGLE_MAPS_API,
  REACT_APP_CLIENT_BASE_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_REGISTER_BASE_URL,
  REACT_APP_FAVICON,
  REACT_APP_SITE_TITLE,
  REACT_APP_SITE_LOGO,
  REACT_APP_SESSION_TIMEOUT,
  REACT_APP_TIMEOUT_TOLERANCE,
  REACT_APP_USE_BILLING_CODE,
  REACT_APP_POLLING_SELF_ROLES_TIME_INTERVAL,
  REACT_APP_SUPPORT_EMAIL,
  REACT_APP_SUPPORT_PHONE,
  REACT_APP_ENABLE_2FA_PHONE,
  REACT_APP_REQUIRE_2FA,
} = process.env;

const defaultConfig = {
  firebase: {
    apiKey: REACT_APP_FIREBASE_API_KEY,
      authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
      databaseURL: REACT_APP_FIREBASE_DATABASEURL,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
      messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENTID,
  },
  google_maps: REACT_APP_GOOGLE_MAPS_API,
    multiFactor: {
    enable: REACT_APP_ENABLE_2FA_PHONE === "true",
    require: REACT_APP_ENABLE_2FA_PHONE === "true" && REACT_APP_REQUIRE_2FA === "true"
  },
  url: {
    client: REACT_APP_CLIENT_BASE_URL,
    api: REACT_APP_API_BASE_URL,
    register: REACT_APP_REGISTER_BASE_URL,
  },
  branding: {
    site_favicon: REACT_APP_FAVICON,
    site_logo: REACT_APP_SITE_LOGO || '',
    site_title: REACT_APP_SITE_TITLE,
  },
  session: {
    timeout: parseInt(REACT_APP_SESSION_TIMEOUT),
    timeoutTolerance: parseInt(REACT_APP_TIMEOUT_TOLERANCE),
  },
  appSettings: {
    pollingSelfRolesTimeInterval: parseInt(REACT_APP_POLLING_SELF_ROLES_TIME_INTERVAL)
  },
  functions: {
    billingCode: REACT_APP_USE_BILLING_CODE === "true"
  },
  supportContact: {
    email: REACT_APP_SUPPORT_EMAIL,
    phone: REACT_APP_SUPPORT_PHONE
  },
}


export const configContext = React.createContext(defaultConfig);
export const withConfig = (Component) => {
  return (props) => {
    return <configContext.Consumer>
      {config => <Component {...props} config={config}/>}
    </configContext.Consumer>
  }
};

const ConfigProvider = ({children}) => {
  const [config, setConfig] = useState(defaultConfig);

  const fetchData = useAxios({url: 'config.json', method: 'get'});
  const [{response, error}] = useAxiosResponse(fetchData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(response) {
      const config = {
        client: {
          clientTime: new Date(),
          serverTime: new Date(response.serverTime)
        },
        ...response
      };
      service.defaults.baseURL = config.url.api;
      localStorage.setItem('baseUrl', config.url.client);
      setConfig(config);
      setLoading(false);
    }
  }, [response]);

  return <>
    <LayoutSplashScreen visible={loading}/>
    {!error && !loading ? <configContext.Provider value={config}>{children}</configContext.Provider> : null}
    {error && !loading ? <>Failed to load configuration. Please reload the page.</> : null}
  </>

}

export default ConfigProvider;
