import React, { useCallback, useEffect, useRef, useState } from "react";
import WidgetCard from "./WidgetCard";
import { Col, Row, Nav } from "react-bootstrap";

const NavWidget = ({
  items = [],
  cardClassName = "card-stretch gutter-b",
  navClassName = "nav-success nav-pills",
  children
}) => {
  const [active, setActive] = useState(0);

  return <>
    <WidgetCard className={cardClassName}>
      <Row>
        <Col>
        <ul className={`nav ${navClassName} mb-n3 mr-auto`}>
          {items.map((item, i) => {
            let className = "nav-link";
            if (i === active) className += ' active';
            if (item.disabled) className += ' disabled';
            return <li key={item.label} className="nav-item" onClick={() => setActive(i)}>
              <p className={className}>
                {item.label}
              </p>
            </li>
          })}
        </ul>
        </Col>
        {children}
      </Row>
    </WidgetCard>
    {items[active].content}
  </>
}

export default NavWidget;
