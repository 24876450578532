import HiddenConstWidget   from "./HiddenConstWidget";
import OptionCheckboxWidget from "./OptionCheckboxWidget";
import OptionRadioWidget from "./OptionRadioWidget";
import OptionSelectWidget from "./OptionSelectWidget";

export default {
  HiddenConstWidget,
  OptionCheckboxWidget,
  OptionRadioWidget,
  OptionSelectWidget,
};
