import React from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import SignUp from "../../components/auth/SignUp";


const Register = () => {
  return <AuthLayout>
    <SignUp/>
  </AuthLayout>
}

export default Register
