import React, { useContext } from "react";

import {Route, Switch} from 'react-router-dom';

import Auth from "./pages/auth/Auth";
import Register from "./pages/register/Register";
import Layout from "./pages/Layout";

import { I18nProvider } from "./_metronic/i18n";
import { MaterialThemeProvider } from "./_metronic/layout";
import {firebaseAuth} from './provider/auth/AuthProvider';

import Alert from './components/common/Alert';

import Error from "./pages/auth/Error";
import Help from "./pages/help/Help";
import FirebaseEmailAction from "./pages/auth/FirebaseEmailAction";
import Preview from './pages/preview/Preview';

function App() {
	const { token, hasEnoughFactors } = useContext(firebaseAuth)

	let jwt = token || localStorage.token;

	const loggedIn = () => {
		return !!jwt && hasEnoughFactors();
	}

	return (
		<MaterialThemeProvider>
			<I18nProvider>
				<Switch>
					<Route path="/help">
						<Help isWholePage={true} />
					</Route>
					<Route path="/error" component={Error} />
					<Route path="/logging-out" component={Error} />
					<Route path="/firebase" component={FirebaseEmailAction}/>
					<Route path="/register" component={Register}/>
					<Route path="/preview/:name" component={Preview}/>
					<Route path='/' render={rProps => loggedIn() ? <Layout /> : <Auth />} />
					<Route path="/auth" component={Auth} />
				</Switch>
			</I18nProvider>
			<Alert />
		</MaterialThemeProvider>
	);
}
export default App;
