import {
  RJSFSchema,
  StrictRJSFSchema,
  FormContextType,
  FieldProps,
  deepEquals,
  ErrorSchema,
  PROPERTIES_KEY
} from "@rjsf/utils";
import React, { useCallback, useEffect, useMemo } from "react";
import usePrevious from "../../../hooks/usePrevious";
import _ from 'lodash';

export default function ConditionalObjectField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> (props: FieldProps<T, S, F>) {
  const {
    schema,
    idSchema,
    idSeparator = '/',
    formData,
    registry,
    onChange,
  } = props;

  const { fields, schemaUtils } = registry;
  const {ObjectField} = fields;

  const previousSchema = usePrevious(schema);

  const sanitizeData = useCallback((newSchema?: S, oldSchema?: S, formData?: T) => {
    if (!_.isObject(formData)) {
      return undefined;
    }
    if (!oldSchema || !newSchema || deepEquals(newSchema, oldSchema)) {
      return undefined;
    }
    let newFormData = schemaUtils.sanitizeDataForNewSchema(newSchema, oldSchema, formData);
    if (newFormData && newSchema) {
      if (_.isObject(newFormData)) {
        Object.keys(newFormData).forEach(key => {
          if (newFormData[key as keyof T] === undefined) {
            delete newFormData[key as keyof T];
          }
        });
      }
      // Call getDefaultFormState to make sure defaults are populated on change. Pass "excludeObjectChildren"
      // so that only the root objects themselves are created without adding undefined children properties
      newFormData = schemaUtils.getDefaultFormState(newSchema, newFormData, 'excludeObjectChildren') as T;
    }
    return newFormData;
  }, [schemaUtils]);

  useEffect(() => {
    const newFormData = sanitizeData(schema, previousSchema, formData);
    if (newFormData !== undefined) {
      const id = idSchema.$id;
      onChange(newFormData, undefined, id);
    }
  }, [schema, previousSchema, sanitizeData, formData, idSchema, onChange]);

  const rawSchema = useMemo( () => {
    const id = idSchema.$id;
    const idSplit = id.split(idSeparator);
    idSplit[0] = '';
    const joinProperties = idSplit.join(`${idSeparator}${PROPERTIES_KEY}${idSeparator}`);
    const schemaPath = joinProperties.split(idSeparator).slice(1);
    return _.get(registry.rootSchema, schemaPath) as S;
  }, [registry, idSeparator, idSchema]);

  const getNextSchema = useCallback((formData: T | undefined) => {
    return schemaUtils.retrieveSchema(rawSchema, formData);
  }, [schemaUtils, rawSchema]);

  const handleChange = useCallback((_formData: T | undefined, errorSchema?: ErrorSchema<T>, id?: string) => {
    let newFormData = _formData;
    let newSchema;
    if (_.isObject(newFormData)) {
      newSchema = getNextSchema(_formData);
      const result = sanitizeData(newSchema, schema, _formData);
      if (_.isObject(result)) {
        newFormData = result;
      }
    }

    //console.log('conditional handle change', { newFormData, newSchema }, { _formData, schema })
    return onChange(newFormData as T, errorSchema, id);
  }, [onChange, getNextSchema, schema, sanitizeData, schemaUtils ]);

   //if (['section_weight','section_bmi'].includes(props.name)) console.log('conditional render', {props}, props.formData)

  return <ObjectField
    {...props}
    onChange={handleChange}
    //schema={schema}
    //uiSchema={uiSchema}
  />;
}
