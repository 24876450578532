import React from "react";
import { Field, Form, Formik } from "formik";
import File from "../../common/form/File";
import SubmitListener from "../../common/SubmitListener";
import * as Yup from "yup";

const SUPPORTED_FORMATS = [
  'text/csv',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

const FILE_SIZE = 200000000; // 200 MB

const uploadSchema = {
  file: Yup.mixed()
    .test('fileSize', "File Size is too large", value => !value || value.size <= FILE_SIZE)
    .test('fileType', "Unsupported File Format", value => !value || SUPPORTED_FORMATS.includes(value.type))
    .required("File is required.")
};

const SpreadsheetUploadProgress = (props) => {
  const {
    handleSubmit,
  } = props;

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        file: ""
      }}
      validationSchema={Yup.object().shape(uploadSchema)}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {(formik) => (
        <>
          <Form className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="file"
                  component={File}
                  placeholder="Select file..."
                />
              </div>
            </div>
          </Form>
          <SubmitListener formik={formik}/>
        </>
      )}
    </Formik>
  );

}

export default SpreadsheetUploadProgress;
