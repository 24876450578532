import React, { useEffect, useState } from 'react';

export default function TableauVizFrame (props) {
  const {token, src} = props;
  const viz = React.useRef(null);
  return <tableau-viz
    ref={viz}
    id="tableauViz"
    token={token}
    src={src}
    toolbar="bottom"
    hide-tabs={true}>
  </tableau-viz>
}
