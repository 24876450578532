import React, { useCallback, useMemo, useState } from "react";
import ReactSelect from 'react-select';
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls";

const useStyles = makeStyles(() => ({
  invalid: {
    border: "1px solid #F64E60",
    borderRadius: "5px"
  }
}));

export default function Select({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "select",
  shouldValidate = true,
  options,
  getOptionLabel,
  getOptionValue,
  useOptionField = false,
  ...props
}) {
  const classes = useStyles();
  const isInvalid = () => {
    return errors[field.name] && touched[field.name];
  }

  const isObject = (obj) => {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  }

  const getOption = useCallback((fieldValue) => {
    if (isObject(fieldValue)) return fieldValue;
    if (Array.isArray(options)) {
      return options.find((option) => {
        const optionValue = getOptionValue(option);
        return optionValue === fieldValue;
      });
    }
    return undefined;
  }, [options, getOptionValue]);

  const setOption = useCallback((option) => {
    const value = useOptionField ? option || null : getOptionValue(option || {}) || '';
    return setFieldValue(field.name, value)
  }, [setFieldValue, field.name, useOptionField]);

  return (
    <>
      {label && <label>{label}</label>}
      <ReactSelect
        custom
        required
        isClearable
        as={ReactSelect}
        className={'w-100' + (isInvalid() ? ` is-invalid ${classes.invalid}` : '')}
        {...field}
        {...props}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        options={options}
        value={getOption(field.value) || ''}
        onChange={setOption}
      />
      {withFeedbackLabel && shouldValidate && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}