import React, { useMemo } from "react";
import Alert from '@material-ui/lab/Alert';
import { useHasRoles } from '../../utils/customHooks';
import { useStateValue } from "../../store/StateProvider";
import { LayoutSplashScreen } from "../../_metronic/layout";
import UnauthorizedAlert from "../auth/alert/UnauthorizedAlert";

export const RoleBasedRenderer = ({ requiredRoles, inSites, showWarning = true, children }) => {
  const isAuthorized = useHasRoles(requiredRoles, inSites);
  const [{ auth: { user } }] = useStateValue();
  const { noRoles, roles } = user;
  const rolesUnpopulated = useMemo(() => {
    if (roles === null) return true;
    return typeof roles === 'object' && Object.keys(roles).length === 0;
  }, [roles]);

  if (rolesUnpopulated && !noRoles) return <LayoutSplashScreen/>

  if (noRoles) {
    return <div>
      <Alert severity="success">You are logged in! Your account is awaiting approval.</Alert>
    </div>
  }

  return (
    <>
      { isAuthorized ? (
        children
      ) : showWarning ? (
        <div>
          <UnauthorizedAlert/>
        </div>
      ) : null }
    </>
  )
}
