import React, { useMemo, useState, useEffect, useCallback } from "react";
import { FieldArray, useFormikContext } from "formik";
import { buildQueryString } from "../../utils/queryString";
import UserService from "../../services/user";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import AsyncSelect from 'react-select/async';
import useDebounce from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  removeButton: {
    position: "relative",
    cursor: "pointer",
    color: "red",
    left: "-6px",
    top: "-11px",
    zIndex: "1",
  },
  item: {
    position: "relative",
    marginTop: "10px",
  },
}));

const groupReduce = (acc, cur) => {
  let found = false;
  let key = 'dashboard_group_id';
  acc = acc.map((prev) => {
    if (!cur[key]) return acc;
    if (`${prev[key]}` === `${cur[key]}`) {
      found = true;
      return cur;
    } else {
      return prev;
    }
  });
  if (found) return acc;
  return acc.concat(cur);
}

const UserTableauInput = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const [dashboardGroups, setDashboardGroups] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const updateDashboardGroups = useCallback((newGroups) => {
    if (!Array.isArray(newGroups)) newGroups = [newGroups];
    setDashboardGroups(prev => {
      return prev.reduce(groupReduce, newGroups);
    });
  }, [setDashboardGroups]);

  const searchDashboardGroups = useCallback((inputValue, callBack) => {
    const count = 100;
    const queryString = buildQueryString({
      orderType: 'asc',
      orderBy: 'dashboard_group_id',
      page: 0,
      rowsPerPage: count,
      searchOptions: {
        name: inputValue,
      }
    });
    UserService.getOptionsTableau(queryString).then(ret => {
      let {results, total_rows} = ret.data;
      const messages = [];
      if (total_rows > count) {
        messages.push({label: `And ${total_rows - count} more.`, isDisabled: true})
      }

      if (typeof callBack === "function") callBack(results.concat(messages));
      updateDashboardGroups(results);
      if (results.length === 0) setDisabled(true);
    }).catch(e => {
      console.log(e);
    })
  }, []);

  const loadOptionsDebounced = useDebounce(
    (nextValue, callback) => searchDashboardGroups(nextValue, callback),
    500,
  );

  useEffect(() => {
    searchDashboardGroups(undefined, undefined);
  }, [searchDashboardGroups]);

  if (disabled) return null;
  if (dashboardGroups.length === 0) return <><br/>loading</>;

  return (
    <FieldArray
      name={"userDashboardGroups"}
      render={({remove, push}) => (
        <>
          <Typography variant="h6">Tableau Dashboard Groups</Typography>
          <div className={`col-lg-12 mt-6 row ${classes.item}`}>
          {values.userDashboardGroups.map((group, index) => (
            <div
              key={index}
              className={`col-auto  mt-3`}
            >
              <span className="label label-xl label-inline font-weight-boldest label-rounded">
                {group.dashboard_group}&nbsp;
              </span>

              <span
                className={classes.removeButton}
                onClick={() => {
                  remove(index);
                }}
              >
                <RemoveCircle />
              </span>
            </div>
          ))}
          </div>
          <div className={`col-lg-12 mt-6 row ${classes.item}`}>
            <div className={`col-6`}>
              <AsyncSelect
                placeholder={`Add Groups`}
                inputValue={inputValue}
                onInputChange={(e) => setInputValue(e)}
                defaultOptions={dashboardGroups}
                loadOptions={loadOptionsDebounced}
                filterOption={option => {
                  return !values.userDashboardGroups.find(group => `${group.dashboard_group_id}` === `${option.value}`);
                }}
                value={''}
                onChange={(option) => {
                  push(option)
                }}
                getOptionLabel={option => option.dashboard_group}
                getOptionValue={option => option.dashboard_group_id}
              />
            </div>
          </div>
        </>
      )}
    />
  );
}

export default UserTableauInput;
