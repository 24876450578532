import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import UserDataTable from "../../components/user/UserDataTable";

import UserService, { getUserCSVFile } from "../../services/user";

import { useQueryString, extractSearchString, searchOptionsToSearchString } from '../../utils/queryString';
import { useStateValue } from '../../store/StateProvider';
import { SET_USER_QP } from '../../store/queryParams';
import { useDidUpdateEffect, useLoading, useNotification } from '../../utils/customHooks';
//import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import DataTableHeader from "../../components/common/datatable/DataTableHeader";
import { Col, Row, Button, Card } from "react-bootstrap";
import SearchBar from "material-ui-search-bar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import useQueryParamStore from "../../hooks/useQueryParamStore";
import useIsMounted from "../../hooks/useIsMounted";
import useApiService from "../../hooks/useApiService";
import UserRACFilter from '../../components/user/datatable/filter/UserRACFilter';
import DataTableSearch from '../../components/common/datatable/DataTableSearch';
import UserArchiveFilter from '../../components/user/datatable/filter/UserArchiveFilter';


const PAGE_NAME = 'user';

const User = () => {
	const history = useHistory();
	const urlRows = '/user/list';
	const isMounted = useIsMounted();
	const queryParams = useQueryParamStore(PAGE_NAME, 'SET_USER_QP', []);
	const fetchRows = useApiService({url: urlRows, queryString: queryParams.queryString});
	const setNotification = useNotification();
	const [totalRows, setTotalRows] = useState(0);
	const [rows, setRows] = useState([]);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [searchString, setSearchString] = useState('');

	useEffect(() => {
		if (!searchString) {
			queryParams.setSearchOption('searchString', undefined);
		} else {
			queryParams.setSearchOption('searchString', searchString);
		}
	}, [searchString, queryParams.setSearchOptions, queryParams.setSearchOption])

	const fetchData = useCallback(() => {
		setLoading(true);
		fetchRows().then((response) => {
			let {results, total_rows} = response.data;
			if (isMounted()) {
				setTotalRows(total_rows);
				setRows(results);
			}
		}).catch((e) => {
			const {response} = e;
			let errors = response.data.errors;
			if (typeof errors !== 'object') {
				errors = {};
			}
			if (response.status === 400) {
				errors.table = "Additional filter required.";
			} else {
				errors.table = "Unable to load entries.";
			}
			setError(errors);
		}).finally(() => {
			if (isMounted()) {
				setLoading(false);
			}
		});
	}, [fetchRows, isMounted, setTotalRows, setRows, queryParams.queryString, setLoading, setError]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const updateUser = async (user) => {
		let res = await UserService.editUser(user);

		if(res && res.status === 200) {
			setNotification({
				type: "success",
				message: "User record has been updated"
			});
		}
	}

	const changeArchive = id => {
		let parsedRows = [...rows];

		let changedSite = parsedRows.find(item => {
			return item.user_id === id;
		});

		if(changedSite){
			changedSite.archive = !changedSite.archive;
			updateUser(changedSite);
		}

		setRows(parsedRows);
	}

	const handleClickRow = (row) => {
		history.push('/user/edit/' + row.user_id);
	}

	/*const handleExportUsers = () => {
		const csvExporter = new ExportToCsv({
			filename: 'users',
			showLabels: true,
			headers: [
				'User id',
				'First name',
				'Last name',
				'Phone',
				'Email',
				'Archive',
				'Created',
				'Last login',
				'Last login ip',
				'Welcome email sent',
			]
		});
		const data = rows.map(row => {
			return {
				user_id: row.user_id ?? "",
				first_name: row.first_name ?? "",
				last_name: row.last_name ?? "",
				phone: row.phone ?? "",
				email: row.email ?? "",
				archive: row.archive ?? "",
				created: row.created ? moment(row.created).format("MM/DD/YYYY") : "",
				last_login: row.last_login ?? "",
				last_login_ip: row.last_login_ip ?? "",
				welcome_email_sent: row.welcome_email_sent ?? "",
			}
		})
		csvExporter.generateCsv(data);
	}*/

	const handleExportAllRecordToCSV = async () => {
		setLoading(true);
		const response = await getUserCSVFile(queryParams.queryString);
		setLoading(false);
		const url = window.URL.createObjectURL(new Blob([response?.data], { type: response?.headers["content-type"] }));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'user.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Card className={'w-100'}>
			<DataTableHeader
				label={"Users"}
			>
				{/*<div className="col-lg-2 mt-6">
					<DropdownButton id="dropdown-basic-button" title="Export CSV">
						<Dropdown.Item
							onClick={() => {
								handleExportUsers();
							}}
						>
							Download {(rows || '').length.toLocaleString()} visible records below
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handleExportAllRecordToCSV()}>
							Download all {totalRows.toLocaleString()} records available
						</Dropdown.Item>
					</DropdownButton>
				</div>*/}
				<Col>
					<Row className="row">
						<Col className="mt-6" lg={8} xl={6}>
							<DataTableSearch
								value={searchString}
								onRequestSearch={setSearchString}
								onCancelSearch={setSearchString}
								//className={classes.searchBar}
								placeholder={"Search by name, email or phone" ?? "Search by Name"}
							/>
						</Col>
						<Col className="mt-6" lg={4} xl={6}>
							<Row className="row">
								<Col xs={12}>
									<UserRACFilter/>
								</Col>
								<Col>
									<UserArchiveFilter/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col className="mt-6" lg={2}>
					<Link to={'/user/create'}>
						<Button
							block
							color="secondary"
							//startIcon={<AddIcon />}
						>
							<span className="svg-icon">
								<SVG
									src={toAbsoluteUrl(`/media/svg/icons/Code/${"Plus.svg"}`)}
								/>
							</span>
							Create
						</Button>
					</Link>
				</Col>
			</DataTableHeader>

			<UserDataTable
				rows={rows}
				queryParams={queryParams}
				totalRows={totalRows}
				errorMessage={error.table}
				loading={loading}
				handleClickRow={handleClickRow}
				changeArchive={changeArchive}
			/>
		</Card>
  );
};

export default User;
