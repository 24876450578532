import { useRef, useEffect, useState, useMemo, useCallback, useContext } from "react";
import { useStateValue } from '../store/StateProvider';
import { SET_NOTIFICATION } from '../store/notification';
import { SET_IS_LOADING } from "../store/loading/index";
import { RoleEnum } from "../config/roles";
import UserService from "../services/user";
import { extractRoles } from "../firebase/authmethods";
import { configContext } from "../provider/config/ConfigProvider";
import { SET_AUTH_USER } from "../store/auth";
import { firebaseAuth } from '../provider/auth/AuthProvider';

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect((...args) => {
    if (didMountRef.current) fn(...args);
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};

export const useNotification = () => {
  const [{ notification }, dispatch] = useStateValue();
  const [notificationState, setNotification] = useState(notification);

  useEffect(() => {
    dispatch({
      type: SET_NOTIFICATION,
      notification: notificationState
    })
  }, [notificationState, dispatch]);

  return setNotification;
}

export const useLoading = () => {
  const [{ isLoading }, dispatch] = useStateValue();
  const [isLoadingState, setIsLoadingState] = useState(isLoading);

  useEffect(() => {
    dispatch({
      type: SET_IS_LOADING,
      isLoading: isLoadingState
    })
  }, [isLoadingState, dispatch]);

  return setIsLoadingState;
}

export const useHasRoles = (requiredRoles = [], inSites = []) => {
  const [{ auth: { user: { roles: userRoles } } }] = useStateValue();

  const siteFilter = useCallback((site) => {
    // if there are no required sites, then any site will do
    if (inSites.length === 0) return true;
    // otherwise, we must find the sites that are required
    return inSites.findIndex(x => `${x}` === `${site}` || `${site}` === '0') !== -1;
  }, [inSites])

  const isAuthorized = useMemo(() => {
    return [RoleEnum.PortalUser, ...requiredRoles].every(requiredRole => {
      // user need to have the demand for at least one site to access the option
      const sitesWithRoles = (userRoles || {})[requiredRole] || [];

      return sitesWithRoles.filter(siteFilter).length > 0;
    })
  }, [userRoles, requiredRoles, siteFilter]);

  return isAuthorized;
}

export const useSubscribeToSelfRoles = () => {
  const {getToken, handleSignout} = useContext(firebaseAuth);
  const timer = useRef();
  const config = useContext(configContext);
  const [, dispatch ] = useStateValue();

  const setRoles = useCallback((roles) => {
    const noRoles = Object.values(roles).every((sites) => {
      return Array.isArray(sites) && sites.length === 0;
    });
    dispatch({
      type: SET_AUTH_USER,
      user: {
        roles,
        noRoles
      }
    });
  }, [dispatch]);

  const refresh = useCallback(async () => {
    let retryCount = 0;
    const maxRetryCount = 5;
    const retryInterval = 1000;
    const errors = [];
    while (retryCount < maxRetryCount) {
      if (retryCount > 0) {
        const lastError = errors[0];
        const promises = [new Promise(res => setTimeout(res, retryInterval))];
        if (lastError.response.status === 401) {
          promises.push(getToken())
        }
        await Promise.all(promises)
          .then(console.log)
          .catch(console.error);
        console.error(lastError.response);
      }
      try {
        const userRolesRes = await UserService.getUserSelfRoles();
        const userRoles = extractRoles(userRolesRes.data);
        localStorage.setItem("roles", JSON.stringify(userRoles));
        setRoles(userRoles);
        return;
      } catch (e) {
        e.retryCount = retryCount++;
        errors.push(e);
      }
    }
    handleSignout();
  }, [setRoles]);

  useEffect(() => {
    refresh();
    timer.current = setInterval( () => {
      refresh();
    }, config.appSettings.pollingSelfRolesTimeInterval);

    return () => {
      timer.current = null;
    };
  }, [config.appSettings.pollingSelfRolesTimeInterval, refresh]);
}
