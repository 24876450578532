import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../_metronic/_partials/controls";
import { Checkbox } from "../common/form/Checkbox";
import PhoneNumber from '../common/phone-number-input';
import UserRolesInput from './UserRolesInput';
import UserTableauInput from "./UserTableauInput";
import { RoleBasedRenderer } from "../roles-based-renderer/RoleBasedRenderer";
import { RoleEnum } from "../../config/roles";
import { phoneRegExp, nameRegExp } from "../validations/regexp";
import UserMultiFactorEdit from "./UserMultiFactorEdit";
import UserPasswordInput from './UserPasswordInput';
import UserResendEmail from './UserResendEmail';
import UserErrors from './UserErrors';
import UserCreateFirebase from './UserCreateFirebase';
import UserTableauAccount from './UserTableauAccount';
import UserFirebaseAccount from './UserFirebaseAccount';

const UserFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, "Minimum 1 symbol")
    .max(250, "Maximum 250 symbols")
    .required("First Name is required")
    .matches(nameRegExp, "Invalid name"),
  last_name: Yup.string()
    .min(1, "Minimum 1 symbol")
    .max(250, "Maximum 250 symbols")
    .required("Last Name is required")
    .matches(nameRegExp, "Invalid name"),
  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .nullable(),
    //.required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  userRoles: Yup.array()
    .of(
      Yup.object().shape({
        site_id: Yup.string().required("Site is required")
      })
    )
});

const UserForm = (props) => {
  const { saveUser, formLabel, initUser } = props;
  const defaultValues = {
    user_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    userRoles: [],
    userDashboardGroups: [],
    userMultiFactors: []
  };
  const additionalInfo = {
    password: "",
    resend_email: ""
  };
  const initialValues = initUser ?? defaultValues;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{...initialValues, ...additionalInfo}}
      validationSchema={UserFormSchema}
      onSubmit={(values) => {
        saveUser(values, initUser);
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Modal.Body>
            <Typography variant="h6" id="subtitle1">
              {formLabel}
            </Typography>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6 mt-6">
                  <Field
                    name="first_name"
                    component={Input}
                    placeholder="Contact Name"
                    label="First Name"
                    autoComplete="off"
                  />
                </div>

                <div className="col-lg-6 mt-6">
                  <Field
                    name="last_name"
                    component={Input}
                    placeholder="Contact Name"
                    label="Last Name"
                    autoComplete="off"
                  />
                </div>

                <div className="col-lg-6 mt-6">
                  <Field
                    name="phone"
                    component={PhoneNumber}
                    placeholder="xxx-xxx-xxxx"
                    label="Office Phone"
                    autoComplete="off"
                  />
                </div>

                <div className="col-lg-6 mt-6">
                  <Field
                    name="email"
                    component={Input}
                    placeholder="Contact Email"
                    label="Contact Email"
                    readOnly={!!initUser}
                    autoComplete="off"
                  />
                </div>

                {initUser ? (
                  <div className="col-lg-6 mt-3">
                    <UserPasswordInput/>
                  </div>
                ) : null}
                {initUser ? (
                  <div className="col-lg-12 mt-6 custom-checkbox">
                    <UserResendEmail/>
                  </div>
                ) : null}
                {initUser ? (
                  <div className="col-lg-6 mt-6">
                    <UserFirebaseAccount />
                  </div>
                ) : null}
                {initUser ? (
                  <div className="col-lg-6 mt-3">
                    <UserTableauAccount/>
                  </div>
                ) : null}

                {initUser ? (
                  <div className="col-lg-12 mt-6 custom-checkbox">
                    <UserErrors/>
                  </div>
                ) : null}

                {initUser ? (
                  <RoleBasedRenderer
                    requiredRoles={[
                      RoleEnum.RoleAdmin
                    ]}
                    showWarning={false}
                  >
                    <div className="col-lg-12 mt-6">
                      <UserRolesInput/>
                    </div>
                  </RoleBasedRenderer>
                ) : null}
                {initUser ? (
                  <RoleBasedRenderer
                    requiredRoles={[
                      RoleEnum.TableauAdmin
                    ]}
                    showWarning={false}
                  >
                    <div className="col-lg-12 mt-6">
                      <UserTableauInput/>
                    </div>
                  </RoleBasedRenderer>
                ) : null}
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Link to="/user">
              <button
                type="button"
                onClick={() => {}}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
            </Link>

            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};

export default UserForm;
