import axios from "axios";

const service = axios.create({
  timeout: 200000,
});

service.interceptors.request.use(
  (config) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
    config.headers = Object.assign({}, defaultHeaders, config.headers || {});
    // Modified with ConfigProvider
    //config.baseURL = process.env.REACT_APP_API_BASE_URL;
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    /*if (error.config.method === "get") {
      // window.location.href = `${appUrl()}/logging-out`;
    }
    return error.response;*/
    return Promise.reject(error);
  }
);

export { service };
