export const userToViewModel = (user, userRoles, userDashboardGroups) => {
  const mappedUserRoles = [];

  if (!!userRoles) {
    for (const [siteId, { title, roles }] of Object.entries(userRoles)) {
      mappedUserRoles.push({
        site_id: siteId,
        title,
        ...roles
        /*dashboard_user: roles.dashboard_user ?? false,
        stroke_user: roles.stroke_user ?? false,
        cardiac_user: roles.cardiac_user ?? false,
        upload_user: roles.upload_user ?? false,
        user_admin: roles.user_admin ?? false,
        site_admin: roles.site_admin ?? false,
        role_admin: roles.role_admin ?? false,
        developer: roles.developer ?? false*/
      })
    }
  }

  const output = {
    user_id: user.user_id,
    first_name: user.first_name,
    last_name: user.last_name,
    phone: user.phone,
    email: user.email,
    userRoles: mappedUserRoles,
    userDashboardGroups,
  };

  if (typeof user.firebase === 'object') {
    output.firebase = user.firebase;
    output.firebaseLastLogin = new Date(output.firebase.record.metadata.lastSignInTime);
    output.userMultiFactors = ((user.firebase.record || {}).multiFactor || {}).enrolledFactors || [];
  }
  if (typeof user.tableau === 'object') {
    output.tableau = user.tableau;
    output.tableauLastLogin = new Date(output.tableau.record.lastLogin);
    output.tableauSiteRole = output.tableau.record.siteRole;
  }

  if (Array.isArray(user.errors) && user.errors.length > 0) {
    output.errors = user.errors;
  }

  return output;
};

export const viewModelToUserData = ({ userRoles, userDashboardGroups, ...userData }) => {
  const userRolesData = {};

  for (const {site_id, title, ...roles} of userRoles) {
      userRolesData[`${site_id}`] = { roles, title };
  }

  return {
      userData,
      userRoles: userRolesData,
      userDashboardGroups
  }
}
