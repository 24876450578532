import React, { useEffect, useState } from 'react';
import useApiService from '../../../hooks/useApiService';
import useAxiosResponse from '../../../hooks/useAxiosResponse';
import { Spinner } from 'react-bootstrap';
import NavWidget from '../../../components/common/partial/widget/NavWidget';
import TableauVizFrame from '../../../components/tableau/embedding/TableauVizFrame';
//import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';

// maybe https://www.npmjs.com/package/@stoddabr/react-tableau-embed-live

// https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_get.html
// https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_release_notes.html
// TODO need to upgrade tableau server to 2023.1 (embedding 3.5), for JWT token current is 2022.1.9 (embedding 3.1)
/*
function TableauEmbedding() {


  const fetchToken = useApiService({ url: '/user/self/tableau/embedded' });
  const [{response, loading}] = useAxiosResponse(fetchToken);

  if (loading) {
    return <Spinner animation={'border'}/>
  }
  //return <TableauEmbed sourceUrl="https://public.tableau.com/views/WorldIndicators/GDPpercapita" />
  return <TableauEmbed
    sourceUrl={url}
    token={response.jwt}/>
}//*/

function OtherEmbedding ({token}) {
  const [url, setUrl] = useState('');

  return <>
    <input className='form-control' type="text" value={url} onChange={e => setUrl(e.target.value)} placeholder={'Paste URL to Dashboard view'}/>
    <TableauVizFrame src={url} token={token}/>
  </>
}

export default function TableauEmbedding () {


  const fetchToken = useApiService({ url: '/user/self/tableau/embedded' });
  const [{response, loading}] = useAxiosResponse(fetchToken);

  const links = {
    Demographics: 'https://data.texasrdc.org/views/DemographicsV2/Demographics',
    'IV Thrombolytics': 'https://data.texasrdc.org/views/IVThrombolyticsTherapyV2/IVThrombolyticsAdministration',
    'Door In - Door Out': 'https://data.texasrdc.org/views/DoorIn-DoorOutPerformanceV2/DoorIn-DoorOutCases',
    'Door to Needle': 'https://data.texasrdc.org/views/IschemicStrokeDoortoNeedlePerformanceV2/Ischemic-DoortoNeedle'
  }

  if (loading) {
    return <Spinner animation={'border'}/>
  }

  const items = Object.entries(links).map(([key, value]) => {
    return {
      value: value,
      label: key,
      content: <TableauVizFrame src={value} token={response.jwt}/>
    }
  }).concat({
    value: 'other',
    label: 'Other',
    content: <OtherEmbedding token={response.jwt}/>
  });


  return <NavWidget items={items}/>
}
