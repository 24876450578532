import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Input } from '../../_metronic/_partials/controls';
import { Button, Row, Col } from 'react-bootstrap';

const UserPasswordInput = () => {
  const name = 'password';
  const { setFieldValue } = useFormikContext();
  const [showPass, setShowPass] = useState(false);
  if (showPass) {
    return <Row>
      <Col>
        <Field
          name={name}
          type="password"
          component={Input}
          placeholder="Password"
          label="Password"
          autoComplete="off"
        />
      </Col>
      <Col className={'pt-8'} xs={'auto'}>
        <Button className='btn-secondary' onClick={() => {
          setShowPass(false)
          setFieldValue(name, '');
        }}>Cancel</Button>
      </Col>
    </Row>
  }
  return <Button className={'mt-8'} onClick={() => setShowPass(true)}>Set New Password</Button>
}

export default UserPasswordInput;