import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Site from "./site/Site";
import CreateSite from "./site/CreateSite";
import EditSite from "./site/EditSite";

import User from "./user/User";
import CreateUser from "./user/CreateUser";
import EditUser from "./user/EditUser";

import Help from "./help/Help";

import Header from "../components/Header";
import SessionTimeout from '../components/session-timeout/SessionTimeout';
import WelcomeDialog from '../components/auth/welcome-dialog';

import { firebaseAuth } from '../provider/auth/AuthProvider'
import Loading from "../components/common/Loading";
import { useStateValue } from "../store/StateProvider";
import { SET_AUTH_USER } from "../store/auth";
import { RoleBasedRenderer } from "../components/roles-based-renderer/RoleBasedRenderer";
import { RoleEnum } from "../config/roles";
import { useSubscribeToSelfRoles } from "../utils/customHooks";
import Profile from "./profile/Profile";
import Demo from "./developer/Demo";
import Upload from "./upload/Upload";
import Status from "./upload/Status";
import MultiForm from "./multiForm/MultiForm";
import StrokeFormPage from "./stroke/StrokeFormPage";
import StrokeUploadPage from "./stroke/StrokeUploadPage";
import StrokeTablePage from "./stroke/StrokeTablePage";
import StrokeReviewPage from "./stroke/StrokeReviewPage";
import GroupTablePage from './tableau/group/GroupTablePage';
import GroupFormPage from './tableau/group/GroupFormPage';
import FormConfigTablePage from './multiForm/schema/FormConfigTablePage';
import FormConfigEditPage from './multiForm/schema/FormConfigEditPage';
import TableauEmbedding from './tableau/embedding/TableauEmbedding';

const Layout = () => {
  const { handleSignout } = useContext(firebaseAuth);
  const [, dispatch ] = useStateValue();

  useEffect(() => {
    var rolesStr = localStorage.getItem("roles");
    var roles = JSON.parse(rolesStr);

    dispatch({
      type: SET_AUTH_USER,
      user: {
        roles
      }
    });
  }, [dispatch]);

  useSubscribeToSelfRoles();

  return (
    <div>
      <WelcomeDialog />
      <Loading />
      <SessionTimeout logOut={handleSignout} />
      <Header />
      <div className="wrapper">
        <div className="container-fluid">
          <Switch>
            {/* start routes for SITES */}
            <Route path="/site/create">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.SiteAdmin
                ]}
              >
                <CreateSite />
              </RoleBasedRenderer>
            </Route>
            <Route path="/site/edit/:id">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.SiteAdmin
                ]}
              >
                <EditSite />
              </RoleBasedRenderer>
            </Route>
            <Route path="/sites">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.SiteAdmin
                ]}
              >
                <Site />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for SITES */}

            {/* start routes for TABLEAU */}
            <Route path="/dashboard/embedding">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.TableauAdmin
                ]}
                inSites={[0]}
              >
                <TableauEmbedding/>
              </RoleBasedRenderer>
            </Route>
            <Route path="/dashboard/group/create">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.TableauAdmin
                ]}
                inSites={[0]}
              >
                <RoleBasedRenderer
                  requiredRoles={[
                    RoleEnum.SiteAdmin
                  ]}
                >
                  <GroupFormPage/>
                </RoleBasedRenderer>
              </RoleBasedRenderer>
            </Route>
            <Route path="/dashboard/group/:id">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.TableauAdmin
                ]}
                inSites={[0]}
              >
                <RoleBasedRenderer
                  requiredRoles={[
                    RoleEnum.SiteAdmin
                  ]}
                >
                  <GroupFormPage/>
                </RoleBasedRenderer>
              </RoleBasedRenderer>
            </Route>
            <Route path="/dashboard/group">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.TableauAdmin
                ]}
                inSites={[0]}
              >
                <RoleBasedRenderer
                  requiredRoles={[
                    RoleEnum.SiteAdmin
                  ]}
                >
                  <GroupTablePage/>
                </RoleBasedRenderer>
              </RoleBasedRenderer>
            </Route>
            {/* end routes for TABLEAU */}

            {/* start routes for FORM CONFIG */}
            <Route path="/form/schema/:name">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.FormAdmin
                ]}
                inSites={[0]}
              >
                <RoleBasedRenderer
                  requiredRoles={[
                    RoleEnum.SiteAdmin
                  ]}
                >
                  <FormConfigEditPage/>
                </RoleBasedRenderer>
              </RoleBasedRenderer>
            </Route>
            <Route path="/form/schema">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.FormAdmin
                ]}
                inSites={[0]}
              >
                <RoleBasedRenderer
                  requiredRoles={[
                    RoleEnum.SiteAdmin
                  ]}
                >
                  <FormConfigTablePage/>
                </RoleBasedRenderer>
              </RoleBasedRenderer>
            </Route>
            {/* end routes for FORM CONFIG */}

            {/* start routes for USER */}
            <Route path="/user/create">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UserAdmin
                ]}
              >
                <CreateUser />
              </RoleBasedRenderer>
            </Route>
            <Route path="/user/edit/:id">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UserAdmin
                ]}
              >
                <EditUser />
              </RoleBasedRenderer>
            </Route>
            <Route path="/user">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UserAdmin
                ]}
              >
                <User />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for USER */}

            {/* start routes for UPLOAD/SUBMIT FORM*/}
            <Route path="/upload/spreadsheet" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UploadUser
                ]}
              >
                <Upload />
              </RoleBasedRenderer>
            </Route>
            <Route path="/upload/form" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UploadUser, RoleEnum.Developer
                ]}
              >
                <Upload />
              </RoleBasedRenderer>
            </Route>
            <Route path="/upload/status" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.UploadUser, RoleEnum.Developer
                ]}
              >
                <Status />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for UPLOAD*/}

            {/* start routes for FORM*/}
            <Route path="/form/editor" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.FormAdmin
                ]}
              >
                <MultiForm />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for FORM*/}

            {/* start routes for STROKE */}
            <Route path={["/stroke/form/edit/:id","/stroke/form/create"]} >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.StrokeUser, RoleEnum.FormUser
                ]}
              >
                <StrokeFormPage />
              </RoleBasedRenderer>
            </Route>
            <Route path="/stroke/upload/:id" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.StrokeUser, RoleEnum.UploadUser
                ]}
              >
                <StrokeReviewPage />
              </RoleBasedRenderer>
            </Route>
            <Route path="/stroke/upload" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.StrokeUser, RoleEnum.UploadUser
                ]}
              >
                <StrokeUploadPage />
              </RoleBasedRenderer>
            </Route>
            <Route path="/stroke/form" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.StrokeUser, RoleEnum.FormUser
                ]}
              >
                <StrokeTablePage />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for STROKE */}

            {/* start routes for DEVELOPER*/}
            <Route path="/developer" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.Developer
                ]}
              >
                <Demo />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for DEVELOPER*/}

            {/* start routes for USER PROFILE */}
            <Route path="/profile">
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.PortalUser
                ]}
              >
                <Profile/>
              </RoleBasedRenderer>
            </Route>
            {/* end routes for USER PROFILE */}

            {/* start routes for DASHBOARD */}
            <Route path="/" >
              <RoleBasedRenderer
                requiredRoles={[
                  RoleEnum.PortalUser
                ]}
              >
                <Help />
              </RoleBasedRenderer>
            </Route>
            {/* end routes for DASHBOARD */}
          </Switch>
        </div>
        <div style={{height: "94px"}}/>
      </div>
    </div>
  );
};

export default Layout;
