import { useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import UploadProgressCell from "../../components/multiForm/datatable/cell/UploadProgressCell";
import SpreadsheetRowReview from "../../components/stroke/review/SpreadsheetRowReview";
import NavWidget from "../../components/common/partial/widget/NavWidget";
import { Col, Row } from "react-bootstrap";
import UploadEntriesCell from "../../components/multiForm/datatable/cell/UploadEntriesCell";
import WidgetCard from "../../components/common/partial/widget/WidgetCard";
import DateCell from "../../components/common/datatable/cell/DateCell";

export default function StrokeReviewPage () {
  const {id} = useParams();

  const [spreadsheet, setSpreadsheet] = useState({id, progress: 'loading'});

  const errorRows = useMemo(() => {
    try {
      return [...new Set(spreadsheet.error_rows.split(','))];
    } catch (e) {
      return [];
    }
  }, [spreadsheet.error_rows]);

  return <>
    <WidgetCard>
      <Row>
        <Col>
          <Row>
            <Col>
              <h1>Spreadsheet: {spreadsheet.org_name}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Site: {spreadsheet.site_name}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              Progress
              <UploadProgressCell
                row={spreadsheet}
                replaceRow={setSpreadsheet}/>
            </Col>
            <Col xs={'auto'}>
              Rows
              <UploadEntriesCell row={ spreadsheet }/>
            </Col>
          </Row>
        </Col>
        <Col xs={'auto'}>
          <Row>
            <Col>
              Uploaded: <DateCell id={'created'} row={spreadsheet}/>
            </Col>
          </Row>
          <Row className={'mt-5'}>
            <Col>
              Updated: <DateCell id={'updated'} row={spreadsheet}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </WidgetCard>
    {errorRows.length > 0 ? <NavWidget
      navClassName={'nav-pills nav-warning'}
      items={errorRows.map((row) => {
        return {
          label: `${parseInt(row)+1}`,
          content: <SpreadsheetRowReview id={id} row={row}/>
        }
      })}
    /> : null}
  </>
}
