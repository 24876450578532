import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { configContext } from "../../provider/config/ConfigProvider";
import { Modal } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import UserMultiFactorEdit from "../../components/user/profile/UserMultiFactorEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  const config = useContext(configContext);
  return <div className={classes.root}>
    <Paper className={classes.paper}>
      <Modal.Body>
        <Typography variant="h4">
          Profile Settings
        </Typography>
        {config.multiFactor.enable ?
          <UserMultiFactorEdit/>
          : null }
      </Modal.Body>
    </Paper>
  </div>
}

export default Profile;
