import React, { useContext } from 'react';
import AuthLayout from "../../components/auth/AuthLayout";
import useAxiosResponse from '../../hooks/useAxiosResponse';
import FormConfigPreview from '../../components/multiForm/schema/FormConfigPreview';
import { useParams } from 'react-router-dom';
import useApiService from '../../hooks/useApiService';
import Loading from '../../components/common/Loading';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { Col, Row } from 'react-bootstrap';
import { configContext } from '../../provider/config/ConfigProvider';



const Preview = () => {
  const {name} = useParams();
  const config = useContext(configContext);
  const fetchSchema = useApiService({
    url: `/multiform/preview/${name}`,
  });
  const [responseSchema, retrySchema] = useAxiosResponse(fetchSchema, true);

  return <div>
    <Loading />
    <div className="container-fluid">
      <Row className={'m-3'}>
        <Col lg={12}>
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl(config.branding.site_logo)}
          />
        </Col>
      </Row>
      <Row className={'m-6'}>
        <Col lg={12}>
          <FormConfigPreview name={name} responseSchema={responseSchema}/>
        </Col>
      </Row>
    </div>
  </div>
}

export default Preview
