import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import WidgetCard from "../common/partial/widget/WidgetCard";
import {Row, Col, Button} from "react-bootstrap";
import date from "date-and-time";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const countdownText = (seconds) => {
  if (seconds < 180) {
    return Math.round(seconds) + '\xa0seconds remain.';
  } else {
    return `${Math.floor(seconds / 60)}\xa0minutes ${Math.floor(seconds % 60)}\xa0seconds remain.`;
  }
}

const eta = (seconds) => {
  let format = 'MM/DD/YYYY[\xa0]HH:mm';
  if (seconds < 3600 * 12) {
    format = 'HH:mm:ss'
  }
  return date.format(date.addSeconds(new Date(), seconds), format);
}

const StatusWidget = ({ className, data, handleClick, loading }) => {

  const {
    list,
    msUntilComplete,
    totalRemaining,
    paused,
    averageInsertTime
  } =  data;


  const variant = useMemo(() => {
    return paused ? 'dark' : 'success';
  }, [paused]);

  const spinnerClass = useMemo(() => {
    return loading ? `spinner spinner-lg spinner-light-${variant} spinner-left` : '';
  }, [loading, variant])

  useEffect(() => {
    //console.log(data)
  }, [data])

  return <WidgetCard className={className}>
    <Row>
      <Col>
        <Button
          className={`btn-pill btn-lg btn-block btn-light-${variant} ${spinnerClass}`}
          onClick={handleClick}
        >
          {loading ? null : <span className={`svg-icon svg-icon-lg`}>
            <SVG
              src={toAbsoluteUrl(
                `/media/svg/icons/Media/${paused ? 'Play' : 'Pause'}.svg`
              )}
            />
          </span>}
          {paused ? loading ? 'Starting' : 'Stopped' : loading ? 'Stopping' : 'Started'}
        </Button>
      </Col>
      <Col>
        <Row>
          <Col>
            <span>
              {countdownText(msUntilComplete / 1000)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            ETA: {eta(msUntilComplete / 1000)}
          </Col>
        </Row>
        <Row>
          <Col>
            Remaining Rows: {totalRemaining}
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col>
        <span>
          Average Insert Time: {Math.round((averageInsertTime || {}).total)}ms
        </span>
      </Col>
    </Row>
    <Row>
      <Col>
        Rate: { Math.round(1 / (averageInsertTime || {}).total * 1000 * 60 * 60) || 'NaN'} rows/hr.
      </Col>
    </Row>
  </WidgetCard>;
}

export default StatusWidget;
