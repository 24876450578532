import React, { useContext, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import UserMultiFactorUnenroll from "./UserMultiFactorUnenroll";
import UserMultiFactorEnroll from "./UserMultiFactorEnroll";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";
import { getGeneralOptions } from "../../../services/general";

const UserMultiFactorEdit = () => {
  const {authUser, enrolledFactors, handleSignout} = useContext(firebaseAuth);

  useEffect(() => {
    if (authUser === null) {
      handleSignout('2FA settings changed. Signing out.');
    } else {
      getGeneralOptions().catch((error) => {
        const {response} = error;
        if ((response || {}).status === 401) {
          handleSignout('2FA settings changed. Signing out.');
        }
      });
    }
  }, [authUser, enrolledFactors, handleSignout])

  return <>
    <Typography variant="h6">Two Factor Authentication (2FA)</Typography>
    {Array.isArray(enrolledFactors) ? <>
      {enrolledFactors.map((enrolledFactor) => {
        return <UserMultiFactorUnenroll
          key={enrolledFactor.uid}
          enrolledFactor={enrolledFactor}/>
      })}
      <UserMultiFactorEnroll/>
    </> : <>loading</>}
  </>
}

export default UserMultiFactorEdit;
