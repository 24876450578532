import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import useIsMounted from "../../../hooks/useIsMounted";
import InputMask from 'react-input-mask';
import { makeStyles } from "@material-ui/core/styles";
import { VerifyPhoneDialog } from "../../auth/auth-dialog";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";

const useStyles = makeStyles((theme) => ({
  btns: {
    marginLeft: theme.spacing(2),
  }
}));

const UserMultiFactorEnroll = () => {
  const [verificationId, setVerificationId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const isMounted = useIsMounted();
  const classes = useStyles();
  const {enrollMultiFactor, confirmEnrollment, refreshEnrolledFactors} = useContext(firebaseAuth);

  const verifyPhone = () => {
    return !!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phoneNumber);
  }

  const tryCode = (code) => {
    return confirmEnrollment(verificationId, code);
  }

  const enroll = () => {
    enrollMultiFactor(phoneNumber, enroll).then((verificationId) => {
      if (isMounted) setVerificationId(verificationId);
    });
  }

  const handleClose = () => {
    setVerificationId(null);
    setPhoneNumber(null);
    if (typeof refreshEnrolledFactors === 'function') refreshEnrolledFactors();
  }

  return <ListItem>
    <VerifyPhoneDialog
      key={verificationId}
      phone={phoneNumber}
      verificationId={verificationId}
      tryCode={tryCode}
      success={handleClose}
      handleClose={handleClose}
    />
    <InputMask
      mask={'999-999-9999'}
      value={phoneNumber || ''}
      onChange={(e) => setPhoneNumber(e.target.value)}
    >
      <TextField
        type={'tel'}
        placeholder={'000-000-0000'}
        error={!verifyPhone()}
        variant="outlined"
        size={'small'}
      />
    </InputMask>
    <Button
      className={classes.btns}
      variant="contained"
      onClick={() => enroll()}
      disabled={!verifyPhone()}
      color={'primary'}
    >Enroll</Button>
  </ListItem>
}

export default UserMultiFactorEnroll;
