import React, { useContext, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import { phoneParenthesis } from "../../../utils/format-phone";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";
import { UnenrollConfirmationDialog } from "../../auth/auth-dialog";

const useStyles = makeStyles((theme) => ({
  btns: {
    marginLeft: theme.spacing(2),
  }
}));

const UserMultiFactorUnenroll = (props) => {
  const classes = useStyles();
  const {enrolledFactor} = props;
  const {unenrollMultiFactor, refreshEnrolledFactors, authUser} = useContext(firebaseAuth);
  const [open, setOpen] = useState(false);

  const unenroll = () => {
    setOpen(false);
    unenrollMultiFactor(enrolledFactor, unenroll).finally(() => {
      if (typeof refreshEnrolledFactors === 'function') refreshEnrolledFactors();
    });
  }

  return <ListItem>
    <UnenrollConfirmationDialog
      email={(authUser || {}).email}
      phone={phoneParenthesis(enrolledFactor.phoneNumber || '')}
      open={open}
      confirm={() => unenroll()}
      cancel={() => setOpen(false)}
    />
    <TextField
      InputProps={{
        readOnly: true,
      }}
      variant="outlined"
      size={'small'}
      defaultValue={phoneParenthesis(enrolledFactor.phoneNumber)}/>
    <Button
      className={classes.btns}
      variant="contained"
      onClick={() => setOpen(true)}
      color={'secondary'}
    >Unenroll</Button>
  </ListItem>
}

export default UserMultiFactorUnenroll;