import React, { useContext, useEffect, useState } from "react";
import {CircularProgress} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { firebaseAuth } from "../../../provider/auth/AuthProvider";
import { Link, useHistory } from "react-router-dom";
import PasswordResetSuccessDialog from "../action-dialog";
import { confirmPasswordReset } from "../../../services/auth";

const ResetPassword = ({actionCode, continueUrl}) => {

  const initialValues = {
    password: "",
    same_password: "",
  };
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [open, setOpen] = useState(false);
  const {verifyPasswordReset} = useContext(firebaseAuth);

  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    same_password: Yup.string()
      .required()
      .when("password", {
        is: (val) => (val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, actions) => {
      setVerifying(true);
      actions.setSubmitting(true);
      confirmPasswordReset(actionCode, values.password).then((response) => {
        setOpen(true);
      }).catch(() => {
        setError('Error occurred during confirmation. The link might have expired or the password is too weak. Please request a new link.');
      }).finally(() => {
        setVerifying(false);
        actions.setSubmitting(false);
      })
    },
  });

  useEffect(() => {
    verifyPasswordReset(actionCode).then((email) => {
      setEmail(email);
    }).catch(() => {
      setError('This link has expired. Please request a new one.');
    });
  }, [actionCode, verifyPasswordReset]);

  if (error) {
    return <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <p className="text-muted font-weight-bold">
          {" "}
          {error}
        </p>
      </div>
      <Link to="/auth/forgot-password">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 btn-block"
        >
          Forgot Password?
        </button>
      </Link>
    </div>
  }

  if (!email) {
    return <CircularProgress className="splash-screen-spinner" />
  }

  return <>
    <PasswordResetSuccessDialog
      open={open}
      handleClose={() => {
        history.push(`${continueUrl || '/'}`);
        setOpen(false);
      }}
    />
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <p className="text-muted font-weight-bold">
          {" "}
          Enter your new password
        </p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "same_password"
            )}`}
            name="same_password"
            {...formik.getFieldProps("same_password")}
          />
          {formik.touched.same_password && formik.errors.same_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.same_password}
              </div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Submit</span>
            {verifying && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/sign-in">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  </>

};

export default ResetPassword;
