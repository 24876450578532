import useAsyncSelect from '../../../../hooks/useAsyncSelect';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import useQueryParamStore from '../../../../hooks/useQueryParamStore';

export default function UserRACFilter() {

  const queryParams = useQueryParamStore('user', 'SET_USER_QP', []);
  const [site, setSite] = useState(null);
  const value = site ? site.site_id : null;

  const sites = useAsyncSelect({
    url: '/user/option/parent_sites',
    key: 'site_id',
    searchTerm: 'name',
    filters: {
      exclude: value,
    }
  });

  useEffect(() => {
    const site_id = queryParams.searchOptions.site_id;
    setSite((prev) => {
      if (`${site_id || ''}` === `${(prev || {}).site_id || ''}`) return prev;
      return sites.options.find((site) => {
        return `${site_id}` === `${site.site_id}`
      })
    });
  }, [setSite, queryParams.searchOptions.site_id, sites.options])

  const handleChange = useCallback((site) => {
    const site_id = (site || {}).site_id;
    queryParams.setSearchOption('site_id', site_id);
  }, [queryParams.setSearchOption]);

  if (sites && Array.isArray(sites.options) && sites.options.length < 2) {
    return null;
  }

  return <AsyncSelect
    //name={name}
    placeholder={'Filter by Site'}
    //isMulti
    //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    loadOptions={sites.loadOptions}
    getOptionLabel={option => option.common_name || option.legal_name || option.name}
    getOptionValue={option => option.site_id}
    loadValue={sites.loadValue}
    defaultOptions={sites.options}
    onChange={handleChange}
    isClearable={true}
    value={site}
    menuPlacement={'auto'}
    //components={{ Option: CountyOption}}
  />
}
